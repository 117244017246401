import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface ProcessReferencesModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
  documentNames?: string[];
  isReprocess: boolean;
}

/**
 * @description Process references modal
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onOpenChange - Function to open/close the modal.
 * @param {function} onConfirm - Function to confirm the modal.
 */
export const ProcessReferencesModal: React.FC<ProcessReferencesModalProps> = ({
  isOpen,
  onOpenChange,
  onConfirm,
  documentNames,
  isReprocess,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>
            {isReprocess ? "Reprocess" : "Rechart"}
            {" references?"}
          </DialogTitle>
          <DialogDescription>
            {isReprocess ? "Reprocessing" : "Recharting"} will overwrite all citations
            you have added or edited in charts. This action cannot be undone.
          </DialogDescription>
        </DialogHeader>
        {documentNames?.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {documentNames.map((name) => (
              <Badge variant="outline" key={name}>
                {name}
              </Badge>
            ))}
          </div>
        )}
        <DialogFooter>
          <div className="flex gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>{isReprocess ? "Reprocess" : "Rechart"}</Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
