import { ApiResponse } from "@/types";
import { S3Operation } from "@/types/types";
import useApi from "./useApi";

const useFileUtils = () => {
  const { getRequest, handleError } = useApi();

  const generateFileHash = async (file: File): Promise<string> => {
    const fileBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest("SHA-256", fileBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    return btoa(hashHex);
  };

  const getReferenceSignedUrl = async (
    file: File,
    operation: S3Operation = S3Operation.GET,
  ): Promise<ApiResponse> => {
    try {
      const base64Hash = await generateFileHash(file);
      const s3_key = "references/" + base64Hash;
      const response = await getRequest(
        `get_signed_url_for_file?file_id=${s3_key}&operation=${operation}`,
      );
      return {
        success: true,
        data: { ...response.data, s3_key: s3_key },
        status: response.status,
      };
    } catch (error) {
      return handleError(error, `Failed to get signed URL for file ${file.name}`);
    }
  };

  const uploadToS3 = async (file: File, signedUrl: string): Promise<ApiResponse> => {
    try {
      // Make sure we're using the raw file content
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": "application/pdf", // Force PDF content type
          "x-amz-acl": "public-read", // Make sure the file is readable
        },
      });

      if (!response.ok) {
        console.error("S3 Upload Error:", {
          status: response.status,
          statusText: response.statusText,
          url: signedUrl,
          fileType: file.type,
          fileName: file.name,
        });
        throw new Error(`Failed to upload file: ${response.statusText}`);
      }

      return {
        success: true,
        data: { url: signedUrl },
        status: response.status,
      };
    } catch (error) {
      console.error("Upload error details:", error);
      return handleError(error, `Failed to upload file ${file.name} to S3`);
    }
  };

  const getUploadedFileKeys = async (
    files: File[],
  ): Promise<{ [filename: string]: string }> => {
    try {
      const uploadPromises = files.map(async (file) => {
        // Get signed URL and upload to S3
        const signedUrlResponse = await getReferenceSignedUrl(file, S3Operation.PUT);
        const s3UploadResponse = await uploadToS3(file, signedUrlResponse.data.url);

        if (!s3UploadResponse.success) {
          throw new Error(`Failed to upload ${file.name}`);
        }

        return {
          [file.name]: signedUrlResponse.data.s3_key,
        };
      });

      // Wait for all uploads to complete and merge into a single object
      const results = await Promise.all(uploadPromises);
      return results.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    }
  };

  return {
    generateFileHash,
    getReferenceSignedUrl,
    uploadToS3,
    getUploadedFileKeys,
  };
};

export default useFileUtils;
