/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Label } from "@/components/ui/label";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { UpdateElementsModal } from "@/features/project/components";
import { useProjectStore } from "@/store";
import { ElementType } from "@/types";
import {
  CheckCircledIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Pencil1Icon,
  PlusIcon,
} from "@radix-ui/react-icons";
import React, { useRef, useState } from "react";
interface ElementListProps {
  itemType: string;
  editingBool?: boolean;
  startCollapsed?: boolean;
  list: { [key: string]: string }[];
  highlightedRef?: React.MutableRefObject<HTMLTableRowElement | null>;
  citationText?: string;
}

const ElementList: React.FC<ElementListProps> = ({
  itemType,
  editingBool,
  startCollapsed,
  list,
  highlightedRef,
  citationText,
}) => {
  const sectionTitle =
    itemType === ElementType.FEATURE
      ? "Features"
      : itemType === ElementType.CLAIM
        ? "Claims"
        : "Evaluate";
  const [isOpen, setIsOpen] = useState(!startCollapsed);
  const [showEditModal, setShowEditModal] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const assertedIds = useProjectStore((state) => state.currentProject.assertedClaims);

  const handleEditButton = () => {
    setShowEditModal(true);
  };

  return (
    <div className="border rounded-lg mt-3 p-2 w-full" ref={listRef}>
      {list?.length === 0 ? (
        <div className="flex items-center justify-between gap-2 pl-2">
          <Label className="mb-0">{sectionTitle}</Label>
          {editingBool && (
            <Button
              variant="ghost"
              size="icon"
              onClick={handleEditButton}
              aria-label="add"
            >
              <PlusIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
      ) : (
        <Collapsible open={isOpen} onOpenChange={setIsOpen}>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 pl-2">
              <Label className="mb-0">{sectionTitle}</Label>
              {editingBool && (
                <>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={handleEditButton}
                    aria-label="edit"
                  >
                    <Pencil1Icon className="h-4 w-4" />
                  </Button>
                </>
              )}
            </div>
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="icon">
                {isOpen ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </Button>
            </CollapsibleTrigger>
          </div>
          <CollapsibleContent>
            <Table>
              <TableBody>
                {list?.map((item, index) => {
                  if (!item) return null;
                  const [id, text] = Object.entries(item)[0];
                  const isHighlighted =
                    citationText && text.slice(0, 75) === citationText.slice(0, 75);

                  const isAsserted = assertedIds?.includes(id);

                  return (
                    <TableRow
                      key={index}
                      className={isHighlighted ? "bg-blue-100" : ""}
                      ref={isHighlighted ? highlightedRef : null}
                    >
                      <TableCell className="font-medium">{id}</TableCell>
                      <TableCell>{String(text)}</TableCell>
                      {itemType === ElementType.CLAIM && editingBool && (
                        <TableCell className="w-[10px]">
                          {isAsserted && (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <CheckCircledIcon className="h-4 w-4 text-blue-500" />
                              </TooltipTrigger>
                              <TooltipContent>Asserted</TooltipContent>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CollapsibleContent>
        </Collapsible>
      )}

      <UpdateElementsModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        itemType={itemType as ElementType}
      />
    </div>
  );
};

export default ElementList;
