/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { MultiDocumentSelect } from "@/components";
import VariableInput from "@/components/input/VariableInput";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItemWithText } from "@/components/ui/radio-group";
import ColorSelect from "@/features/project/charts/components/ColorSelect";
import ElementSelect from "@/features/project/charts/components/ElementSelect";
import { useDataTable, useProject } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ChartColor, ElementType, FigurePlacement } from "@/types";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import SettingsSection from "../../components/SettingsSection";

interface ExportChartsModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Exports charts to a Microsoft Word or Excel file
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 */
const ExportChartsModal: React.FC<ExportChartsModalProps> = ({ open, handleClose }) => {
  const { getElementChartExport } = useDataTable();
  const { getProjectSettings } = useProject();

  // Global state from store
  const { currentProjectId, currentProject } = useProjectStore();
  const { addErrorMessage, addLoadingMessage, removeLoadingMessage } =
    useAppStateStore();

  // Local state
  const [wordChecked, setWordChecked] = useState(true);
  const [excelChecked, setExcelChecked] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  const [includeSummaryCharts, setIncludeSummaryCharts] = useState(false);
  const [includeClaims, setIncludeClaims] = useState(true);
  const [includeFeatures, setIncludeFeatures] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [introductionLanguage, setIntroductionLanguage] = useState("");
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [referenceIds, setReferenceIds] = useState([]);
  const [patentCitationFormat, setPatentCitationFormat] = useState(
    "{{nickname}} at {{column}}:{{lines}}",
  );
  const [applicationCitationFormat, setApplicationCitationFormat] = useState(
    "{{nickname}} at {{paragraph}}",
  );
  const [documentCitationFormat, setDocumentCitationFormat] = useState(
    "{{nickname}} at Page {{pages}}",
  );
  const [foreignDocumentCitationFormat, setForeignDocumentCitationFormat] = useState(
    "{{nickname}} at Paragraph {{paragraph}}",
  );
  const [figureFormat, setFigureFormat] = useState(
    "Fig. {{figure_number}} at {{index}}",
  );
  const [titleFormat, setTitleFormat] = useState(
    'Invalidity of {{subject_number}} ("{{nickname}}")\nin view of {{reference_number}}',
  );
  const [isCitationBefore, setIsCitationBefore] = useState(true);
  const [figurePlacement, setFigurePlacement] = useState(FigurePlacement.GROUPED);

  const handleClaimSelect = (selectedClaims: string[]) => {
    setSelectedClaims(selectedClaims);
  };

  const handleFeatureSelect = (selectedFeatures: string[]) => {
    setSelectedFeatures(selectedFeatures);
  };

  useEffect(() => {
    setWordChecked(true);
    setExcelChecked(false);
    setIntroductionLanguage("");
    setIncludeSummaryCharts(false);
    setIncludeClaims(true);
    setIncludeFeatures(false);
    setAlertVisible(false);
    setAlertMessage("");
  }, [open]);

  // Add useEffect to fetch settings when modal opens
  useEffect(() => {
    const fetchSettings = async () => {
      if (currentProjectId) {
        const response = await getProjectSettings(currentProjectId);
        if (response.success && response.data) {
          const settings = response.data;
          // Update all the state values with the fetched settings
          setTitleFormat(settings.title_format || "");
          setPatentCitationFormat(settings.us_patent_citation_format || "");
          setForeignDocumentCitationFormat(
            settings.non_us_patent_citation_format || "",
          );
          setApplicationCitationFormat(settings.us_application_citation_format || "");
          setDocumentCitationFormat(settings.uploaded_document_citation_format || "");
          setIsCitationBefore(settings.is_citation_before || true);
          setFigurePlacement(settings.figure_placement || FigurePlacement.GROUPED);
          setIntroductionLanguage(settings.word_introduction_language || "");
          setSelectedColors(settings.citation_colors || []);
          setTitleFormat(settings.word_claim_chart_title_format || "");
        }
      }
    };

    if (open) {
      fetchSettings();
    }
  }, [open]); // Only depends on 'open' prop

  /**
   * @description Exports charts to a Microsoft Word file
   * @param {boolean} includeFeatures - whether to include feature charts
   * @param {boolean} includeClaims - whether to include claim charts
   * @param {boolean} includeSummary - whether to include summary charts
   */
  const exportProject = async (
    finalColors: string[],
    finalFeatures: string[],
    finalClaims: string[],
    exportType: string,
  ) => {
    const citationPosition = isCitationBefore ? "start" : "end";
    const response = await getElementChartExport(
      currentProjectId,
      referenceIds,
      includeFeatures,
      includeClaims,
      includeSummaryCharts,
      introductionLanguage,
      finalColors,
      finalFeatures,
      finalClaims,
      exportType,
      citationPosition,
      patentCitationFormat,
      applicationCitationFormat,
      documentCitationFormat,
      foreignDocumentCitationFormat,
      figureFormat,
      figurePlacement,
      titleFormat,
    );
    if (!response.success) {
      addErrorMessage("Error downloading file. Try again later.");
      return;
    }
    if (!response.data) {
      if (process.env.NODE_ENV !== "production") {
        console.error("No download URL found");
      }
      addErrorMessage("Error downloading file. Try again later.");
      return;
    }
  };

  // Handles the download button click and prepares payload
  const handleDownloadClick = async () => {
    // Validate user input on required selections
    const fileFormatSelected = wordChecked || excelChecked;
    const chartOptionsSelected =
      includeSummaryCharts || includeClaims || includeFeatures;
    if (!fileFormatSelected && !chartOptionsSelected) {
      setAlertMessage("Please select at least one file format and chart option.");
      setAlertMessage("Please select at least one file format and chart option.");
      setAlertVisible(true);
      return;
    } else if (!fileFormatSelected && chartOptionsSelected) {
      setAlertMessage("Please select at least one file format.");
      setAlertVisible(true);
      return;
    }
    if (fileFormatSelected && !chartOptionsSelected) {
      setAlertMessage("Please select at least one chart option.");
      setAlertVisible(true);
      return;
    }
    setAlertVisible(false);
    const key = nanoid();
    addLoadingMessage(`Downloading charts for ${currentProject.name}...`, key);
    handleClose();

    const finalColors =
      selectedColors?.length === 0 || selectedColors?.length === 3
        ? []
        : selectedColors;
    selectedColors?.length === 0 || selectedColors?.length === 3 ? [] : selectedColors;

    let finalFeatures = [];
    let finalClaims = [];

    if (
      includeFeatures &&
      selectedFeatures?.length > 0 &&
      selectedFeatures?.length !== currentProject.features?.length
    ) {
      finalFeatures = selectedFeatures;
    }
    if (
      includeClaims &&
      selectedClaims?.length > 0 &&
      selectedClaims?.length !== currentProject.claims?.length
    ) {
      finalClaims = selectedClaims;
    }

    let exportType = "";

    if (wordChecked && excelChecked) {
      exportType = "both";
    } else if (wordChecked) {
      exportType = "word";
    } else if (excelChecked) {
      exportType = "excel";
    }

    await exportProject(finalColors, finalFeatures, finalClaims, exportType);
    removeLoadingMessage(key);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[1200px] flex flex-col max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Download charts</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-2 overflow-y-auto flex-1">
          <div className="space-y-2">
            <SettingsSection label="Chart types *" description="">
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center space-x-2">
                  {currentProject.claims?.length > 0 && (
                    <div className="flex items-center space-x-2">
                      <CheckboxWithText
                        id="claims"
                        checked={includeClaims}
                        onCheckedChange={(checked) =>
                          setIncludeClaims(checked as boolean)
                        }
                        label="Claim Charts"
                      />
                    </div>
                  )}
                  {/* {currentProject.features?.length > 0 && (
                    <div className="flex items-center space-x-2">
                      <CheckboxWithText
                        id="features"
                        checked={includeFeatures}
                        onCheckedChange={(checked) =>
                          setIncludeFeatures(checked as boolean)
                        }
                        label="Feature Charts"
                      />
                    </div>
                  )}
                  <CheckboxWithText
                    id="summary"
                    checked={includeSummaryCharts}
                    onCheckedChange={(checked) =>
                      setIncludeSummaryCharts(checked as boolean)
                    }
                    label="Summary Chart"
                  /> */}
                </div>
              </div>
            </SettingsSection>
          </div>

          <div className="space-y-2">
            <SettingsSection
              label="Chart options *"
              description="Select the references, colors, and elements to include in the export"
            >
              <div className="flex flex-wrap gap-2 flex-col">
                <div className="flex gap-2">
                  <div className="max-w-[500px]">
                    <MultiDocumentSelect
                      handleChange={setReferenceIds}
                      general={true}
                    />
                  </div>
                  <ColorSelect
                    colors={[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED]}
                    selectedColors={selectedColors}
                    setSelectedColors={setSelectedColors}
                  />
                  {includeClaims && currentProject.claims?.length > 0 && (
                    <ElementSelect
                      onElementSelect={handleClaimSelect}
                      elementType={ElementType.CLAIM}
                    />
                  )}
                  {includeFeatures && currentProject.features?.length > 0 && (
                    <ElementSelect
                      onElementSelect={handleFeatureSelect}
                      elementType={ElementType.FEATURE}
                    />
                  )}
                </div>
              </div>
            </SettingsSection>
          </div>

          <div className="space-y-2">
            <SettingsSection label="File format *" description="">
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center space-x-2">
                  <CheckboxWithText
                    id="word"
                    checked={wordChecked}
                    onCheckedChange={(checked) => setWordChecked(checked as boolean)}
                    label="Microsoft Word (.docx)"
                  />
                </div>
                {/* <div className="flex items-center space-x-2">
                  <CheckboxWithText
                    id="excel"
                    checked={excelChecked}
                    onCheckedChange={(checked) =>
                      setExcelChecked(checked as boolean)
                    }
                    label="Microsoft Excel (.xlsx)"
                  />
                </div> */}
              </div>
            </SettingsSection>
          </div>

          {wordChecked && (
            <div>
              <SettingsSection
                label="Word invalidity claim chart title"
                description="Format for the title displayed at the top of the invalidity claim chart documents"
              >
                <div className="space-y-2">
                  <VariableInput
                    label=""
                    value={titleFormat}
                    onChange={setTitleFormat}
                    type="title"
                    placeholder='e.g., Invalidity of {{subject_number}} ("{{nickname}}")\nin view of {{reference_number}}'
                  />
                </div>
              </SettingsSection>
              <SettingsSection
                label="Word introduction language"
                description="Language displayed at the beginning of the Word export above the claim charts"
              >
                <div className="space-y-2 mt-2">
                  <VariableInput
                    label=""
                    value={introductionLanguage}
                    onChange={setIntroductionLanguage}
                    type="introduction"
                  />
                </div>
              </SettingsSection>
            </div>
          )}

          <div className="space-y-4">
            <SettingsSection label="Citation settings" description="">
              <div className="space-y-4">
                {wordChecked && (
                  <div>
                    <Label className="text-sm">Position</Label>
                    <RadioGroup
                      value={isCitationBefore ? "start" : "end"}
                      onValueChange={(value) => setIsCitationBefore(value === "start")}
                      className="flex gap-4 mt-2"
                    >
                      <RadioGroupItemWithText
                        value="start"
                        id="start"
                        label="Before language"
                      />
                      <RadioGroupItemWithText
                        value="end"
                        id="end"
                        label="After language"
                      />
                    </RadioGroup>
                  </div>
                )}

                <div className="grid grid-cols-2 gap-4">
                  <VariableInput
                    label="US Patent Format"
                    value={patentCitationFormat}
                    onChange={setPatentCitationFormat}
                    type="citation"
                    placeholder="e.g., {{nickname}} at {{column}}:{{lines}}"
                  />

                  <VariableInput
                    label="US Patent Application Format"
                    value={applicationCitationFormat}
                    onChange={setApplicationCitationFormat}
                    type="citation"
                    placeholder="e.g., {{nickname}} at {{paragraph}}"
                  />

                  <VariableInput
                    label="Uploaded Document Format"
                    value={documentCitationFormat}
                    onChange={setDocumentCitationFormat}
                    type="citation"
                    placeholder="e.g., {{nickname}} at p. {{pages}}"
                  />

                  <VariableInput
                    label="Non-US Patent Format"
                    value={foreignDocumentCitationFormat}
                    onChange={setForeignDocumentCitationFormat}
                    type="citation"
                    placeholder="e.g., {{nickname}} at {{paragraph}}"
                  />
                </div>
              </div>
            </SettingsSection>

            {/* Figure Settings */}
            {wordChecked && (
              <SettingsSection
                label="Figure settings"
                description="Figure position in the exported charts"
              >
                <div className="flex gap-8 items-start">
                  <div>
                    <Label className="text-sm">Figure Placement</Label>
                    <RadioGroup
                      value={figurePlacement}
                      onValueChange={(value) =>
                        setFigurePlacement(value as FigurePlacement)
                      }
                      className="flex gap-4 mt-2"
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItemWithText
                          value={FigurePlacement.GROUPED}
                          id={FigurePlacement.GROUPED}
                          label="Grouped for all element citations"
                        />
                      </div>
                      {/* <div className="flex items-center space-x-2">
                        <RadioGroupItemWithText
                          value={FigurePlacement.EMBEDDED}
                          id={FigurePlacement.EMBEDDED}
                          label="Embedded with each citation"
                        />
                      </div> */}
                      <div className="flex items-center space-x-2">
                        <RadioGroupItemWithText
                          value={FigurePlacement.OMITTED}
                          id={FigurePlacement.OMITTED}
                          label="Omitted from export"
                        />
                      </div>
                    </RadioGroup>
                  </div>
                </div>
              </SettingsSection>
            )}
          </div>
        </div>

        {alertVisible && (
          <Alert variant="destructive" className="mt-2">
            <AlertDescription>{alertMessage}</AlertDescription>
          </Alert>
        )}

        <DialogFooter className="mt-2 pt-2">
          <div className="flex flex-row justify-between gap-3">
            <div className="flex items-center gap-2">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleDownloadClick}
                disabled={
                  (!wordChecked && !excelChecked) ||
                  (!includeSummaryCharts && !includeClaims && !includeFeatures)
                }
              >
                Download
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ExportChartsModal;
