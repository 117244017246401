import { Separator } from "@/components/ui/separator";
import { useViz } from "@/hooks";
import useProjectStore from "@/store/projectStore";
import {
  ClaimStatusReason,
  DocumentType,
  OfficeAction,
  ReferenceParagraphCitation,
} from "@/types/project";
import { memo, useState } from "react";
import { ClaimReasonsCollapsibleRightHalf } from "./components/ClaimReasonsCollapsibleRightHalf";

interface ReasonsCollapsibleProps {
  selectedReason: (ClaimStatusReason & { index: number; claimIndex: number }) | null;
  reasons: ClaimStatusReason[];
  claimText: string;
  status: string;
  isCitationsSectionVisible: boolean;
  isEditing: boolean;
}

export const ClaimReasonsCollapsible = memo(
  ({
    selectedReason,
    reasons,
    claimText,
    status,
    isCitationsSectionVisible,
    isEditing,
  }: ReasonsCollapsibleProps) => {
    const { updateCurrentProject, currentProject } = useProjectStore();
    const { generateClaimAmendments, generateClaimArgument } = useViz();
    if (!selectedReason) return null;

    const [generatingAmendment, setGeneratingAmendment] = useState(false);
    const [generatingArgument, setGeneratingArgument] = useState(false);

    const updateStore = (newData: any, type: "amendments" | "arguments") => {
      // Update the document in the store
      const updatedDocs = currentProject.applicationDocuments.map((doc) => {
        if (doc.document_type === DocumentType.OFFICE_ACTION) {
          const officeAction = doc.document as OfficeAction;
          const updatedClaimDetails = officeAction.claimDetails.map(
            (claim, claimIndex) => {
              if (claimIndex === selectedReason.claimIndex) {
                return {
                  ...claim,
                  reasons: claim.reasons.map((reason, reasonIndex) => {
                    if (reasonIndex === selectedReason.index) {
                      return { ...reason, [type]: newData };
                    }
                    return reason;
                  }),
                };
              }
              return claim;
            },
          );

          return {
            ...doc,
            document: { ...officeAction, claimDetails: updatedClaimDetails },
          };
        }
        return doc;
      });

      updateCurrentProject({
        applicationDocuments: updatedDocs,
        id: currentProject.id,
        name: currentProject.name,
      });
    };

    const handleGenerateAmendments = async () => {
      setGeneratingAmendment(true);
      try {
        const response = await generateClaimAmendments(claimText, selectedReason);
        if (response.success) {
          const firstAmendment = {
            text: response.data.amendments[0].text,
            reason: response.data.amendments[0].reason,
          };
          selectedReason.amendments = [firstAmendment];
          updateStore([firstAmendment], "amendments");
        }
      } finally {
        setGeneratingAmendment(false);
      }
    };

    const handleGenerateArgument = async () => {
      setGeneratingArgument(true);
      try {
        const response = await generateClaimArgument(claimText, selectedReason);
        if (response.success) {
          const newArguments = response.data.arguments;
          selectedReason.arguments = newArguments;
          updateStore(newArguments, "arguments");
        }
      } finally {
        setGeneratingArgument(false);
      }
    };

    const isObjected = status.toLowerCase() === "objected";
    const citationsSection = RenderCitations(
      isObjected
        ? reasons.flatMap((r) => r.referenceCitations)
        : selectedReason.referenceCitations,
    );
    const rightHalfOpen = isEditing || (!isEditing && isCitationsSectionVisible);

    return (
      <div className="mt-2 mb-3 space-y-4">
        <div className="flex gap-4">
          {/* Left half: Claim Language + Examiner Commentary */}
          <div
            className={`space-y-8 ${rightHalfOpen ? "w-1/2 mr-2" : "w-full px-[4vw]"}`}
          >
            <div className="space-y-2">
              <p className="text-sm font-bold">Claim Language</p>
              <p className="text-sm text-justify">{claimText}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm font-bold">Examiner Commentary</p>
              {isObjected ? (
                <div className="space-y-4">
                  {reasons.map((reason, index) => (
                    <div key={index}>
                      <p className="text-sm whitespace-pre-line text-justify">
                        {reason.examinerCommentary[index]}
                      </p>
                      {index < reasons.length - 1 && <Separator className="my-2" />}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-sm whitespace-pre-line text-justify">
                  {selectedReason.examinerCommentary[0]}
                </p>
              )}
            </div>
          </div>

          {/* Right half: Cited Art Paragraphs and Drafting */}
          {rightHalfOpen && (
            <ClaimReasonsCollapsibleRightHalf
              isEditing={isEditing}
              isCitationsSectionVisible={isCitationsSectionVisible}
              amendments={selectedReason.amendments || []}
              argument={selectedReason.arguments || []}
              generatingAmendment={generatingAmendment}
              generatingArgument={generatingArgument}
              handleGenerateAmendments={handleGenerateAmendments}
              handleGenerateArgument={handleGenerateArgument}
              citationsSection={citationsSection}
            />
          )}
        </div>
      </div>
    );
  },
);

const RenderCitations = (citations: ReferenceParagraphCitation[]) =>
  citations.map((citation, index, array) => (
    <div key={index}>
      <div className="relative">
        <div
          className="absolute left-0 top-1 w-3 h-3 rounded-sm"
          style={{ backgroundColor: citation.color }}
        />
        <p className="text-sm whitespace-pre-line pl-4">
          <span className="text-muted-foreground">
            {`${citation.referenceNickname} [${citation.paragraphNumber}]`}
          </span>
          {`: ${citation.paragraphText}`}
        </p>
      </div>
      {index < array.length - 1 && <Separator className="my-4" />}
    </div>
  ));

ClaimReasonsCollapsible.displayName = "ReasonsCollapsible";
