import { ChartColor, CitationLocation, InvalidityCitation } from "@/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";

/**
 * @param {string} subjectId - the subjectId to be formatted
 * @description Formats the subjectId based on its length
 */
export const splitId = (subjectId: string) => {
  // if the subjectId is "No Reference Documents" return "No Reference Documents"
  if (!subjectId || subjectId === "No Reference Documents") {
    return subjectId;
  }

  // If the subjectId is less than 3 characters, return the subjectId as is
  if (subjectId.length < 3) {
    return subjectId;
  }

  // if the subject id ends with a file extension, return it as is
  if (subjectId.endsWith(".pdf")) {
    return subjectId;
  }

  // Check if the first two characters are letters
  let prefix = "";
  if (/^[A-Za-z]{2}/.test(subjectId)) {
    prefix = subjectId.slice(0, 2); // Extract first two letters as prefix
    subjectId = subjectId.slice(2); // Remove the extracted prefix from the original subjectId
  }
  let withoutPrefix = subjectId;

  // Check and remove the suffix 'A1' or 'B2' if present
  let suffix = "";
  const suffixMatch = withoutPrefix.match(/[A-Z]\d{0,2}$/);
  if (suffixMatch) {
    suffix = suffixMatch[0];
    withoutPrefix = withoutPrefix.slice(0, -suffix.length);
  }

  // Formatting the number part based on prefix
  let formattedNumber;

  switch (prefix) {
    case "US":
      if (withoutPrefix.length > 8) {
        // Publication number format (e.g., 2010/0234553)
        formattedNumber = `${withoutPrefix.slice(0, 4)}/${withoutPrefix.slice(4)}`;
      } else {
        // Regular patent number format
        formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    case "JP":
      if (/^[A-Z]/.test(withoutPrefix)) {
        // Publication number format (e.g., S63-2956)
        formattedNumber = `${withoutPrefix.slice(0, 3)}-${withoutPrefix.slice(3)}`;
      } else if (withoutPrefix.length > 8) {
        // Publication number format (e.g., 2010-0234553)
        formattedNumber = `${withoutPrefix.slice(0, 4)}-${withoutPrefix.slice(4)}`;
      } else {
        // Regular patent number format
        formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    case "KR":
      if (withoutPrefix.slice(0, 2) === "10") {
        // Publication number format (e.g., 10-1054626)
        formattedNumber = `${withoutPrefix.slice(0, 2)}-${withoutPrefix.slice(2)}`;
      } else if (withoutPrefix.length > 8) {
        // Publication number format (e.g., 2010-0234553)
        formattedNumber = `${withoutPrefix.slice(0, 4)}-${withoutPrefix.slice(4)}`;
      } else {
        // Regular patent number format
        formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    default: //includes EP, CN
      formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      break;
  }

  // Reassemble the full formatted ID with prefix and suffix if they were removed
  return `${prefix} ${formattedNumber}`.trim();
};

export const colorRank: { [key: string]: number } = {
  [ChartColor.GREEN]: 4,
  [ChartColor.YELLOW]: 3,
  [ChartColor.RED]: 2,
  [ChartColor.GRAY]: 1,
};

/**
 * @param {array} colors - The colors to get the highest rank from
 * @returns {string} - The highest rank
 */
export const getHighestRank = (colors: string[]) => {
  const num = Math.max(...colors.map((color) => colorRank[color] || 0));
  return Object.keys(colorRank).find((key) => colorRank[key] === num);
};

/**
 * Filters out unwanted sections from document body or metadata
 * @param sections - Array of document sections to filter
 * @param options - Optional configuration for filtering
 * @returns Filtered array of sections
 */
export const filterDocumentSections = (
  sections: any[],
  options: {
    excludeStrings?: string[];
  } = {},
) => {
  const { excludeStrings = ["IS", "IS.", "U.S.", "(Continued)"] } = options;

  return (
    sections?.filter((section: any) => {
      // For body sections, apply text-based filtering
      const text = section.text || "";

      // Must contain letters
      const hasLetters = /[a-zA-Z]/.test(text);

      // Strip spaces and commas for patent number check
      const strippedText = text.replace(/[\s,]/g, "");

      // Check for excluded strings
      const isExcludedString = excludeStrings.includes(text.trim());

      return hasLetters && !isExcludedString && !isLikelyPatentNumber(strippedText);
    }) || []
  );
};

export const formatCitationLocation = (
  citation: InvalidityCitation | CitationLocation,
  is_patent: boolean,
  format?: string,
) => {
  if (citation?.claimSection) {
    return citation.claimSection;
  }
  const paragraph = citation?.paragraph;
  const pages = citation?.pages;
  const columns = citation?.columns;
  const lines = citation?.lines;

  // Helper function to format ranges
  const formatRange = (start: number, end: number) => {
    return start === end ? `${start}` : `${start}-${end}`;
  };

  // Format column-lines
  const formatColumnLines = () => {
    if (
      !columns?.length ||
      !lines?.length ||
      columns.some((n) => n < 0) ||
      lines.some((n) => n < 0)
    ) {
      return "Unknown";
    }

    // Same column case
    if (columns[0] === columns[1]) {
      return `${columns[0]}:${formatRange(lines[0], lines[1])}`;
    }

    // Different columns case
    return `${columns[0]}:${lines[0]}-${columns[1]}:${lines[1]}`;
  };

  const formatPages = () => {
    return pages
      ? pages[0] === pages[1]
        ? `Page ${pages[0]}`
        : `Pages ${pages[0]}-${pages[1]}`
      : "Unknown";
  };

  switch (format) {
    case "column-lines":
      return formatColumnLines();

    case "page":
      return formatPages();

    case "paragraph":
      return paragraph ? `[${paragraph}]` : "Unknown";

    case "page-paragraph":
      return formatPages() + (paragraph ? `[${paragraph}]` : "Unknown");

    default:
      // If column-line data exists, only show that
      if (columns?.length && lines?.length) {
        const columnLineFormat = formatColumnLines();
        if (columnLineFormat !== "Unknown") return columnLineFormat;
      }

      // For patents without column-line data, show paragraph
      if (is_patent) {
        return paragraph ? `[${paragraph}]` : "Unknown";
      }

      // Otherwise show page
      return formatPages();
  }
};
