import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
  TableWrapper,
} from "@/components/ui/table";
import { Pencil1Icon } from "@radix-ui/react-icons";
import React from "react";

interface KindCodeSelectProps {
  kindCodes: Record<string, string[]>;
  selectedKindCodes: Record<string, string>;
  setSelectedKindCodes: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  handleConfirm: () => Promise<void>;
}

const KindCodeSelect: React.FC<KindCodeSelectProps> = ({
  kindCodes,
  selectedKindCodes,
  setSelectedKindCodes,
  handleConfirm,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-semibold">Confirm Patent Kind Codes</h2>
      <TableWrapper>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Patent Number</TableHead>
              <TableHead>Kind Code</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(kindCodes).map(([patentNumber, availableKindCodes]) => (
              <TableRow key={patentNumber}>
                <TableCellCondensed>{patentNumber}</TableCellCondensed>
                <TableCellCondensed>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="flex items-center gap-1 min-w-[70px]"
                      >
                        <Pencil1Icon className="h-4 w-4" />
                        {selectedKindCodes[patentNumber] || "Select Kind Code"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      {availableKindCodes.map((kindCode) => (
                        <DropdownMenuItem
                          key={kindCode}
                          onClick={() =>
                            setSelectedKindCodes((prev) => ({
                              ...prev,
                              [patentNumber]: kindCode,
                            }))
                          }
                        >
                          {kindCode}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCellCondensed>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <Button onClick={handleConfirm} className="mx-auto">
        Confirm Selection
      </Button>
    </div>
  );
};

export default KindCodeSelect;
