/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { LoadingButton } from "@/components/ui/loading-button";
import { RadioGroup, RadioGroupItemWithText } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useDataTable } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ElementType } from "@/types";
import React, { useEffect, useState } from "react";

interface CopyCitationsModalProps {
  open: boolean;
  handleClose: () => void;
  referenceId: string;
  mode: "append" | "replace";
  elementType: ElementType;
  claimNumber: string;
  rowIndex: number;
}

/**
 * @description Prune invalidity charts by citation score
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const CopyCitationsModal: React.FC<CopyCitationsModalProps> = ({
  open,
  handleClose,
  referenceId,
  mode,
  elementType,
  claimNumber,
  rowIndex,
}) => {
  const { copyInvalidityCitations, getSummaryChartData } = useDataTable();
  const { addErrorMessage } = useAppStateStore();
  const { currentProjectId, currentProject } = useProjectStore();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedElement, setSelectedElement] = useState<string | null>(null);
  const [applyToAllReferences, setApplyToAllReferences] = useState(false);
  const [elements, setElements] = useState<string[]>([]);
  useEffect(() => {
    setElements(
      elementType === ElementType.FEATURE
        ? currentProject.features.map((feature) => Object.keys(feature)[0])
        : currentProject.claims.map((claim) => Object.keys(claim)[0]),
    );
  }, [currentProject, elementType]);

  useEffect(() => {
    resetModal();
  }, [open]);

  const resetModal = () => {
    setSelectedElement(null);
    setApplyToAllReferences(false);
    setIsLoading(false);
  };

  const onClose = () => {
    resetModal();
    handleClose();
  };

  const handleSave = async () => {
    setIsLoading(true);
    copyInvalidityCitations(
      currentProjectId,
      claimNumber, // target claim number
      selectedElement, // source claim number
      referenceId,
      mode === "replace", // delete existing
      applyToAllReferences,
      elementType === ElementType.FEATURE,
      rowIndex,
    )
      .then((response) => {
        if (response.success) {
          getSummaryChartData(currentProjectId, elementType);
        }
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>
            {mode === "append"
              ? `Append citations to ${
                  elementType === ElementType.FEATURE ? "feature" : "claim"
                } ${claimNumber}`
              : `Replace citations for ${
                  elementType === ElementType.FEATURE ? "feature" : "claim"
                } ${claimNumber}`}
          </DialogTitle>
          <DialogDescription>
            {mode === "append"
              ? `Append citations to ${
                  elementType === ElementType.FEATURE ? "feature" : "claim"
                } ${claimNumber} from another element.`
              : `Replace citations for ${
                  elementType === ElementType.FEATURE ? "feature" : "claim"
                } ${claimNumber} with citations from another element.`}
          </DialogDescription>
        </DialogHeader>

        <div className="flex items-center gap-2">
          <Select
            value={selectedElement ?? ""}
            onValueChange={(value) => setSelectedElement(value)}
            disabled={isLoading}
          >
            <SelectTrigger className="w-[300px]">
              <SelectValue
                placeholder={`Select ${
                  elementType === ElementType.FEATURE ? "feature" : "claim"
                }`}
              />
            </SelectTrigger>
            <SelectContent>
              {elements.map((element) => (
                <SelectItem key={element} value={element}>
                  {element}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex gap-2 flex-col mt-2">
          <Label>Apply to </Label>
          <RadioGroup
            value={applyToAllReferences ? "all" : "selected"}
            onValueChange={(value) => setApplyToAllReferences(value === "all")}
            className="flex space-x-4"
          >
            <RadioGroupItemWithText value="selected" label="Selected reference only" />
            <RadioGroupItemWithText value="all" label="All references" />
          </RadioGroup>
        </div>

        <DialogFooter>
          <div className="flex items-center justify-end gap-2">
            <Button variant="outline" onClick={() => onClose()} disabled={isLoading}>
              Cancel
            </Button>
            <LoadingButton
              variant="default"
              onClick={handleSave}
              loading={isLoading}
              disabled={isLoading}
            >
              {isLoading
                ? mode === "append"
                  ? "Appending..."
                  : "Replacing..."
                : mode === "append"
                  ? "Append"
                  : "Replace"}
            </LoadingButton>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CopyCitationsModal;
