/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { useProjectStore } from "@/store";
import { splitId } from "@/utils/projectUtils";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";

interface ReferenceSelectProps {
  handleReferenceSelect: (selectedIds: string[]) => void;
  className?: string;
}

/**
 * @description Reference select to control references displayed in the table
 * @param {function} handleReferenceSelect - Function to handle the selection of references.
 */
const ReferenceSelect: React.FC<ReferenceSelectProps> = ({
  handleReferenceSelect,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const [tempSelectedDocumentIds, setTempSelectedDocumentIds] = useState<string[]>([]);

  const { currentProject, selectedReferences } = useProjectStore();
  const referenceIds: string[] = currentProject.referenceIds || [];
  const documentsToNumbers = currentProject.documentsToNumbers;
  const documentsToNicknames = currentProject.documentsToNicknames;

  useEffect(() => {
    if (tempSelectedDocumentIds.length > 0) {
      const updatedSelectedIds = tempSelectedDocumentIds.filter((id) =>
        referenceIds.includes(id),
      );
      setTempSelectedDocumentIds(updatedSelectedIds);
    }
  }, [referenceIds]);

  useEffect(() => {
    setTempSelectedDocumentIds(selectedReferences.map((ref) => ref.id));
  }, [selectedReferences]);

  const handleClose = () => {
    handleReferenceSelect(tempSelectedDocumentIds);
    setOpen(false);
  };

  const handleReferenceToggle = (id: string) => {
    setTempSelectedDocumentIds((prev) => {
      const newSelection = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];
      return newSelection.slice(0, 3); // Limit to 3 selections
    });
  };

  const getDisplayText = (id: string) => {
    const number = documentsToNumbers?.[id] ? splitId(documentsToNumbers[id]) : "";
    const nickname = documentsToNicknames?.[id] || "";
    if (nickname === "") {
      return `${number}`;
    }
    return `${nickname}`;
  };

  return (
    <Popover
      open={open}
      onOpenChange={(isOpen) => (isOpen ? setOpen(true) : handleClose())}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("justify-between flex-grow", className)}
        >
          <div className="flex items-center space-x-1 truncate">
            {tempSelectedDocumentIds.length > 0 ? (
              <>
                {tempSelectedDocumentIds.map((id) => (
                  <span
                    key={id}
                    className="text-secondary-foreground px-2 rounded border"
                  >
                    {documentsToNicknames[id]}
                  </span>
                ))}
              </>
            ) : (
              <span>Select references</span>
            )}
          </div>
          <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        className="p-0 w-auto min-w-[var(--radix-popover-trigger-width)] max-w-[300px]"
        align="start"
      >
        <ScrollArea className="h-[300px]">
          <div className="p-1">
            {referenceIds.map((id) => (
              <Button
                key={id}
                variant="ghost"
                className="w-full justify-start text-left"
                onClick={() => handleReferenceToggle(id)}
              >
                <div className="flex items-center w-full">
                  {tempSelectedDocumentIds.includes(id) && (
                    <CheckIcon className="mr-2 h-4 w-4 flex-shrink-0" />
                  )}
                  <span className="truncate">{getDisplayText(id)}</span>
                </div>
              </Button>
            ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};

export default ReferenceSelect;
