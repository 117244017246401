/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore } from "@/store";
import React from "react";

import BaseUploadFilesModal from "@/components/files/BaseUploadFilesModal";
import useProcess from "@/hooks/useProcess";

interface UploadFilesToPortfolioModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Modal for uploading files to a portfolio
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
const UploadFilesToPortfolioModal: React.FC<UploadFilesToPortfolioModalProps> = ({
  open,
  handleClose,
}) => {
  const { currentPortfolioId } = useProjectStore();
  const { processDocuments } = useProcess();

  // Handle the add to project button click
  const handleAddFiles = async (files: File[]) => {
    handleClose();
    processDocuments(
      currentPortfolioId,
      null, // project id
      null, // patent numbers
      files,
    );
  };

  return (
    <BaseUploadFilesModal
      open={open}
      handleCloseModal={handleClose}
      handleAddFilesClick={handleAddFiles}
      title="Add references to all subjects"
      buttonLabel="Add References"
      showCheckbox={false}
    />
  );
};

export default UploadFilesToPortfolioModal;
