/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { PatentSourceNumberButton } from "@/components";
import { Button } from "@/components/ui/button";
import { DateTimePicker } from "@/components/ui/datetime-picker";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
  TableWrapper,
} from "@/components/ui/table";
import { useProject } from "@/hooks";
import { useProjectStore } from "@/store";
import { ParentType, Patent } from "@/types";
import { CheckIcon, Pencil1Icon } from "@radix-ui/react-icons";
import { DateTime } from "luxon";
import React, { useState } from "react";

interface DocumentMetadataProps {
  details: Patent;
  showHead: boolean;
  subjectPage?: boolean;
  projectPriorityDate?: DateTime;
  enableEditPriorityDate?: boolean;
}

/**
 * @description Document metadata table
 */
const DocumentMetadata: React.FC<DocumentMetadataProps> = ({
  details,
  showHead,
  subjectPage,
  projectPriorityDate,
  enableEditPriorityDate = false,
}) => {
  const { updateProjectDetails } = useProject();
  const {
    currentProjectId,
    updateCurrentProject,
    currentProject,
    currentParent,
    currentPortfolio,
    updateCurrentPortfolio,
  } = useProjectStore();
  const [isEditingPriorityDate, setIsEditingPriorityDate] = useState(false);

  // Use project date if edited, otherwise use priority date from patent details. fallback to filing date
  const [priorityDate, setPriorityDate] = useState<DateTime>(() => {
    if (projectPriorityDate) {
      return DateTime.isDateTime(projectPriorityDate)
        ? projectPriorityDate
        : DateTime.fromISO(projectPriorityDate, { zone: "utc" });
    } else if (details.priorityDate) {
      return DateTime.fromISO(details.priorityDate, { zone: "utc" });
    } else if (details.filingDate) {
      return DateTime.fromISO(details.filingDate, { zone: "utc" });
    }
    return DateTime.now().setZone("utc").startOf("day");
  });

  const handleSavePriorityDate = () => {
    try {
      const utcPriorityDate = DateTime.utc(
        priorityDate.year,
        priorityDate.month,
        priorityDate.day,
      );
      updateCurrentProject({
        id: currentProject.id,
        name: currentProject.name,
        priorityDate: utcPriorityDate,
      });
      updateProjectDetails(currentProjectId, {
        priority_date: utcPriorityDate.toISO(),
      });

      if (currentParent === ParentType.PORTFOLIO) {
        // Find and update the project within the portfolio
        const updatedProjects = currentPortfolio.projects.map((project) =>
          project.id === currentProjectId
            ? {
                ...project,
                subject: {
                  ...project.subject,
                  priorityDate: utcPriorityDate,
                },
              }
            : project,
        );

        updateCurrentPortfolio({
          ...currentPortfolio,
          projects: updatedProjects,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setIsEditingPriorityDate(false);
  };

  const handleEditPriorityDate = () => {
    setIsEditingPriorityDate(true);
  };

  return (
    <div className="border border-gray-200 rounded-lg">
      <TableWrapper>
        <Table>
          {showHead && (
            <TableHeader sticky={false}>
              <TableRow>
                {!subjectPage && (
                  <>
                    <TableHead>Number</TableHead>
                    <TableHead>Title</TableHead>
                  </>
                )}
                <TableHead className="whitespace-nowrap">Publication Date</TableHead>
                <TableHead className="whitespace-nowrap">
                  <div className="flex items-center  gap-2">
                    Priority Date
                    {enableEditPriorityDate && (
                      <Button
                        size="icon"
                        variant="ghost"
                        onClick={
                          isEditingPriorityDate
                            ? handleSavePriorityDate
                            : handleEditPriorityDate
                        }
                      >
                        {isEditingPriorityDate ? <CheckIcon /> : <Pencil1Icon />}
                      </Button>
                    )}
                  </div>
                </TableHead>
                {details.inventors && details.inventors != "" && (
                  <TableHead>
                    {details.inventors.length > 1 ? "Inventors" : "Inventor"}
                  </TableHead>
                )}
                {details.assignee && details.assignee != "" && (
                  <TableHead>Assignee</TableHead>
                )}
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            <TableRow>
              {!subjectPage && (
                <>
                  <TableCellCondensed className="whitespace-nowrap">
                    <PatentSourceNumberButton
                      sourceNumber={details.number}
                      prefix={details.prefix || ""}
                    />
                  </TableCellCondensed>
                  <TableCellCondensed>{details.name}</TableCellCondensed>
                </>
              )}
              <TableCellCondensed className="whitespace-nowrap">
                {DateTime.fromISO(details.publicationDate, {
                  zone: "utc",
                }).toFormat("MM/dd/yyyy") || "—"}
              </TableCellCondensed>
              <TableCellCondensed className="whitespace-nowrap">
                {isEditingPriorityDate ? (
                  <DateTimePicker
                    value={priorityDate ? priorityDate.toUTC().toJSDate() : null}
                    granularity="day"
                    onChange={(date) =>
                      setPriorityDate(
                        date ? DateTime.fromJSDate(date, { zone: "utc" }) : null,
                      )
                    }
                  />
                ) : (
                  priorityDate.toFormat("MM/dd/yyyy")
                )}
              </TableCellCondensed>
              {details.inventors && details.inventors != "" && (
                <TableCellCondensed>
                  {" "}
                  {Array.isArray(details.inventors)
                    ? details.inventors.join(", ")
                    : details.inventors || "—"}
                </TableCellCondensed>
              )}
              {details.assignee && details.assignee != "" && (
                <TableCellCondensed>{details.assignee || "—"}</TableCellCondensed>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
};

export default DocumentMetadata;
