/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks";
import { useAppStateStore, useProcessStore, useProjectStore } from "@/store";
import {
  ApiResponse,
  ChartColor,
  CitationLocation,
  ElementType,
  InvalidityCitation,
  ProcessType,
} from "@/types";
import { toCamelCase } from "@/utils/dataUtils";
import { convertToUtcDateString } from "@/utils/dateUtils";
import { getHighestRank, splitId } from "@/utils/projectUtils";
import { nanoid } from "nanoid";

export const useDataTable = () => {
  const { postRequest, getRequest, handleError } = useApi();
  const {
    currentProjectId,
    currentPortfolioId,
    currentProject,
    chartData,
    summaryChartData,
    selectedElementType,
    selectedReferences,
    selectedElements,
    selectedColors,
    updateChartData,
    updateSummaryChartData,
    updateSummaryChartHeaders,
    updateSummaryChartRowHeightCollapsed,
  } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { addProcess, removeProcess } = useProcessStore();

  const arraysEqual = (a?: number[], b?: number[]): boolean => {
    if (!a || !b) return a === b;
    if (a.length !== b.length) return false;
    return a.every((val, index) => val === b[index]);
  };

  /**
   * @description Fetches the summary chart data
   * @param {string} projectId - The id of the project to fetch the summary chart data for
   * @param {string} type - The type of the summary chart data to fetch
   */
  const getSummaryChartData = async (
    projectId: string,
    type: string,
    assertedClaimsOnly?: boolean,
  ): Promise<ApiResponse> => {
    try {
      if (!projectId) {
        return { success: false, data: [], status: 400 };
      }

      const payload: {
        project_id: string;
        is_feature: boolean;
        asserted_claims_only?: boolean;
      } = {
        project_id: projectId,
        is_feature: type !== "claim",
      };
      if (assertedClaimsOnly) {
        payload.asserted_claims_only = assertedClaimsOnly;
      }

      const response = await getRequest("get_summary_chart", payload);

      const summaryData = response.data;

      if (!Array.isArray(summaryData) || summaryData.length === 0) {
        return { success: true, data: [], status: response.status };
      }

      const filteredSummaryData = summaryData.filter(
        (row) => row.claim_number !== "Preamble",
      );

      // Checks for null currentProject references and nicknames map
      if (!currentProject?.documentsToNicknames || !currentProject?.references) {
        // If required data is missing, return filtered data without nickname processing
        updateSummaryChartData(filteredSummaryData);
        return { success: true, data: response.data, status: response.status };
      }

      // Deduplicate column headers
      // Group IDs by nickname and find the most recent for each group
      const nicknameGroups: { [nickname: string]: string } = {};
      Object.entries(currentProject.documentsToNicknames).forEach(([id, nickname]) => {
        if (nickname && nickname !== "null") {
          const reference = currentProject.references.find((ref) => ref.id === id);
          if (reference) {
            const existingId = nicknameGroups[nickname];
            if (!existingId) {
              nicknameGroups[nickname] = id;
            } else {
              // Count gray cells in summary chart for both references
              const currentGrayCount = summaryChartData.filter(
                (row) => row[id] === ChartColor.GRAY,
              ).length;
              const existingGrayCount = summaryChartData.filter(
                (row) => row[existingId] === ChartColor.GRAY,
              ).length;

              // If current reference has fewer grays, use it
              if (currentGrayCount < existingGrayCount) {
                nicknameGroups[nickname] = id;
              }
            }
          }
        }
      });

      // Keep original order of column headers, but deduplicate
      const uniqueColumnHeaders = ["claim_number"];
      Object.keys(filteredSummaryData[0]).forEach((header) => {
        if (
          header !== "claim_number" &&
          Object.values(nicknameGroups).includes(header)
        ) {
          uniqueColumnHeaders.push(header);
        }
      });

      // Update the filtered data to only include unique columns
      const deduplicatedSummaryData = filteredSummaryData.map((row) => {
        const newRow: { [key: string]: any } = {};
        uniqueColumnHeaders.forEach((header) => {
          newRow[header] = row[header];
        });
        return newRow;
      });

      const validHeaders = uniqueColumnHeaders.filter((key) => key !== "claim_number");
      updateSummaryChartHeaders(validHeaders);

      const totalContainerHeight = 250 - 60;
      const numRows = deduplicatedSummaryData.length;
      const rowHeightCollapsed = totalContainerHeight / numRows;
      updateSummaryChartRowHeightCollapsed(rowHeightCollapsed);
      updateSummaryChartData(deduplicatedSummaryData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error fetching summary chart data");
    }
  };

  /**
   * @description Fetches the reference chart data
   * @param {string} projectId - The id of the project to fetch the reference chart data for
   * @param {array} referenceIds - The ids of the references to fetch the chart data for
   * @param {string} type - The type of the reference chart data to fetch
   * @param {array} subset - The subset of the reference chart data to fetch
   */
  const getReferenceChartData = async (
    projectId: string,
    referenceIds: string[],
    is_feature: boolean,
    subset: string[],
    colors: string[],
    updateChart: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_reference_chart_data", {
        project_id: projectId,
        reference_ids: referenceIds,
        is_feature: is_feature,
        subset: subset,
        colors: colors,
      });
      const tempChartData = response.data;
      const filteredChartData = tempChartData.filter(
        (row) => row.claim_number !== "Preamble",
      );

      if (updateChart) {
        updateChartData(filteredChartData);
      }

      return {
        success: true,
        data: filteredChartData,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching reference chart data");
    }
  };

  /**
   * @description Updates the language of a reference citation
   * @param {string} citationId - The id of the citation to update the language for
   * @param {object} options - Additional options to pass to the request
   */
  const updateReferenceCitation = async (
    citationId: string,
    options: { [key: string]: any } = {},
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      citation_id: citationId,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest("post_update_reference_citation", payload);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating citation language");
    }
  };

  /**
   * @description Adds a reference citation to the project
   * @param {string} invalidityId - The id of the invalidity to add the citation to
   * @param {string} organizationId - The id of the organization to add the citation to
   * @param {string} color - The color of the citation to add
   * @param {string} text - The text of the citation to add
   * @param {string} paragraph - The paragraph of the citation to add
   * @param {array} figureRefs - The references of the figures to add to the citation
   * @param {array} figureUrls - The URLs of the figures to add to the citation
   */
  const addReferenceCitation = async (
    invalidityId: string,
    referenceId: string,
    color: string,
    text: string,
    location: CitationLocation,
    projectId: string,
    isFeature: boolean,
    figureUrls: string[],
    figureRefs: string[],
    claimNumber: string,
  ): Promise<ApiResponse> => {
    try {
      const cleanLocation: CitationLocation = {
        paragraph: location.paragraph,
      };

      if (location.pages?.length > 0) {
        cleanLocation.pages = location.pages;
      }

      if (location.lines?.length > 0) {
        cleanLocation.lines = location.lines;
      }

      if (location.columns?.length > 0) {
        cleanLocation.columns = location.columns;
      }

      const response = await postRequest("post_add_reference_citation", {
        invalidity_id: invalidityId,
        color: color,
        text: text,
        figure_urls: figureUrls,
        figure_refs: figureRefs,
        score: null,
        project_id: projectId,
        is_feature: isFeature,
        document_id: referenceId,
        claim_number: claimNumber,
        location: cleanLocation,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error adding reference citation");
    }
  };

  /**
   * @description Deletes a reference citation from a project
   * @param {string} invalidityCitationId - The id of the invalidity citation to delete
   */
  const toggleReferenceCitationDeletion = async (
    invalidityCitationId: string,
    toggleRemovedStateTo: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_toggle_reference_citation_deletion", {
        invalidity_citation_id: invalidityCitationId,
        removed: toggleRemovedStateTo,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error deleting reference citation");
    }
  };

  /**
   * @description Deletes a reference citation from a project
   * @param {string} invalidityCitationId - The id of the invalidity citation to delete
   */
  const deleteAllCitationInstances = async (
    invalidityCitationId: string,
    referenceId: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_toggle_reference_citation_deletion", {
        invalidity_citation_id: invalidityCitationId,
        removed: true,
        propagate_changes: true,
      });

      if (Array.isArray(response.data)) {
        // Update the frontend chart data to mark all matching citations as removed
        const newChartData = [...chartData];
        const citationIdsToRemove = response.data;

        // Track which rows need summary table updates
        const summaryUpdates = new Set<number>();

        newChartData.forEach((row, rowIndex) => {
          if (row[referenceId]?.citations) {
            let colorUpdateNeeded = false;
            row[referenceId].citations = row[referenceId].citations.map(
              (citation: InvalidityCitation) => {
                if (citationIdsToRemove.includes(citation.id)) {
                  colorUpdateNeeded = true;
                  return {
                    ...citation,
                    removed: true,
                    updatedAt: new Date().toISOString(),
                  };
                }
                return citation;
              },
            );

            // If removed any citations, summary update may be needed
            if (colorUpdateNeeded) {
              summaryUpdates.add(rowIndex);
            }
          }
        });

        // Update summary table colors
        if (summaryChartData && summaryChartData.length > 0) {
          let newSummaryChartData = [...summaryChartData];

          summaryUpdates.forEach((rowIndex) => {
            const row = newChartData[rowIndex];
            const citations = row[referenceId].citations;

            newSummaryChartData = updateSummaryCell(
              row.claim_number,
              citations,
              newSummaryChartData,
              referenceId,
            );
          });

          updateSummaryChartData(newSummaryChartData);
        }

        updateChartData(newChartData);
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error deleting reference citation");
    }
  };

  const updateSummaryCell = (
    claimNumber: string,
    citations: InvalidityCitation[],
    newSummaryChartData: any[],
    referenceId: string,
  ) => {
    const activeCitations = citations.filter((c) => !c.removed);
    const newColor =
      activeCitations.length > 0
        ? getHighestRank(activeCitations.map((c) => c.color))
        : ChartColor.GRAY;

    // Find corresponding row in summary chart
    const summaryRowIndex = newSummaryChartData.findIndex(
      (summaryRow) => summaryRow.claim_number === claimNumber,
    );

    if (summaryRowIndex !== -1) {
      newSummaryChartData[summaryRowIndex][referenceId] = newColor;
    }

    return newSummaryChartData;
  };

  const getElementChartExport = async (
    projectId: string,
    referenceIds: string[],
    includeFeatures: boolean,
    includeClaims: boolean,
    includeSummary: boolean,
    introductionLanguage: string,
    colors: string[],
    features: string[],
    claims: string[],
    exportType: string,
    citationPosition: string,
    patentCitationFormat: string,
    applicationCitationFormat: string,
    documentCitationFormat: string,
    foreignDocumentCitationFormat: string,
    figureFormat: string,
    figurePlacement: string,
    titleFormat: string,
  ): Promise<ApiResponse> => {
    const key = nanoid();
    try {
      addProcess({
        id: key,
        type: ProcessType.DOWNLOAD_CHART,
        projectId: projectId,
        portfolioId: currentPortfolioId,
      });
      const response = await getRequest("get_project_export", {
        project_id: projectId,
        reference_ids: referenceIds,
        include_features: includeFeatures,
        include_claims: includeClaims,
        include_summary: includeSummary,
        introduction_language: introductionLanguage,
        color_subset: colors,
        feature_subset: features,
        claim_subset: claims,
        export_type: exportType,
        citation_position: citationPosition,
        patent_citation_format: patentCitationFormat,
        application_citation_format: applicationCitationFormat,
        document_citation_format: documentCitationFormat,
        foreign_document_citation_format: foreignDocumentCitationFormat,
        figure_citation_format: figureFormat,
        figure_placement: figurePlacement,
        title_format: titleFormat,
      });

      const downloadFile = async (url: string, fileType: string) => {
        const fileResponse = await fetch(url);
        if (!fileResponse.ok)
          throw new Error(`Failed to download the ${fileType} file`);
        const fileBlob = await fileResponse.blob();
        const localUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = localUrl;
        const fileName = currentProject.name.replace(/[\s']/g, "");
        const extension = fileType === "excel" ? "xlsx" : "zip";
        link.setAttribute("download", `${fileName}_${fileType}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(localUrl);
      };

      if (response.data.urls) {
        // Handle multiple URLs
        if (response.data.urls.word) {
          await downloadFile(response.data.urls.word, "word");
        }
        if (response.data.urls.excel) {
          await downloadFile(response.data.urls.excel, "excel");
        }
      } else if (response.data.url) {
        // Handle single URL
        await downloadFile(response.data.url, exportType);
      } else {
        if (process.env.NODE_ENV !== "production") {
          console.error("No download URL found");
        }
        addErrorMessage("Error downloading file. Try again later.");
        return;
      }
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting element chart export for word");
    } finally {
      removeProcess(key);
    }
  };

  /**
   * @description Saves a citation after adding/editing
   * @param {number} index - the index of the row to save the citation to
   * @param {string} referenceId - the id of the reference to save the citation to
   * @param {number} citationIndex - the index of the citation to save
   * @param {string} citationTextEdit - the text of the citation to save
   * @param {string} citationParagraphEdit - the paragraph of the citation to save
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   * @param {Array} figureUrls - the URLs of the figures to save to the citation
   */
  const addCitationFromPatentViewer = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    citationTextEdit: string,
    citationLocationEdit: CitationLocation,
    isAddingCitation: boolean,
    figureUrls: string[],
    invalidityId: string,
    figureRefs: string[],
    setIsEditing: (isEditing: boolean) => void,
    setAddingCitationKey: (key: string) => void,
    claimNumberFromPatentViewer?: string,
  ) => {
    setIsEditing(false);
    let newCitation: InvalidityCitation;
    let color = ChartColor.GRAY;
    let updatedCitations: InvalidityCitation[] = [];
    let claimNumber: string;

    color = ChartColor.GREEN;
    if (chartData && chartData.length > 0 && index !== -1) {
      claimNumber = chartData[index].claim_number;
      updatedCitations = [...chartData[index][referenceId]["citations"]];
      if (!claimNumberFromPatentViewer) {
        updatedCitations.splice(citationIndex, 1); // remove empty citation
      }

      if (summaryChartData && summaryChartData.length > 0) {
        updateSummaryChartColorByIndex(
          "#D3D3D3",
          color,
          index,
          citationIndex,
          referenceId,
        );
      }
    }

    const addResponse = await addReferenceCitation(
      invalidityId,
      referenceId,
      color,
      citationTextEdit,
      citationLocationEdit,
      currentProjectId,
      selectedElementType === ElementType.FEATURE,
      figureUrls,
      figureRefs,
      claimNumberFromPatentViewer || claimNumber,
    );

    if (!addResponse.success) {
      addErrorMessage(
        `Error adding citation ${citationLocationEdit.paragraph} from project ${currentProject.name} `,
      );
    } else {
      newCitation = addResponse.data;

      if (chartData && chartData.length > 0 && index !== -1) {
        if (updatedCitations?.length > 0) {
          updatedCitations.push(toCamelCase(newCitation));
        } else {
          updatedCitations = [toCamelCase(newCitation)];
        }

        const newChartData = chartData.map((item, idx) =>
          idx === index
            ? {
                ...item,
                [referenceId]: {
                  ...item[referenceId],
                  citations: updatedCitations,
                },
              }
            : { ...item },
        );
        updateChartData(newChartData);
      }
    }

    setAddingCitationKey("");

    return toCamelCase(newCitation);
  };

  /**
   * @description Saves a citation after adding/editing
   * @param {number} index - the index of the row to save the citation to
   * @param {string} referenceId - the id of the reference to save the citation to
   * @param {number} citationIndex - the index of the citation to save
   * @param {string} citationTextEdit - the text of the citation to save
   * @param {string} citationParagraphEdit - the paragraph of the citation to save
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   * @param {Array} figureUrls - the URLs of the figures to save to the citation
   */
  const saveCitationUpdate = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    citationTextEdit: string,
    citationLocationEdit: CitationLocation,
    figureUrls: {
      newFigureUrls?: string[];
      oldFigureUrls?: string[];
    },

    citation: InvalidityCitation,
    setIsEditing: (isEditing: boolean) => void,
    propagateChanges: boolean = false,
  ) => {
    setIsEditing(false);

    const newChartData = [...chartData];
    const newCitation = newChartData[index][referenceId]["citations"][citationIndex];
    const payload: { [key: string]: any } = {};
    const oldText = citation.text;

    // Handle figure URLs
    if (figureUrls.newFigureUrls?.length > 0) {
      payload.new_figure_urls = figureUrls.newFigureUrls;
      // Update the citation's figureUrls in the chart data by adding new URLs
      const currentUrls =
        newChartData[index][referenceId]["citations"][citationIndex].figureUrls || [];
      newChartData[index][referenceId]["citations"][citationIndex].figureUrls = [
        ...currentUrls,
        ...figureUrls.newFigureUrls,
      ];
    }

    if (figureUrls.oldFigureUrls?.length > 0) {
      payload.old_figure_urls = figureUrls.oldFigureUrls;
      // Update the citation's figureUrls in the chart data by removing old URLs
      const currentUrls =
        newChartData[index][referenceId]["citations"][citationIndex].figureUrls || [];
      newChartData[index][referenceId]["citations"][citationIndex].figureUrls =
        currentUrls.filter((url) => !figureUrls.oldFigureUrls.includes(url));
    }

    if (citationLocationEdit.paragraph !== citation.paragraph) {
      newChartData[index][referenceId]["citations"][citationIndex].paragraph =
        citationLocationEdit.paragraph;
      payload.new_paragraph = citationLocationEdit.paragraph;
    }

    if (citationLocationEdit.claimSection !== citation.claimSection) {
      newChartData[index][referenceId]["citations"][citationIndex].claimSection =
        citationLocationEdit.claimSection;
      payload.new_claim_section = citationLocationEdit.claimSection;
    }
    if (!arraysEqual(citationLocationEdit.pages, citation.pages)) {
      const newPages = citationLocationEdit.pages?.filter(
        (page) => page !== null && !Number.isNaN(page),
      );
      newChartData[index][referenceId]["citations"][citationIndex].pages = newPages;
      payload.new_pages = newPages.length > 0 ? newPages : null;
    }

    // Updated logic for lines
    if (!arraysEqual(citationLocationEdit.lines, citation.lines)) {
      const newLines = citationLocationEdit.lines?.filter(
        (line) => line !== null && !Number.isNaN(line),
      );
      newChartData[index][referenceId]["citations"][citationIndex].lines = newLines;
      payload.new_lines = newLines.length > 0 ? newLines : null;
    }

    // Updated logic for columns
    if (!arraysEqual(citationLocationEdit.columns, citation.columns)) {
      const newColumns = citationLocationEdit.columns?.filter(
        (col) => col !== null && !Number.isNaN(col),
      );
      newChartData[index][referenceId]["citations"][citationIndex].columns = newColumns;
      payload.new_columns = newColumns.length > 0 ? newColumns : null;
    }

    if (citationTextEdit !== citation.text) {
      newChartData[index][referenceId]["citations"][citationIndex].text =
        citationTextEdit;
      payload.new_text = citationTextEdit;
    }

    payload.propagate_changes = propagateChanges;

    // If propagating changes, update all matching citations in the store
    if (propagateChanges) {
      newChartData.forEach((row, rowIndex) => {
        Object.keys(row).forEach((refId) => {
          if (typeof row[refId] === "object" && row[refId]?.citations) {
            row[refId].citations.forEach((cit: InvalidityCitation, citIdx: number) => {
              if (cit.text === oldText) {
                const newCitation = {
                  ...cit,
                  text: citationTextEdit,
                  ...(payload.new_paragraph && {
                    paragraph: payload.new_paragraph,
                  }),
                  ...(payload.new_pages && { pages: [...payload.new_pages] }),
                  ...(payload.new_lines && { lines: [...payload.new_lines] }),
                  ...(payload.new_columns && {
                    columns: [...payload.new_columns],
                    ...(payload.new_claim_section && {
                      claimSection: payload.new_claim_section,
                    }),
                  }),
                  figureUrls: payload.old_figure_urls
                    ? (cit.figureUrls || []).filter(
                        (url) => !payload.old_figure_urls.includes(url),
                      )
                    : cit.figureUrls,
                };

                // Add new figure URLs if they exist
                if (payload.new_figure_urls?.length) {
                  newCitation.figureUrls = [
                    ...(newCitation.figureUrls || []),
                    ...payload.new_figure_urls,
                  ];
                }

                newChartData[rowIndex][refId].citations[citIdx] = newCitation;
              }
            });
          }
        });
      });
    }

    updateChartData(newChartData);

    const response = await updateReferenceCitation(citation.id, payload);
    if (!response.success) {
      addErrorMessage(
        `Error updating citation ${citationLocationEdit.paragraph} from project ${currentProject.name} `,
      );
    }
    return toCamelCase(newCitation);
  };

  /**
   * @description Deletes a citation
   * @param {number} itemIndex - the index of the row to delete the citation from
   * @param {number} citationIndex - the index of the citation to delete
   * @param {string} referenceId - the id of the reference to delete the citation from
   * @param {InvalidityCitation} citation - the citation to delete
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   */
  const handleSourceDeleteToggle = async (
    itemIndex: number,
    referenceId: string,
    citationId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean,
    toggleRemovedStateTo: boolean,
  ) => {
    let citationIndex;
    let updatedCitations;
    const newChartData = [...chartData];
    if (chartData && chartData.length > 0 && itemIndex !== -1) {
      updatedCitations = [...newChartData[itemIndex][referenceId]["citations"]];

      // Find the index of the citation by id
      citationIndex = updatedCitations.findIndex((c) => c.id === citationId);
    }

    if (citationIndex !== -1) {
      if (chartData && chartData.length > 0 && itemIndex !== -1) {
        // Mark the citation as removed without splicing the array
        updatedCitations[citationIndex] = {
          ...updatedCitations[citationIndex],
          removed: toggleRemovedStateTo,
          updatedAt: new Date().toISOString(),
        };

        // Update the chart data
        newChartData[itemIndex][referenceId]["citations"] = updatedCitations;
        updateChartData(newChartData);
      }

      if (summaryChartData && summaryChartData.length > 0) {
        if (updatedCitations.every((c) => c.removed)) {
          // If all citations are removed, update to gray
          updateSummaryChartColorByIndex(
            ChartColor.GRAY,
            ChartColor.GRAY,
            itemIndex,
            citationIndex,
            referenceId,
            true,
          );
        } else {
          // Get current summary color and calculate new highest color
          const currentSummaryColor = summaryChartData[itemIndex][referenceId];
          const newHighestColor = getHighestRank(
            updatedCitations.filter((c) => !c.removed).map((c) => c.color),
          );

          // Use existing updateSummaryChartColor function
          updateSummaryChartColorByIndex(
            currentSummaryColor,
            newHighestColor,
            itemIndex,
            citationIndex,
            referenceId,
            true,
          );
        }
      }

      if (!isAddingCitation) {
        const response = await toggleReferenceCitationDeletion(
          citation.id,
          toggleRemovedStateTo,
        );
        if (!response.success) {
          addErrorMessage(
            `Error toggling citation deletion from project ${currentProject.name}`,
          );
        }
      }
    } else {
      console.error("Citation not found in chartData.");
    }
  };

  /**
   * @description Retrieves the invalidity for a claim
   * @param {string} projectId - The id of the project to retrieve the invalidity for
   * @param {string} claim_number - The claim number to retrieve the invalidity for
   * @param {string} referenceId - The reference id to retrieve the invalidity for
   * @param {string} claim_language - The language of the element
   * @param {boolean} is_feature - Whether the element is a feature
   * @param {array[string]} positive_examples- The positive invalidities to retrieve
   * @param {array[string]} negative_examples - The negative invalidities to retrieve
   * @returns Result object with success status and data or error message.
   */
  const retrieveInvalidityForClaim = async (
    projectId: string,
    claim_number: string,
    referenceId: string,
    claim_language: string,
    is_feature: boolean,
    positive_examples: string[],
    negative_examples: string[],
    rowIndex: number,
  ) => {
    try {
      const response = await postRequest("post_retrieve_invalidity_for_claim", {
        project_id: projectId,
        claim_number: claim_number,
        reference_id: referenceId,
        claim_language: claim_language,
        is_feature: is_feature,
        positive: positive_examples,
        negative: negative_examples,
      });

      const citations = toCamelCase(response.data[referenceId]);

      if (citations && citations.length > 0) {
        // Create new chart data array
        const newChartData = [...chartData];

        // Update the citations for the specific row and reference
        if (newChartData[rowIndex] && newChartData[rowIndex][referenceId]) {
          // Append new citations to existing ones
          newChartData[rowIndex][referenceId].citations = [
            ...citations.map((citation) => ({
              ...citation,
              // Ensure all required properties exist
              color: citation.color || ChartColor.GRAY,
              text: citation.text || "",
              paragraph: citation.paragraph || null,
              pages: citation.pages || [],
              lines: citation.lines || [],
              columns: citation.columns || [],
              figure_refs: citation.figure_refs || [],
              figure_urls: citation.figure_urls || [],
              id: citation.id,
            })),
          ];

          // Update the chart data
          updateChartData(newChartData);
        }

        // Update summary table
        summaryTableNewInvalidityUpdate(rowIndex, referenceId, citations);
      }
      return { success: true, status: response.status, data: citations };
    } catch (error) {
      return handleError(error, "Error retrieving invalidity for claim");
    }
  };

  const summaryTableNewInvalidityUpdate = (
    claimIndex: number,
    reference_id: string,
    newInvalidity: InvalidityCitation[],
  ) => {
    const colors: string[] = [];
    // const invalidityArray = JSON.parse(newInvalidity);
    newInvalidity.forEach((citation: InvalidityCitation) => {
      if (citation.color) {
        colors.push(citation.color);
      }
    });

    const color = getHighestRank(colors);
    const newSummaryChartData = [...summaryChartData];

    // Ensure the claimIndex is within bounds
    if (!newSummaryChartData[claimIndex]) {
      newSummaryChartData[claimIndex] = {};
    }

    newSummaryChartData[claimIndex][reference_id] = color;
    updateSummaryChartData(newSummaryChartData);
  };

  /**
   * @description Extracts the colors of the other citations in a row
   * @param {number} index - the index of the row to extract the colors from
   * @param {number} citation - the index of the citation to exclude
   * @param {string} referenceId - the id of the reference to extract the colors from
   */
  const extractOtherColors = (index: number, citation: number, referenceId: string) => {
    const colors: string[] = [];
    const row = chartData[index][referenceId]["citations"];
    if (Array.isArray(row)) {
      row.forEach((item, itemIndex) => {
        // Check if the current citation index is not the one to be excluded and has a color property
        if (itemIndex !== citation && item.color) {
          colors.push(item.color);
        }
      });
    }
    return colors;
  };

  /**
   * @description Checks if a color update is required in the summary table
   * @param {string} currentColor - the current color of the citation
   * @param {string} newColor - the new color to set
   * @param {Array} otherColors - the colors of the other citations in the row
   */
  const isSummaryColorUpdateRequired = (
    currentColor: string,
    newColor: string,
    otherColors: string[],
  ) => {
    // Calculate the highest rank in the original set and the new set
    const originalHighestRank = getHighestRank([currentColor, ...otherColors]);
    const newHighestRank = getHighestRank([newColor, ...otherColors]);

    // Update required if the highest rank in the new set is different from the original set
    return newHighestRank !== originalHighestRank;
  };

  /**
   * @description Updates the color of a citation in the chart data
   * @param {number} index - the index of the row to update the color of
   * @param {number} citationIndex - the index of the citation to update the color of
   * @param {string} color - the new color to set
   * @param {string} referenceId - the id of the reference to update the color of
   * @param {string} citationParagraph - the paragraph of the citation to update
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   */
  const handleSourceColorUpdate = async (
    index: number,
    citationIndex: number,
    color: string,
    referenceId: string,
    citationId: string,
    isAddingCitation: boolean,
  ) => {
    let currentColor = ChartColor.GREEN;

    if (citationIndex !== -1 && chartData && chartData.length > 0) {
      // Find citation by ID instead of index
      const citation = chartData[index][referenceId]["citations"].find(
        (citation) => citation.id === citationId,
      );

      if (citation) {
        // Get current color to determine whether FE summary table update is needed
        currentColor = citation.color;

        // Change the color on the frontend
        const newChartData = [...chartData];
        const citationToUpdate = newChartData[index][referenceId]["citations"].find(
          (citation) => citation.id === citationId,
        );

        if (citationToUpdate) {
          citationToUpdate.color = color;
        }

        updateChartData(newChartData);
      }
    }

    if (!isAddingCitation) {
      if (summaryChartData && summaryChartData.length > 0) {
        // Update summary table color if needed
        updateSummaryChartColorByIndex(
          currentColor,
          color,
          index,
          citationIndex,
          referenceId,
        );
      }

      const updateResponse = await updateReferenceCitation(citationId, {
        new_color: color,
      });
      if (!updateResponse.success) {
        addErrorMessage(
          `Error updating citation color for ${citationIndex} from project ${currentProject.name} `,
        );
      }
    }
  };

  const updateInvalidityBoilerplate = async (
    projectId: string,
    claimNumber: string,
    isFeature: boolean,
    header?: string,
    footer?: string,
    updateAllElements?: boolean,
    index?: number,
  ) => {
    try {
      // Send raw template strings to backend
      const response = await postRequest("/post_update_invalidity_boilerplate", {
        project_id: projectId,
        claim_number: claimNumber,
        is_feature: isFeature,
        header: header ?? "",
        footer: footer ?? "",
        update_all_elements: updateAllElements,
      });

      const newChartData = [...chartData];
      const refIds = selectedReferences.map((ref) => ref.id);

      // Helper function to replace variables in text
      const replaceVariables = (text: string, row: any, referenceId: string) => {
        if (!text) return "";

        return text.replace(/{{(\w+)}}/g, (match, variable) => {
          switch (variable) {
            case "element_number":
              return row.claim_number ?? match;
            case "element_language":
              return row.claim_text ?? match;
            case "reference_name": {
              if (!currentProject?.documentsToNicknames) {
                return match;
              }
              return currentProject.documentsToNicknames[referenceId] || match;
            }
            case "subject_nickname": {
              if (!currentProject?.documentsToNicknames) {
                return match;
              }
              return (
                currentProject.documentsToNicknames[currentProject.subject?.id] || match
              );
            }
            case "subject_number": {
              return splitId(currentProject?.subject?.number) || match;
            }
            case "subject_priority_date": {
              return (
                convertToUtcDateString(currentProject?.subject?.priorityDate) || match
              );
            }
            default:
              return match;
          }
        });
      };

      if (!updateAllElements) {
        // Update specific row only
        const row = newChartData[index];
        refIds.forEach((refId) => {
          if (row[refId]) {
            // Store raw templates
            row[refId].headerTemplate = header;
            row[refId].footerTemplate = footer;
            // Store replaced text for display
            row[refId].header = replaceVariables(header, row, refId);
            row[refId].footer = replaceVariables(footer, row, refId);
          }
        });
      } else {
        // Update all rows
        newChartData.forEach((row) => {
          refIds.forEach((refId) => {
            if (row[refId]) {
              // Store raw templates
              row[refId].headerTemplate = header;
              row[refId].footerTemplate = footer;
              // Store replaced text for display
              row[refId].header = replaceVariables(header, row, refId);
              row[refId].footer = replaceVariables(footer, row, refId);
            }
          });
        });
      }

      updateChartData(newChartData);
      return { success: response.status === 200 };
    } catch (error) {
      addErrorMessage(
        `Error updating invalidity boilerplate for ${projectId} ${claimNumber} from project ${currentProject.name} `,
      );
      return { success: false };
    }
  };

  /**
   * @description Updates the summary chart color for a given reference and claim
   * @param {string} currentColor - The current color in the summary chart
   * @param {string} newColor - The new color to potentially set
   * @param {number} index - The index of the row in chart data
   * @param {number} citationIndex - The index of the citation
   * @param {string} referenceId - The reference ID
   */
  const updateSummaryChartColorByIndex = (
    currentColor: string,
    newColor: string,
    index: number,
    citationIndex: number,
    referenceId: string,
    forceUpdate: boolean = false,
  ) => {
    if (!summaryChartData?.length) return;

    let isColorUpdate = forceUpdate;

    if (!isColorUpdate) {
      const otherColors = extractOtherColors(index, citationIndex, referenceId);
      isColorUpdate = isSummaryColorUpdateRequired(currentColor, newColor, otherColors);
    }

    if (isColorUpdate) {
      const newSummaryChartData = [...summaryChartData];
      const claimNumber = chartData[index].claim_number;
      const summaryRowIndex = newSummaryChartData.findIndex(
        (row) => row.claim_number === claimNumber,
      );

      if (summaryRowIndex !== -1) {
        newSummaryChartData[summaryRowIndex][referenceId] = newColor;
        updateSummaryChartData(newSummaryChartData);
      }
    }
  };

  /**
   * @description Copies (appends or replaces) invalidity citations from one claim to another
   * @param {string} projectId - The ID of the project
   * @param {string} targetClaimNumber - The target claim number
   * @param {string} sourceClaimNumber - The source claim number
   * @param {string} referenceId - The reference ID
   * @param {boolean} deleteExisting - Whether to delete existing citations
   * @param {boolean} applyToAllReferences - Whether to apply to all references
   * @param {boolean} isFeature - Whether the citations are for a feature
   */
  const copyInvalidityCitations = async (
    projectId: string,
    targetClaimNumber: string,
    sourceClaimNumber: string,
    referenceId: string,
    deleteExisting: boolean,
    applyToAllReferences: boolean,
    isFeature: boolean,
    rowIndex: number,
  ) => {
    try {
      const response = await postRequest("/post_copy_invalidity_citations", {
        project_id: projectId,
        target_claim_number: targetClaimNumber,
        source_claim_number: sourceClaimNumber,
        delete_existing: deleteExisting, // Whether to delete existing citations (if replacing)
        apply_to_all_references: applyToAllReferences, // Whether to apply to all references
        reference_id: referenceId,
        is_feature: isFeature,
      });

      let newChartData = [...chartData];

      if (applyToAllReferences) {
        // Update all selected references if apply to all references is true
        for (const referenceId of selectedReferences.map((ref) => ref.id)) {
          let citations = response.data[referenceId];
          citations = toCamelCase(citations);
          newChartData = updateInvalidityCitations(
            newChartData,
            rowIndex,
            referenceId,
            citations,
          );
        }
      } else {
        const citations = toCamelCase(response.data[referenceId]);
        updateInvalidityCitations(newChartData, rowIndex, referenceId, citations);
      }

      // Update the chart data
      updateChartData(newChartData);

      return { success: response.status === 200 };
    } catch (error) {
      addErrorMessage(
        `Error copying invalidity citations for element ${targetClaimNumber}`,
      );
      return { success: false };
    }
  };

  const updateInvalidityCitations = (
    newChartData: any[],
    rowIndex: number,
    referenceId: string,
    citations: InvalidityCitation[],
  ) => {
    if (newChartData[rowIndex] && newChartData[rowIndex][referenceId]) {
      // Append new citations to existing ones
      newChartData[rowIndex][referenceId].citations = [
        ...citations.map((citation) => ({
          ...citation,
          // Ensure all required properties exist
          color: citation.color || ChartColor.GRAY,
          text: citation.text || "",
          paragraph: citation.paragraph || null,
          pages: citation.pages || [],
          lines: citation.lines || [],
          columns: citation.columns || [],
          figureRefs: citation.figureRefs || [],
          figureUrls: citation.figureUrls || [],
          id: citation.id,
        })),
      ];
    }
    return newChartData;
  };

  return {
    handleSourceDeleteToggle,
    handleSourceColorUpdate,
    saveCitationUpdate,
    getSummaryChartData,
    getReferenceChartData,
    getElementChartExport,
    updateInvalidityBoilerplate,
    addCitationFromPatentViewer,
    copyInvalidityCitations,
    retrieveInvalidityForClaim,
    deleteAllCitationInstances,
  };
};

export default useDataTable;
