/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { UploadedFileDisplay } from "@/components";
import UploadFileModal from "@/components/files/UploadFileModal";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItemWithText } from "@/components/ui/radio-group";
import { useCreateProjectStore } from "@/store";
import { ProcessType } from "@/types";
import { Document, DocumentType } from "@/types/project";
import React, { useEffect, useMemo, useState } from "react";
import { CreateStepGroup } from ".";

interface ApplicationOfficeActionStepProps {
  startExpanded: boolean;
  onCompletionChange: (isComplete: boolean) => void;
}

/**
 * @description Office Action step in create application project
 * @param {boolean} startExpanded - Whether the step is expanded.
 */
const ApplicationOfficeActionStep: React.FC<ApplicationOfficeActionStepProps> = ({
  startExpanded,
  onCompletionChange,
}) => {
  const {
    supportingDocuments,
    processes,
    updateSupportingDocuments,
    removeProcesses,
    updateSubjectNumbers,
  } = useCreateProjectStore();

  const infoPopoverContent = "Upload the office action PDF from the patent office.";

  // Upload file modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleContextAddClick = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const newDocument: Document = {
        documentType: DocumentType.OFFICE_ACTION,
        file: selectedFiles[0],
        fileName: selectedFiles[0].name,
      };
      // Remove previous file
      if (supportingDocuments.length > 0) {
        handleRemoveFile();
      }
      updateSupportingDocuments([newDocument]);
    }
  };

  const handleRemoveFile = () => {
    updateSupportingDocuments(
      supportingDocuments.filter(
        (doc) => doc.documentType !== DocumentType.OFFICE_ACTION,
      ),
    );
    removeProcesses(ProcessType.ADD_OFFICE_ACTION);
    updateSubjectNumbers([]);
  };

  // Tracking OAs
  const getOfficeActionDocument = () => {
    return supportingDocuments.find(
      (doc) => doc.documentType === DocumentType.OFFICE_ACTION,
    );
  };

  const isOfficeActionErrored = useMemo(() => {
    return processes.some(
      (process) =>
        process.type === ProcessType.ADD_OFFICE_ACTION && process.status === "error",
    );
  }, [processes]);

  // Add new state
  const [hasOfficeAction, setHasOfficeAction] = useState<string>("");

  // Modify step completion logic
  const isStepCompleted = useMemo(() => {
    return (
      hasOfficeAction === "no" ||
      (getOfficeActionDocument() !== undefined && !isOfficeActionErrored)
    );
  }, [getOfficeActionDocument, isOfficeActionErrored, hasOfficeAction]);

  // Update completion status when it changes
  useEffect(() => {
    onCompletionChange(isStepCompleted);
  }, [isStepCompleted, onCompletionChange]);

  return (
    <CreateStepGroup
      stepName="Office Action"
      isCompleted={isStepCompleted}
      startExpanded={startExpanded}
      infoPopoverContent={infoPopoverContent}
    >
      <div className="space-y-4">
        {!getOfficeActionDocument() && (
          <>
            <Label>Do you have an office action to upload?</Label>
            <RadioGroup
              value={hasOfficeAction}
              onValueChange={setHasOfficeAction}
              className="mb-4"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItemWithText
                  value="yes"
                  id="yes"
                  label="Yes, I have an office action to upload"
                />
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItemWithText
                  value="no"
                  id="no"
                  label="No, I don't have an office action"
                />
              </div>
            </RadioGroup>
          </>
        )}

        {hasOfficeAction === "yes" && (
          <>
            {getOfficeActionDocument() ? (
              <div>
                <UploadedFileDisplay
                  file={getOfficeActionDocument().file}
                  onRemove={handleRemoveFile}
                  fullWidth={true}
                />
                {isOfficeActionErrored && (
                  <Alert variant="destructive" className="mt-2">
                    <AlertDescription>
                      {
                        "Uploaded file is not a valid office action file. Please upload a new file."
                      }
                    </AlertDescription>
                  </Alert>
                )}
              </div>
            ) : (
              <div>
                <Button onClick={handleOpenUploadModal} disabled={isStepCompleted}>
                  {"Upload File"}
                </Button>
                <UploadFileModal
                  open={isUploadModalOpen}
                  handleClose={handleCloseUploadModal}
                  inContext={true}
                  handleContextAddClick={handleContextAddClick}
                />
              </div>
            )}
          </>
        )}
      </div>
    </CreateStepGroup>
  );
};

export default ApplicationOfficeActionStep;
