/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components";
import DocumentViewer from "@/components/patents/DocumentViewer";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useProcessReferences, useViz } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ParentType } from "@/types";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { RefreshCwIcon } from "lucide-react";
import React, { useState } from "react";

interface DocumentSummaryProps {
  documentId: string;
  summary: string;
  parentType: ParentType;
}

const DocumentSummary: React.FC<DocumentSummaryProps> = ({
  documentId,
  summary,
  parentType,
}) => {
  const { currentProject, currentPortfolio } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { updateDocumentSummary } = useViz();
  const [editedSummary, setEditedSummary] = useState("");
  const { generateSummaries } = useProcessReferences();

  const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
    string | null
  >(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const CITATION_REGEX =
    /(?:[A-Za-z]+\s+'\d{3}\s+at\s+[^.]+)|(?:\[[^\]]+\](?:,\s*\[[^\]]+\])*)/g;

  const handleEditClick = (summary: string) => {
    setIsEditing(true);
    setEditedSummary(summary);
  };

  const handleSaveSummary = async () => {
    if (!isEditing) return;

    const parentId =
      parentType === ParentType.PORTFOLIO ? currentPortfolio.id : currentProject.id;

    const response = await updateDocumentSummary(
      parentId,
      documentId,
      editedSummary,
      parentType === ParentType.PORTFOLIO,
    );
    if (response.success) {
      // Update local state based on parent type
      if (parentType === ParentType.PORTFOLIO) {
        const updatedSummaries = {
          ...currentPortfolio.summaries,
          [documentId]: editedSummary,
        };
        useProjectStore.setState((state) => ({
          currentPortfolio: {
            ...state.currentPortfolio,
            summaries: updatedSummaries,
          },
        }));
      } else {
        const updatedSummaries = {
          ...currentProject.summaries,
          [documentId]: editedSummary,
        };
        useProjectStore.setState((state) => ({
          currentProject: {
            ...state.currentProject,
            summaries: updatedSummaries,
          },
        }));
      }
    } else {
      addErrorMessage("Error saving summary. Try again later.");
    }

    setIsEditing(false);
    setEditedSummary("");
  };

  const handleCitationClick = async (e: React.MouseEvent, text: string) => {
    e.preventDefault();
    // First remove quotes if they exist
    const strippedText =
      text.startsWith('"') && text.endsWith('"')
        ? text.substring(1, text.length - 1)
        : text;

    setDocumentViewerCitationText(strippedText);
  };

  const handleCloseDocumentViewer = () => {
    setShowDocumentViewer(false);
    setDocumentViewerCitationText(null);
  };

  const formatSummaryText = (text: string, referenceId: string) => {
    const parts = [];
    let currentPosition = 0;

    // Find all quotes in the entire text
    const quoteMatches = Array.from(text.matchAll(/"([^"]+)"/g));
    const citationMatches = text.match(CITATION_REGEX);

    quoteMatches.forEach((quoteMatch, index) => {
      // Add text before the quote if any
      if (quoteMatch.index > currentPosition) {
        parts.push({
          type: "text",
          content: text.slice(currentPosition, quoteMatch.index).trim(),
        });
      }

      // Add the quote as a single unit, regardless of sentence count
      parts.push({
        type: "quote",
        content: quoteMatch[0],
        strippedContent: quoteMatch[1],
        citation: citationMatches?.[index] || null,
        index: index,
      });

      currentPosition = quoteMatch.index + quoteMatch[0].length;
    });

    // Add any remaining text
    if (currentPosition < text.length) {
      parts.push({
        type: "text",
        content: text.slice(currentPosition).trim(),
      });
    }

    return (
      <span>
        {parts.map((part, partIndex) => {
          if (part.type === "quote") {
            return (
              <span
                key={`quote-${partIndex}`}
                className="hover:bg-blue-100 cursor-pointer"
                data-quote-group={`${referenceId}-${partIndex}`}
                onClick={(e) => handleCitationClick(e, part.strippedContent)}
              >
                {part.content}{" "}
              </span>
            );
          }
          return <span key={`text-${partIndex}`}>{part.content} </span>;
        })}
      </span>
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSaveSummary();
    }
  };

  const handleReset = () => {
    setIsEditing(false);
    setEditedSummary("");
  };

  const handleTitleClick = () => {
    setDocumentViewerCitationText("");
    setShowDocumentViewer(true);
  };

  const handleRegenerateSummary = async () => {
    setIsRegenerating(true);
    const parentId =
      parentType === ParentType.PORTFOLIO ? currentPortfolio.id : currentProject.id;
    await generateSummaries([documentId], parentId, parentType, 1.0);
    setIsRegenerating(false);
  };

  return (
    <>
      <div key={documentId} className="px-4 py-3 border rounded-md">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Label
              className="mb-0 hover:cursor-pointer hover:text-blue-600"
              onClick={handleTitleClick}
            >
              {parentType === ParentType.PORTFOLIO
                ? currentPortfolio?.documentsToNicknames?.[documentId]
                : currentProject?.documentsToNicknames?.[documentId]}
            </Label>

            {isEditing ? (
              <>
                <Button variant="outline" onClick={handleReset} className="h-9">
                  Reset
                </Button>
                <Button className="h-9" onClick={handleSaveSummary}>
                  Save
                </Button>
              </>
            ) : (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleEditClick(summary)}
                disabled={isRegenerating}
              >
                <Pencil1Icon className="h-4 w-4" />
              </Button>
            )}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={handleRegenerateSummary}
                    disabled={isRegenerating || isEditing}
                  >
                    <RefreshCwIcon className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Regenerate summary</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        {isEditing ? (
          <AutosizeTextarea
            value={editedSummary}
            onChange={(e) => setEditedSummary(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-full mt-2"
          />
        ) : isRegenerating ? (
          <Loader message="Regenerating summary..." />
        ) : (
          <p className="text-sm">{formatSummaryText(summary, documentId)}</p>
        )}
      </div>
      <DocumentViewer
        open={documentViewerCitationText !== null || showDocumentViewer}
        handleClose={handleCloseDocumentViewer}
        referenceId={documentId}
        citationText={documentViewerCitationText || ""}
        startInChartMode={false}
      />
    </>
  );
};

export default DocumentSummary;
