/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { LoadingButton } from "@/components/ui/loading-button";
import { Switch } from "@/components/ui/switch";
import { ElementEditor } from "@/features/project/components";
import { useElementOperations, useProject, useVector } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ElementType } from "@/types";
import { useEffect, useState } from "react";

interface UpdateElementsModalProps {
  open: boolean;
  onClose: () => void;
  itemType: ElementType.FEATURE | ElementType.CLAIM | ElementType.EVALUATE;
}

/**
 * @description Update elements (features/claims) modal
 * @param {boolean} open - Whether the modal is open.
 * @param {function} onClose - Function to close the modal.
 * @param {ElementType} itemType - The type of item to update.
 */
function UpdateElementsModal({ open, onClose, itemType }: UpdateElementsModalProps) {
  const { updateElements } = useElementOperations();
  const { addSuccessMessage, addErrorMessage } = useAppStateStore();
  const { retrieveInvalidityForClaimForAllReferences } = useVector();
  const { getProjectClaims } = useProject();
  const { currentProjectId, currentProject, updateCurrentProject } = useProjectStore();

  const [isLoading, setIsLoading] = useState(false);
  const [elements, setElements] = useState<{ [key: string]: string }[]>([]);
  const [originalElements, setOriginalElements] = useState<{ [key: string]: string }[]>(
    [],
  );
  const [fetchInvalidities, setFetchInvalidities] = useState(false);
  const [autoRenumber, setAutoRenumber] = useState(true);
  const [startInEditMode, setStartInEditMode] = useState(false);
  const [assertedClaims, setAssertedClaims] = useState<string[]>(
    currentProject.assertedClaims,
  );
  const [isFetchingClaims, setIsFetchingClaims] = useState(false);

  const resetState = () => {
    setIsLoading(false);
    setFetchInvalidities(true);
    setAutoRenumber(true);
    setElements([]);
    setStartInEditMode(false);
    setAssertedClaims([]);
  };

  useEffect(() => {
    if (open) {
      if (itemType === ElementType.FEATURE) {
        setElements(currentProject.features);
        setOriginalElements(currentProject.features);
        setStartInEditMode(currentProject.features.length === 0);
      } else {
        const fetchClaims = async () => {
          setIsFetchingClaims(true);
          try {
            const claims = await getProjectClaims(currentProjectId, false); // get all claims
            setElements(claims.data);
            setOriginalElements(claims.data);
            setStartInEditMode(claims.data.length === 0);
          } catch (error) {
            addErrorMessage("Failed to fetch claims");
          } finally {
            setIsFetchingClaims(false);
          }
        };
        fetchClaims();
      }
      setAssertedClaims(currentProject.assertedClaims);
    }
  }, [open, itemType, currentProject]);

  const handleUpdateElements = async () => {
    // Add validation to check for empty strings
    const hasEmptyElements = elements.some(
      (elem) => Object.values(elem)[0].trim() === "",
    );

    if (hasEmptyElements) {
      addErrorMessage("Empty elements are not allowed");
      return;
    }

    setIsLoading(true);

    // Update elems in db
    const response = await updateElements(
      currentProjectId,
      elements,
      itemType === ElementType.FEATURE,
      assertedClaims,
    );

    // Fetch invalidities for new or modified claims if selected
    if (fetchInvalidities) {
      const originalTexts = new Set(
        originalElements.map((elem) => elem[Object.keys(elem)[0]].trim()),
      );
      const newOrModifiedClaims = elements.filter(
        (elem) => !originalTexts.has(elem[Object.keys(elem)[0]].trim()),
      );

      // TODO: add error handling here
      if (newOrModifiedClaims.length > 0) {
        const chartPromises = newOrModifiedClaims.map((elem, index) =>
          retrieveInvalidityForClaimForAllReferences(
            currentProjectId,
            Object.keys(elem)[0], // claim number
            elem[Object.keys(elem)[0]].trim(), // claim text
            false,
          ),
        );
        await Promise.all(chartPromises);
      }
    }

    if (response.success) {
      if (itemType === ElementType.FEATURE) {
        updateCurrentProject({
          id: currentProject.id,
          name: currentProject.name,
          features: elements,
        });
      } else {
        updateCurrentProject({
          ...currentProject,
          assertedClaims: assertedClaims,
          claims: elements,
        });
      }
      addSuccessMessage(
        `${
          itemType === ElementType.FEATURE ? "Features" : "Claims"
        } updated successfully`,
      );
    } else {
      addErrorMessage(
        `Failed to update ${itemType === ElementType.FEATURE ? "Features" : "Claims"}`,
      );
    }
    setIsLoading(false);
    handleClose();
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleMarkAllAsserted = () => {
    const allClaimNumbers = elements.map((elem) => Object.keys(elem)[0]);
    setAssertedClaims(allClaimNumbers);
  };

  const handleUnmarkAllAsserted = () => {
    setAssertedClaims([]);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose} modal>
      <DialogContent
        className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col overflow-hidden"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <DialogTitle>
                Edit {itemType === ElementType.FEATURE ? "features" : "claims"}
              </DialogTitle>
              <div className="flex items-center gap-2">
                {itemType === ElementType.CLAIM && (
                  <div className="flex items-center gap-2 border rounded-md p-2 h-11 px-3">
                    <Label className="text-sm font-normal mb-0">Asserted claims</Label>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleMarkAllAsserted}
                      disabled={isLoading}
                    >
                      Select all
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleUnmarkAllAsserted}
                      disabled={isLoading}
                    >
                      Unselect all
                    </Button>
                  </div>
                )}
                <div className="flex items-center gap-2  border rounded-md p-2 px-3 h-11">
                  <Label htmlFor="autoRenumber" className="text-sm font-normal mb-0">
                    Auto-label elements
                  </Label>
                  <Switch
                    checked={autoRenumber}
                    onCheckedChange={(checked) => setAutoRenumber(checked as boolean)}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogHeader>

        <div className="flex-grow overflow-auto min-h-0 ">
          <div className="border rounded-md">
            {isFetchingClaims ? (
              <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
              </div>
            ) : (
              <ElementEditor
                itemType={itemType}
                startInEditMode={startInEditMode}
                setItems={setElements}
                autoRenumber={autoRenumber}
                initialItems={elements}
                assertedClaims={assertedClaims}
                setAssertedClaims={setAssertedClaims}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between gap-2 mt-4">
          <CheckboxWithText
            label={`Find citations for new or modified ${
              itemType === ElementType.FEATURE ? "features" : "claims"
            } in charts (note this will overwrite existing citations)`}
            checked={fetchInvalidities}
            onCheckedChange={(checked) => setFetchInvalidities(checked as boolean)}
            disabled={isLoading}
          />
          <div className="flex gap-2">
            <Button
              variant="outline"
              onClick={handleClose}
              aria-label="Cancel"
              disabled={isLoading}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              onClick={handleUpdateElements}
              aria-label="Done"
            >
              {isLoading ? "Saving..." : "Save"}
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateElementsModal;
