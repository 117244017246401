/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DataTable } from "@/components/table/DataTable";
import { useOfficeAction } from "@/hooks";
import { useProjectStore } from "@/store";
import { ApplicationDocument, DocumentType } from "@/types/project";
import React, { useCallback, useState } from "react";
import {
  getCommonColumns,
  getOfficeActionColumns,
} from "./components/ApplicationDocumentsTableColumns";

interface ApplicationDocumentsProps {
  documents: ApplicationDocument[];
  height?: string;
  children?: React.ReactNode;
  onRowClick?: (documentId: string) => void;
  onDelete?: (officeActions: ApplicationDocument[]) => void;
  onDeleteSelected?: () => void;
}

/**
 * @description Table for displaying user uploaded files
 */
const ApplicationDocumentsTable: React.FC<ApplicationDocumentsProps> = ({
  documents,
  height,
  children,
  onRowClick,
}) => {
  // Project stores
  const { processOfficeActionClaimDetails, deleteOfficeAction } = useOfficeAction();
  const { currentProjectId } = useProjectStore();

  // Local States
  const [selectedRows, setSelectedRows] = useState<ApplicationDocument[]>([]);
  const [handleDeleteSelectedLoading, setHandleDeleteSelectedLoading] = useState(false);

  // Get columns based on document types present in the data
  const columns = [...getCommonColumns()];
  if (documents.some((doc) => doc.document_type === DocumentType.OFFICE_ACTION)) {
    columns.push(...getOfficeActionColumns());
  }

  // Table actions
  const handleDeleteSelected = async (selectedRows: ApplicationDocument[]) => {
    setHandleDeleteSelectedLoading(true);
    try {
      const selectedIds = selectedRows.map((doc) => doc.id);
      await deleteOfficeAction(selectedIds);
    } catch (error) {
      console.error("Error deleting office actions:", error);
    } finally {
      setHandleDeleteSelectedLoading(false);
    }
  };

  const handleRowClick = useCallback(
    (row: any) => {
      onRowClick?.(row.id);
    },
    [onRowClick],
  );

  const handleReprocessSelected = async (
    selectedRows: ApplicationDocument[],
    isReprocess: boolean,
  ) => {
    const selectedIds = selectedRows.map((doc) => doc.id);
    try {
      await processOfficeActionClaimDetails(currentProjectId, selectedIds);
      setSelectedRows([]);
    } catch (error) {
      console.error("Error processing office action claims:", error);
    }
  };

  // Handle row selection changes through DataTable's onRowSelection prop
  const handleRowSelection = (selectedRows: ApplicationDocument[]) => {
    // console.log("Selected rows:", selectedRows);
    // do nothing
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={documents}
        onRowClick={handleRowClick}
        height={height}
        showActions={true}
        enableRowDelete={true}
        onDeleteSelected={handleDeleteSelected}
        onReprocessSelected={handleReprocessSelected}
        isDeleteSelectedLoading={handleDeleteSelectedLoading}
        showTag={false}
        showReprocess={true}
        showColumns={true}
        onRowSelection={handleRowSelection}
      >
        {children}
      </DataTable>
    </>
  );
};

export default React.memo(ApplicationDocumentsTable);
