/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { InfoPopover } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Label } from "@/components/ui/label";
import { ChevronDownIcon, ChevronUpIcon, CircleIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";

/**
 * @description Create step group - wrapper for each step in create project flow
 * @param {string} stepName - The name of the step.
 * @param {React.ReactNode} children - The children of the step.
 * @param {boolean} isCompleted - Whether the step is completed.
 * @param {boolean} showSearchAgain - Whether to show the search again button.
 * @param {function} handleSearchAgain - Function to handle the search again button click.
 * @param {boolean} startExpanded - Whether the step is expanded.
 * @param {string} infoPopoverContent - The content of the info popover.
 */
const CreateStepGroup: React.FC<{
  stepName: string;
  children: React.ReactNode;
  isCompleted: boolean;
  isRequired?: boolean;
  showSearchAgain?: boolean;
  handleSearchAgain?: () => void;
  startExpanded?: boolean;
  infoPopoverContent?: string;
}> = ({
  stepName,
  children,
  isCompleted,
  isRequired,
  showSearchAgain,
  handleSearchAgain,
  startExpanded = true,
  infoPopoverContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(startExpanded);

  return (
    <Collapsible
      open={isExpanded}
      onOpenChange={setIsExpanded}
      className="border border-gray-200 rounded-lg p-3 mt-3"
    >
      <CollapsibleTrigger asChild>
        <div className="flex justify-between items-center cursor-pointer">
          <div className="flex items-center space-x-4">
            {isCompleted ? (
              <svg
                className="w-5 h-5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="10" fill="#22c55e" />
                <path
                  d="M8 12.5L11 15.5L16 9.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <CircleIcon className="text-red-500 w-5 h-5" />
            )}

            <div className="flex items-center space-x-1">
              <Label className="text-lg mb-0 mr-1">{stepName}</Label>
              {isRequired && <span className="text-red-500">*</span>}
              {infoPopoverContent && (
                <InfoPopover content={infoPopoverContent} className="ml-1" />
              )}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {showSearchAgain && (
              <Button variant="ghost" onClick={handleSearchAgain}>
                Reset
              </Button>
            )}
            {isExpanded ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="mt-4 px-9">{children}</CollapsibleContent>
    </Collapsible>
  );
};

export default CreateStepGroup;
