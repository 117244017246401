/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { PatentSourceNumberButton } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { Patent } from "@/types";
import { getUtcDateString } from "@/utils/dateUtils";
import React from "react";

interface PatentDetailsTableProps {
  details: Patent[];
  inUploadFilesModal?: boolean;
  handleProcessAsPDF?: (number: string) => void;
  height?: string;
}

/**
 * @description Document metadata table displayed in create project flow
 * @param {Patent[]} details - The list of patents to display.
 * @param {boolean} inUploadFilesModal - Whether the documents are being displayed in the upload files modal.
 * @param {function} handleProcessAsPDF - Function to process a patent as a PDF.
 */
const PatentDetailsTable: React.FC<PatentDetailsTableProps> = ({
  details,
  inUploadFilesModal,
  handleProcessAsPDF,
  height,
}) => {
  const formatInventorName = (name: string) => {
    if (Array.isArray(name)) {
      return name.map((n) => formatInventorName(n)).join(", ");
    }
    if (name && name.includes(",") && name === name.toUpperCase()) {
      const [lastName, firstName] = name.split(",").map((part) => part.trim());
      return `${firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()} ${
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase()
      }`;
    }
    return name;
  };

  const renderName = (name: string) => {
    if (inUploadFilesModal && name.length > 50) {
      return (
        <Tooltip>
          <TooltipTrigger style={{ textAlign: "left" }}>
            {name.substring(0, 50) + "..."}
          </TooltipTrigger>
          <TooltipContent>{name}</TooltipContent>
        </Tooltip>
      );
    }
    return name;
  };

  return (
    <div className={cn(height, "border border-border rounded-lg")}>
      <div
        className={cn(
          "overflow-y-auto overflow-x-auto",
          inUploadFilesModal ? "h-full" : "",
        )}
      >
        <div className="min-w-full inline-block align-middle">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="bg-background sticky top-0">Number</TableHead>
                <TableHead className="bg-background sticky top-0">Title</TableHead>
                <TableHead className="bg-background sticky top-0">
                  Publication
                </TableHead>
                <TableHead className="bg-background sticky top-0">Priority</TableHead>
                <TableHead className="bg-background sticky top-0">Inventors</TableHead>
                <TableHead className="bg-background sticky top-0">Assignee</TableHead>
                {inUploadFilesModal && (
                  <TableHead className="bg-background sticky top-0" />
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {details.map((detail) => (
                <TableRow key={detail.id}>
                  <TableCellCondensed className="whitespace-nowrap">
                    <PatentSourceNumberButton
                      sourceNumber={detail.number}
                      prefix={detail.prefix || ""}
                    />
                  </TableCellCondensed>
                  <TableCellCondensed>{renderName(detail.name)}</TableCellCondensed>
                  <TableCellCondensed className="whitespace-nowrap">
                    {getUtcDateString(detail.publicationDate)}
                  </TableCellCondensed>
                  <TableCellCondensed className="whitespace-nowrap">
                    {detail.priorityDate ? getUtcDateString(detail.priorityDate) : "—"}
                  </TableCellCondensed>
                  <TableCellCondensed>
                    {inUploadFilesModal
                      ? detail.inventors
                        ? formatInventorName(detail.inventors[0])
                        : "—"
                      : detail.inventors
                        ? formatInventorName(detail.inventors)
                        : "—"}
                  </TableCellCondensed>
                  <TableCellCondensed>{detail.assignee || "—"}</TableCellCondensed>
                  {inUploadFilesModal && (
                    <TableCellCondensed>
                      <Button
                        onClick={() => handleProcessAsPDF(detail.number)}
                        variant="ghost"
                      >
                        Process as PDF
                      </Button>
                    </TableCellCondensed>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PatentDetailsTable;
