/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { Label } from "@/components/ui/label";
import { CondensedParagraph } from "@/components/ui/typography";
import { cn } from "@/lib/utils";
import { useProjectStore } from "@/store";
import { DocumentBodySection, Patent, ProjectType } from "@/types";
import { BlockType } from "@/types/types";
import { normalizeTextToMatch } from "@/utils/normalizeTextToMatch";
import { parseHtml } from "@/utils/parseHtml";
import Fuse from "fuse.js";
import React, { useEffect, useRef } from "react";

interface DocumentBodyProps {
  document: Patent;
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement>;
  isStandard?: boolean;
}

const DocumentBody: React.FC<DocumentBodyProps> = ({
  document,
  citationText,
  highlightedRef,
}) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  if (!document?.body && !document?.fullBody) {
    return null;
  }

  const { currentPortfolio } = useProjectStore();
  const isStandard = currentPortfolio?.type === ProjectType.SEP;

  const getCitation = (bodyItem: any) => {
    if (document.isPatent && bodyItem?.lines && bodyItem?.columns) {
      return `Lines ${bodyItem.lines.join(",")} Columns ${bodyItem.columns.join(",")}`;
    } else if (bodyItem?.pages) {
      return `Pages ${bodyItem.pages.join(",")}`;
    } else if (bodyItem?.paraNum) {
      return bodyItem.paraNum;
    }

    if (bodyItem?.location?.paragraph) {
      return bodyItem.location.paragraph;
    }

    return "";
  };

  const isHighlighted = (plainText?: string, searchText?: string): boolean => {
    if (!plainText || !searchText) return false;

    // Split the plain text into sentences for more granular matching
    const sentences = plainText.split(/[.!?]+/).map((s) => s.trim());

    const normalizedSearchText = normalizeTextToMatch(searchText);

    // Check each sentence for a match
    for (const sentence of sentences) {
      const normalizedSentence = normalizeTextToMatch(sentence);
      if (normalizedSentence.includes(normalizedSearchText)) {
        return true;
      }
    }

    // If no direct match found, try fuzzy search on each sentence
    const fuse = new Fuse(sentences.map(normalizeTextToMatch), {
      includeScore: true,
      threshold: 0.2, // More strict threshold
      distance: 1000,
      minMatchCharLength: 10,
    });

    const result = fuse.search(normalizedSearchText);
    return result.length > 0 && (result[0].score || 1) < 0.3;
  };

  useEffect(() => {
    if (citationText && bodyRef.current) {
      // Initial delay to ensure DOM is ready
      setTimeout(() => {
        const highlightedSections = Array.from(
          bodyRef.current!.querySelectorAll(".bg-blue-100"),
        );

        if (highlightedSections.length > 0) {
          const firstHighlighted = highlightedSections[0] as HTMLElement;

          // First scroll attempt
          firstHighlighted.scrollIntoView({
            block: "center",
            behavior: "auto",
          });
        }
      }, 100);
    }
  }, [citationText]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const renderBody = () => {
    return document.body
      .map((section: DocumentBodySection | null, index: number) => {
        if (!section) {
          return null; // Skip rendering this section if it's null
        }

        const citation = getCitation(document.body[index]);
        const plainText = section.text;

        const isTextHighlighted = isHighlighted(plainText, citationText);

        const textWithCitation = `${plainText}\n\n${citation}`;

        const renderContent = () => {
          switch (section.type) {
            case BlockType.TITLE:
              return null;
            case BlockType.LIST:
              return <ul className="list-disc ml-6">{plainText}</ul>;
            case BlockType.LIST_ITEM:
              return <li>{plainText}</li>;

            case BlockType.TABLE:
              return (
                <div className="overflow-x-auto">
                  {section.html ? parseHtml(section.html) : null}
                </div>
              );
            case BlockType.HEADER:
              return <h4 className="text-lg font-semibold">{plainText}</h4>;
            case BlockType.SECTION_HEADER:
              return <h3 className="text-lg font-semibold">{plainText}</h3>;
            default:
              return (
                <div>
                  {isStandard && <Label>{section?.location?.paragraph}</Label>}
                  {section?.html ? (
                    <div className="overflow-x-auto">
                      {parseHtml(section.html, {}, citationText)}
                    </div>
                  ) : (
                    <div>
                      {citationText
                        ? section.text
                            .split(
                              new RegExp(
                                citationText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
                                "i",
                              ),
                            )
                            .map((part, i) =>
                              part.toLowerCase() === citationText?.toLowerCase() ? (
                                <span key={i} className="bg-blue-100">
                                  {part}
                                </span>
                              ) : (
                                part
                              ),
                            )
                        : section.text}
                    </div>
                  )}
                </div>
              );
          }
        };

        return (
          <ContextMenu key={index}>
            <ContextMenuTrigger>
              <div ref={isTextHighlighted ? highlightedRef : null}>
                <CondensedParagraph className={`w-full`}>
                  {renderContent()}
                </CondensedParagraph>
              </div>
            </ContextMenuTrigger>
            <ContextMenuContent>
              <ContextMenuItem onClick={() => copyToClipboard(plainText)}>
                Copy
              </ContextMenuItem>
              <ContextMenuItem onClick={() => copyToClipboard(textWithCitation)}>
                Copy with Citation
              </ContextMenuItem>
            </ContextMenuContent>
          </ContextMenu>
        );
      })
      .filter(Boolean) // Remove any null values returned from the map
      .reduce((acc, curr, index) => {
        return acc.concat(curr, <div key={`spacer-${index}`} className="h-4" />);
      }, [] as React.ReactNode[]);
  };

  return (
    <div className="mb-4" ref={bodyRef}>
      {!isStandard && <Label className="mb-2">Body</Label>}
      <div className={cn(isStandard && "px-8")}>
        {document.body?.length > 0 ? (
          renderBody()
        ) : document.fullBody?.length > 0 ? (
          <div className="space-y-4">
            {document.fullBody.map((section, index) => (
              <CondensedParagraph key={index} className="w-full">
                {section}
              </CondensedParagraph>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DocumentBody;
