/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DateTime } from "luxon";
import { Organization, User } from "./admin";

export const PATENT_TYPES = ["Application", "Issued Asset", "International"];
export const PATENT_STATUSES = ["Active", "Inactive"];
export const SUPPORTED_JURISDICTIONS = ["US", "EP", "KR", "JP", "DE"];

export interface Project {
  id: string;
  project_id?: string;
  name: string;
  subjectId?: string;
  referenceIds?: string[];
  references?: any[];
  documentIds?: string[];
  keywords?: string[];
  features?: any[];
  context?: PatentContext;
  fullBody?: any[];
  claims?: any[];
  inventors?: any[];
  primaryExaminer?: any[];
  assignee?: any[];
  publicationDate?: any[];
  filingDate?: any;
  priorityDate?: DateTime;
  notes?: any[];
  number?: string;
  type?: ProjectType;
  owner?: string;
  createdBy?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  clientNumber?: string;
  documentsToNumbers?: { [key: string]: string };
  documentsToNicknames?: { [key: string]: string };
  isPortfolio?: boolean;
  projects?: any[];
  introductionLanguage?: string;
  prefix?: string;
  body?: DocumentBodySection[];
  applicationDocuments?: ApplicationDocument[];
  summaries?: { [key: string]: string };
  indefiniteness?: any[];
  enablement?: any[];
  writtenDescription?: any[];
  assertedClaims?: string[];
  // headerFormat?: string;
  // footerFormat?: string;
  // defaultToAssertedClaims?: boolean;
  settings?: ProjectSettings;

  subject?: {
    name: string;
    id: string;
    number: string;
    nickname: string;
    abstract: string;
    publicationDate: string;
    filingDate: string;
    priorityDate?: string;
    inventors: string;
    assignee: string;
    primaryExaminer: string;
    prefix?: string;
  };
}

export interface CitationLocation {
  paragraph?: number | null;
  pages?: number[];
  lines?: number[];
  columns?: number[];
  claimSection?: string | null;
}

export interface PatentContext {
  expertKnowledge: string;
  termsAndDefinitions: Record<string, string>;
  importantFacts: string[];
  priorArtSearchInstructions: string;
  priorArtSearchFilterInstructions: string;
  relevantDocumentIds: string[];
}

export interface SearchResult {
  referenceId: string;

  text: string;
  color?: string;
  figureUrls?: string[];
  prefix?: string;
  location?: CitationLocation;
}

export interface Message {
  text?: string;
  sender: string;
  loading?: boolean;
}

export interface InvalidityCitation {
  id: string;
  text: string;
  paragraph?: number;
  columns?: number[];
  lines?: number[];
  pages?: number[];
  color?: string;
  figureUrls?: string[];
  score?: number;
  figureRefs?: string[];
  invalidityId?: string;
  organizationId?: string;
  removed: boolean;
  updatedById?: string;
  updatedAt: string;
  feedback?: "positive" | "negative";
  iprs?: string[];
  claimSection?: string;
}

export interface Element {
  color: string;
  label: string;
  language: string;
}

export interface Document {
  documentType: DocumentType;
  file: File;
  fileName?: string;
}

export interface Patent {
  id: string;
  name?: string;
  number?: string;
  applicationNumber?: string;
  earlyPublicationNumber?: string;
  nickname?: string;
  abstract?: string;
  claims?: any[];
  features?: any[];
  body?: DocumentBodySection[];
  fullBody?: any[];
  publicationDate?: string;
  filingDate?: string;
  priorityDate?: string;
  inventors?: string;
  assignee?: string;
  primaryExaminer?: string;
  note?: string;
  notes?: string;
  figureUrls?: string[];
  referenceId?: string;
  isPatent?: boolean;
  referenceNumber?: string;
  referenceName?: string;
  referenceNickname?: string;
  title?: string;
  cpcCodes?: string[];
  cpcMain?: string[];
  cpcAdditional?: string[];
  prefix?: string;
  subjects?: { projectId: string; projectName: string }[];
  ids?: { projectId: string; referenceId: string }[];
  url?: string;
  type?: DocumentType;
  tags?: string[];
  status?: DocumentStatus;
  projectId?: string;
  pdfUrl?: string;
  uploadedAt?: string;
}

export interface Reference {
  id: string;
  name: string;
  publicationDate: string;
  authors: string[];
  figureUrls: string[];
  uploadedAt?: string;
  projects?: {
    [projectId: string]: {
      id: string;
      name: string;
      type: string;
    };
  };
}

export interface OfficeAction {
  id: string;
  applicationNumber: string;
  publicationNumber: string;
  notificationDate: string;
  primaryExaminer: string;
  supervisoryExaminer: string;
  summary: any[];
  body: any[];
  claimDetails: any[];
  patentId: string;
}

export interface ClaimDetails {
  claimNumber: string;
  claimText: string;
  status: ClaimStatus;
  reasons: ClaimStatusReason[];
}

export interface ClaimStatusReason {
  type: string | null;
  examinerCommentary: string[];
  referenceCitations: ReferenceParagraphCitation[];
  arguments: string[] | null; // remove null possibility when saved in backend
  amendments: ClaimAmendments[] | null; // remove null possibility when saved in backend
}

export interface ClaimAmendments {
  text: string;
  reason: string;
}

export interface ReferenceParagraphCitation {
  referenceExtracted: string;
  referenceNickname: string;
  referenceNumber: string;
  paragraphNumber: string;
  paragraphId: string;
  paragraphText: string;
  cosineSimilarityScore: number;
  color: ChartColor;
}

export interface PatentInfo {
  abstract: string;
  claims: any[];
  description: string;
}

export interface Invalidity {
  id: string;
  documentId: string;
  claimNumber: string;
  isFeature: boolean;
  projectId: string;
  project: string;
  organizationId: string;
  organization: string;
  citations: InvalidityCitation[];
  header?: string;
  footer?: string;
}

export interface ChatSession {
  id: string;
  userId: string;
  user: User;
  messages: Message[];
  createdAt: DateTime;
  projectId: string;
  project: Project;
  organizationId: string;
  organization: Organization;
}

export interface ProjectUser {
  userId: string;
  user: User;
  projectId: string;
  project: Project;
}

export interface SummaryChartData {
  claimNumber: string;
  [key: string]: any;
}

export interface ApplicationDocument {
  id: string;
  document_type: DocumentType;
  document?: Patent | Reference | OfficeAction;
}

export enum ParentType {
  PROJECT = "PROJECT",
  PORTFOLIO = "PORTFOLIO",
}

export enum ProjectType {
  PFA = "PFA",
  PFC = "PFC",
  INV = "INV",
  SEP = "SEP",
  APP = "APP",
  SCH = "SCH",
}

export enum ProjectStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum ElementType {
  CLAIM = "claim",
  FEATURE = "feature",
  EVALUATE = "evaluate",
}

export enum ChartColor {
  GREEN = "#C1E1A6",
  YELLOW = "#FFDE7D",
  RED = "#F5A19B",
  GRAY = "#D3D3D3",
}

export enum ClaimStatusColor {
  RED = "#f14a4a",
  YELLOW = "#ffd561",
  BLUE = "#61bdff",
}

export enum DocumentType {
  PATENT = "PATENT",
  REFERENCE = "REFERENCE",
  OFFICE_ACTION = "OFFICE_ACTION",
}

export enum DocumentStatus {
  PROCESSED = "PROCESSED",
  REPROCESS = "REPROCESS",
  REUPLOAD = "REUPLOAD",
  RECHART = "RECHART",
  PROCESSING = "PROCESSING",
}

export interface DocumentBodySection {
  bbox: {
    top: number;
    left: number;
    page: number;
    width: number;
    height: number;
  };
  content: string;
  html: string;
  location: {
    pages: number[];
    lines: number[] | null;
    columns: number[] | null;
    section: number | null;
    paragraph: number | null;
    claim_section: number | null;
  };
  markdown: string;
  text: string;
  type: string;
  id: string;
  isCited: boolean;
}

export enum ClaimStatus {
  REJECTED = "rejected",
  OBJECTED = "objected",
  ALLOWED = "allowed",
  WITHDRAWN = "withdrawn",
}

export interface ProjectSettings {
  headerFormat?: string;
  footerFormat?: string;
  defaultToAssertedClaims?: boolean;
  citationColors?: string[];
  isCitationBefore?: boolean;
  usPatentCitationFormat?: string;
  nonUsPatentCitationFormat?: string;
  usApplicationCitationFormat?: string;
  uploadedDocumentCitationFormat?: string;
  wordIntroductionLanguage?: string;
  isFiguresEmbedded?: boolean;
  collapsedClaimDepth?: number;
}

export enum FigurePlacement {
  EMBEDDED = "EMBEDDED",
  GROUPED = "GROUPED",
  OMITTED = "OMITTED",
}
