import { useAppStateStore, useProcessStore, useProjectStore } from "@/store";
import {
  ApiResponse,
  DocumentStatus,
  ParentType,
  Patent,
  ProcessType,
  StatusType,
} from "@/types";
import { nanoid } from "nanoid";
import useApi from "./useApi";
import useFileUtils from "./useFileUtils";
import useProject from "./useProject";
import useViz from "./useViz";

const docStatusMapping = {
  PROCESSED: StatusType.SUCCESS,
  RECHART: StatusType.ERROR,
  RELOAD: StatusType.ERROR,
};

const useProcess = () => {
  const { postRequest, handleError } = useApi();
  const {
    currentProjectId,
    currentParent,
    currentPortfolioId,
    currentProject,
    currentPortfolio,
    updateCurrentProject,
    updateCurrentPortfolio,
  } = useProjectStore();
  const { updateLoadingGroupItem, addLoadingGroupItem, addLoadingGroup } =
    useAppStateStore();
  const { addProcess, removeProcess, processes } = useProcessStore();
  const { getProjectReferencesTableRows } = useProject();
  const { getPortfolioReferences } = useViz();
  const { getUploadedFileKeys } = useFileUtils();
  const { updateDocumentStatuses } = useViz();

  const processDocuments = async (
    portfolioId?: string,
    projectId?: string,
    publicationNumbers?: string[],
    uploadedFiles?: File[],
    chart: boolean = true,
    claimsInCharting: boolean = false,
  ): Promise<ApiResponse> => {
    //  Start process first
    const processId = nanoid();
    addProcess({
      id: processId,
      type: ProcessType.ADD_REFERENCE,
      projectId: projectId ?? null,
      portfolioId: portfolioId ?? null,
    });

    // Upload files before showing loading groups (tbd?)
    const uploadedFilesKeys = await getUploadedFileKeys(uploadedFiles);

    // Set up loading groups
    addLoadingGroup(
      processId,
      projectId ? currentProject.name : currentPortfolio.name,
      ProcessType.ADD_REFERENCE,
    );
    publicationNumbers.forEach((number) => {
      addLoadingGroupItem(
        processId,
        ProcessType.ADD_REFERENCE,
        number,
        StatusType.PROCESSING,
        "",
      );
    });
    Object.entries(uploadedFilesKeys).forEach(([filename, s3_key]) => {
      addLoadingGroupItem(
        processId,
        ProcessType.ADD_REFERENCE,
        s3_key,
        StatusType.PROCESSING,
        "",
        filename ?? null,
      );
    });

    // Try processing the documents
    try {
      const processResponse = await postRequest("process", {
        portfolio_id: portfolioId || null,
        project_ids: projectId ? [projectId] : null,
        publication_numbers: publicationNumbers,
        file_keys: uploadedFilesKeys,
        settings: {
          chart: chart,
          claims_in_charting: claimsInCharting,
        },
      });

      const documents = processResponse.data.documents;
      // console.log("documents", documents);

      // Update document statuses
      documents.forEach((document) => {
        updateLoadingGroupItem(
          processId,
          ProcessType.ADD_REFERENCE,
          document.document_key,
          docStatusMapping[document.status],
          "",
        );
      });

      // On processing response, fetch the new project IDs
      await refetchReferences();
      removeProcess(processId);

      return {
        success: true,
        data: processResponse.data,
        status: processResponse.status,
      };
    } catch (error) {
      removeProcess(processId);
      return handleError(
        error,
        `Failed to add references ${publicationNumbers} to the project`,
      );
    }
  };

  const reprocessDocuments = async (
    documents: Patent[] | string[],
    reparse: boolean = false,
  ): Promise<ApiResponse> => {
    // Check the type and extract IDs accordingly
    const documentIds =
      Array.isArray(documents) && documents.length > 0
        ? typeof documents[0] === "string"
          ? (documents as string[])
          : (documents as Patent[]).map((doc) => doc.id)
        : [];

    //  Start processing first
    const processType = reparse ? ProcessType.REPROCESS : ProcessType.RECHART;
    const processId = nanoid();

    addProcess({
      id: processId,
      type: processType,
      projectId: currentProjectId ?? null,
      portfolioId: currentPortfolioId ?? null,
    });

    // console.log("reprocessing?", reupload);
    // console.log("documents", documents);
    let documentsToNicknames;
    if (currentPortfolioId) {
      documentsToNicknames = currentPortfolio.references.reduce((acc, ref) => {
        acc[ref.id] = ref.name;
        return acc;
      }, {});
    } else {
      documentsToNicknames = currentProject.documentsToNicknames;
    }

    // Set up loading groups
    addLoadingGroup(
      processId,
      currentProjectId ? currentProject.name : currentPortfolio.name,
      processType,
    );
    documentIds.forEach((documentId) => {
      addLoadingGroupItem(
        processId,
        processType,
        documentId,
        StatusType.PROCESSING,
        "",
        documentsToNicknames[documentId],
      );
    });
    try {
      // Update references' temporary frontend state to processing
      if (currentProject && currentProject.references) {
        updateCurrentProject({
          id: currentProject.id,
          name: currentProject.name,
          references: currentProject.references.map((ref) => ({
            ...ref,
            status: documentIds.includes(ref.id)
              ? DocumentStatus.PROCESSING
              : ref.status,
          })),
        });
      }
      if (currentPortfolio && currentPortfolio.references) {
        updateCurrentPortfolio({
          ...currentPortfolio,
          references: currentPortfolio.references.map((ref) => ({
            ...ref,
            status: documentIds.includes(ref.id)
              ? DocumentStatus.PROCESSING
              : ref.status,
          })),
        });
      }

      const reprocessResponse = await postRequest("reprocess", {
        portfolio_id: currentPortfolioId || null,
        project_ids: currentProjectId ? [currentProjectId] : null,
        document_ids: documentIds,
        settings: {
          chart: true,
          reparse: reparse,
        },
      });

      const documents = reprocessResponse.data.documents;

      documents.forEach((document) => {
        updateLoadingGroupItem(
          processId,
          processType,
          document.id,
          docStatusMapping[document.status],
          "",
        );
      });
      await refetchReferences();
      removeProcess(processId);
      return {
        success: true,
        data: reprocessResponse.data,
        status: reprocessResponse.status,
      };
    } catch (error) {
      removeProcess(processId);
      return handleError(error, `Failed to add references to the project`);
    }
  };

  const refetchReferences = async () => {
    if (currentParent === ParentType.PORTFOLIO) {
      await getPortfolioReferences(currentPortfolioId);
    } else {
      await getProjectReferencesTableRows(currentProject.id, false);
    }
  };

  const processUserDocuments = async (): Promise<ApiResponse> => {
    return null;
  };

  return {
    processDocuments,
    reprocessDocuments,
    processUserDocuments,
  };
};

export default useProcess;
