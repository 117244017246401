/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ApiResponse, ElementType } from "@/types";
import { Project, ProjectSettings } from "@/types/project";
import { toCamelCase, toSnakeCase } from "@/utils/dataUtils";
import { filterDocumentSections } from "@/utils/projectUtils";

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProject = () => {
  const { postRequest, getRequest, handleError, postRequestFile } = useApi();
  const {
    updateCurrentProject,
    updateCurrentSubject,
    updateChartData,
    updateSummaryChartData,
    updateSelectedReferences,
    updateSelectedElements,
    updateSelectedColors,
    updateSelectedElementType,
    updateElementEditIndex,
    updateCurrentPortfolio,
    updateApplicationDocuments,
    updateCurrentProjectId,
    updateShowAssertedClaims,
    currentParent,
    currentProject,
    currentProjectId,
    currentPortfolioId,
    currentPortfolio,
  } = useProjectStore();
  const {
    updateIsLoading,
    updateIsReferencesLoading,
    addErrorMessage,
    addSuccessMessage,
  } = useAppStateStore();

  /**
   * @description Adds a user to a project
   * @param {string} projectId - The id of the project to add the user to
   * @param {string} userEmail - The email of the user to add to the project
   */
  const addUserToProject = async (
    projectId: string,
    userId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_add_user_to_project", {
        user_id: userId,
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, `Error adding users to the project`);
    }
  };

  /**
   * @description Removes a user from a project
   * @param {string} projectId - The id of the project to remove the user from
   * @param {string} userEmail - The email of the user to remove from the project
   */
  const removeUserFromProject = async (
    projectId: string,
    userId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_remove_user_from_project", {
        user_id: userId,
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, `Failed to remove users from the project`);
    }
  };

  /**
   * @description Fetches all users for a project
   * @param {string} projectId - The id of the project to fetch users for
   */
  const getUsersForProject = async (projectId: string): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_users_for_project", {
        project_id: projectId,
      });
      return {
        success: true,
        data: {
          users_on_project: response.data.users_on_project,
          shareable_users: response.data.shareable_users,
        },
      };
    } catch (error) {
      return handleError(error, "Error fetching users for project");
    }
  };

  /**
   * @description Checks if a user has access to a project
   * @param {string} projectId - The id of the project to check access for
   */
  const getUserProjectAccessCheck = async (
    projectId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_user_project_access_check", {
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching user project access check");
    }
  };

  /**
   * @description Fetches the owner of a project
   * @param {string} projectId - The id of the project to fetch the owner for
   */
  const getProjectOwnerData = async (projectId: string): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_project_owner_data", {
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching project owner data");
    }
  };

  /**
   * @description Requests access to a project
   * @param {string} projectId - The id of the project to request access to
   * @param {string} userEmail - The email of the user to request access to
   * @param {string} projectNickname - The nickname of the project
   */
  const requestProjectAccess = async (
    projectId: string,
    userEmail: string,
    projectNickname: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_request_project_access_email", {
        project_nickname: projectNickname,
        owner_email: userEmail,
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error requesting access to project");
    }
  };

  /**
   * @description Fetches the project metadata
   * @param {string} projectId - The id of the project to fetch the metadata for
   */
  const getProjectMetadata = async (
    projectId: string,
    projectName: string = "",
    includeReferences: boolean = false,
  ): Promise<ApiResponse> => {
    try {
      updateIsLoading(projectId);

      updateCurrentProjectId(projectId);
      updateCurrentProject({
        id: projectId,
        name: projectName,
      });

      const payload: any = {
        project_id: projectId,
      };

      const response = await getRequest("get_project_metadata", payload);
      const projectMetadata = response.data;

      let projectUpdate: Project = { id: projectId, name: projectName };

      if (includeReferences) {
        const referenceResponse = await getRequest("get_project_reference_rows", {
          project_id: projectId,
        });
        const referenceIds = referenceResponse.data.documents.map(
          (reference: any) => reference.id,
        );

        projectUpdate.references = toCamelCase(referenceResponse.data.documents, true);
        projectUpdate.referenceIds = referenceIds;
        projectUpdate.documentIds = [projectMetadata.subject?.id, ...referenceIds];
        projectUpdate.documentsToNumbers =
          {
            ...currentProject?.documentsToNumbers,
            ...referenceResponse.data.documents_to_numbers,
          } || {};
      }
      // console.log(projectMetadata);

      // Update project details in the store
      projectUpdate = {
        ...projectUpdate,
        id: projectMetadata.id,
        name: projectMetadata.name,
        subjectId: projectMetadata.subject?.id,
        priorityDate: projectMetadata.priority_date,
        keywords: projectMetadata.keywords,
        type: projectMetadata.type,
        owner: projectMetadata.created_by,
        documentsToNicknames: {
          ...currentProject?.documentsToNicknames,
          ...projectMetadata.documents_to_nicknames,
        },
        features: projectMetadata.features,
        claims: projectMetadata.claims,
        summaries: projectMetadata.summaries,
        enablement: projectMetadata.enablement,
        indefiniteness: projectMetadata.indefiniteness,
        writtenDescription: projectMetadata.written_description,
        assertedClaims: projectMetadata.asserted_claims,
        settings: {
          defaultToAssertedClaims: projectMetadata.default_to_asserted_claims,
          headerFormat: projectMetadata.header_format,
          footerFormat: projectMetadata.footer_format,
        },
        subject: null,
      };

      if (projectMetadata.subject) {
        const subjectDetails = toCamelCase(projectMetadata.subject);
        const strippedSubjectBody = filterDocumentSections(subjectDetails.body);

        const subjectUpdate = {
          ...subjectDetails,
          body: strippedSubjectBody,
        };
        updateCurrentSubject(subjectUpdate);

        projectUpdate.subject = subjectUpdate;
      }

      updateCurrentProject({
        ...currentProject,
        ...projectUpdate,
      });

      updateSelectedColors([]);
      updateSelectedElementType(ElementType.CLAIM);
      updateSelectedReferences([]);
      updateElementEditIndex(-1);
      updateSummaryChartData([]);
      updateChartData([]);
      if (
        projectMetadata.asserted_claims &&
        projectMetadata.asserted_claims.length > 0
      ) {
        updateShowAssertedClaims(true);
        updateSelectedElements(projectMetadata.asserted_claims);
      } else {
        updateShowAssertedClaims(false);
        updateSelectedElements([]);
      }

      return { success: true, data: projectMetadata };
    } catch (error) {
      console.error("Error in getProjectMetadata:", error);
      return handleError(error, "Error fetching project metadata");
    } finally {
      updateIsLoading(null);
    }
  };

  const getProjectReferencesTableRows = async (
    projectId: string,
    showLoading: boolean = true,
  ): Promise<ApiResponse> => {
    try {
      if (showLoading) {
        updateIsReferencesLoading(true);
      }

      const response = await getRequest("get_project_reference_rows", {
        project_id: projectId,
      });

      const projectReferences = response.data;
      const referenceIds = projectReferences.documents.map(
        (reference: any) => reference.id,
      );

      const newReferenceData = {
        referenceIds,
        references: toCamelCase(projectReferences.documents, true),
        documentIds: [currentProject.subject?.id, ...referenceIds],
        documentsToNumbers: projectReferences.documents_to_numbers || {},
        documentsToNicknames: projectReferences.documents_to_nicknames || {},
      };

      updateCurrentProject({
        ...currentProject,
        ...newReferenceData,
      });

      return { success: true, data: projectReferences };
    } catch (error) {
      return handleError(error, "Error fetching project references");
    } finally {
      if (showLoading) {
        updateIsReferencesLoading(false);
      }
    }
  };

  const deleteProject = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_project", {
        project_id: projectId,
      });
      addSuccessMessage("Project deleted successfully");
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      addErrorMessage("Error deleting project");
      return handleError(error, "Error deleting project");
    }
  };

  /**
   * @description Updates documents to added documents for billing
   * @param {string} id - The id of the document to update the details for
   * @param {array} referenceIds - The ids of the references to update the details for
   * @param {boolean} is_portfolio - Whether the documents are in a portfolio
   */
  const addToDocumentsAdded = async (
    id: string,
    referenceIds: string[],
    is_portfolio: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_to_documents_added", {
        id: id,
        reference_ids: referenceIds.filter((id) => id && id.trim() !== ""),
        is_portfolio: is_portfolio,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error adding to documents added");
    }
  };

  /**
   * @description Updates the project details
   * @param {string} project_id - The id of the project to update the details for
   * @param {object} options - Additional options to pass to the request
   */
  const updateProjectDetails = async (
    project_id: string,
    options: any,
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      project_id: project_id,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest("post_update_project_details", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error updating project details");
    }
  };

  /**
   * @description Updates the project settings
   * @param {string} project_id - The id of the project to update the settings for
   * @param {object} settings - The settings to update
   */
  const updateProjectSettings = async (
    project_id: string,
    portfolio_id: string,
    settings: ProjectSettings,
  ): Promise<ApiResponse> => {
    try {
      const payload = {
        ...toSnakeCase(settings),
      };
      if (portfolio_id) {
        payload.portfolio_id = portfolio_id;
      }
      if (project_id) {
        payload.project_id = project_id;
      }

      const response = await postRequest("post_update_project_settings", payload);
      updateCurrentProject({
        ...currentProject,
        settings: {
          ...currentProject?.settings,
          ...settings,
        },
      });
      if (currentPortfolioId) {
        updateCurrentPortfolio({
          ...currentPortfolio,
          settings: {
            ...currentPortfolio?.settings,
            ...settings,
          },
        });
      }
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error updating project settings");
    }
  };

  /**
   * @description Gets the project settings
   * @param {string} project_id - The id of the project to get the settings for
   */
  const getProjectSettings = async (project_id: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_project_settings", { project_id });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting project settings");
    }
  };

  const getProjectClaims = async (
    project_id: string,
    collapsed: boolean = false,
    updateStore: boolean = false,
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_project_claims", {
        project_id,
        collapsed,
      });
      if (updateStore) {
        updateCurrentProject({
          ...currentProject,
          claims: response.data,
        });
      }
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting project claims");
    }
  };

  return {
    addUserToProject,
    removeUserFromProject,
    getUsersForProject,
    getUserProjectAccessCheck,
    getProjectOwnerData,
    requestProjectAccess,
    getProjectMetadata,
    deleteProject,
    addToDocumentsAdded,
    updateProjectDetails,
    getProjectReferencesTableRows,
    getProjectSettings,
    updateProjectSettings,
    getProjectClaims,
  };
};

export default useProject;
