/*
 * Copyright AndAI, Inc. 2024. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */
import { Loader } from "@/components";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useReferences } from "@/hooks";
import { cn } from "@/lib/utils";
import { useAppStateStore, useProjectStore } from "@/store";
import { ChartColor, SummaryChartData } from "@/types";
import { splitId } from "@/utils/projectUtils";
import React, { useCallback, useEffect, useMemo, useState } from "react";

interface SummaryTableProps {
  handleSelect: (claimNumber: string | null, key: string) => void;
  isExpanded: boolean;
  hideEmptyRows: boolean;
  hideNonAsserted: boolean;
}

interface TableHeaderProps {
  isExpanded: boolean;
  summaryChartHeaders: string[];
  selectedReferences: any[];
  documentsToNicknames: Record<string, string>;
  documentsToNumbers: Record<string, string>;
  handleSelect: (claimNumber: string | null, key: string) => void;
  handleRemoveReferenceFromProject: (referenceId: string) => void;
}

interface TableRowProps {
  row: SummaryChartData;
  originalIndex: number;
  isExpanded: boolean;
  summaryChartHeaders: string[];
  summaryChartRowNames: string[];
  summaryChartRowHeightCollapsed: number;
  heightMultiplier: number;
  handleSelect: (claimNumber: string | null, key: string) => void;
}

const SummaryTableHeader = React.memo(
  ({
    isExpanded,
    summaryChartHeaders,
    selectedReferences,
    documentsToNicknames,
    documentsToNumbers,
    handleSelect,
    handleRemoveReferenceFromProject,
  }: TableHeaderProps) => {
    return (
      <>
        {isExpanded && (
          <div className="sticky bg-background left-0 top-0 z-50 w-[75px] pt-2" />
        )}
        {summaryChartHeaders.map((key, idx) => (
          <div
            key={idx}
            className="sticky top-0 z-10 bg-background"
            style={{ minWidth: "75px", flex: "1", padding: "0 2px" }}
          >
            <div className="flex flex-col items-center">
              <ContextMenu>
                <ContextMenuTrigger className="w-full">
                  <Tooltip>
                    <TooltipTrigger className="w-full focus:outline-none focus:ring-0">
                      <div
                        className={cn(
                          "w-full overflow-hidden bg-background",
                          isExpanded ? "m-1" : "m-0.75",
                          "cursor-pointer rounded-md mb-2 px-2 py-1 font-medium flex items-center justify-between",
                          selectedReferences?.some((detail) => detail.id === key) &&
                            "border-blue-500 border-2",
                        )}
                        onClick={() => handleSelect(null, key)}
                      >
                        <span className="text-xs truncate mr-2 flex-grow">
                          {documentsToNicknames?.[key] ||
                            (documentsToNumbers?.[key] &&
                              splitId(documentsToNumbers[key])) ||
                            ""}
                        </span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        {documentsToNicknames?.[key] ??
                          splitId(documentsToNumbers?.[key] ?? "")}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </ContextMenuTrigger>
                <ContextMenuContent>
                  <ContextMenuItem
                    className="text-destructive focus:text-destructive cursor-pointer"
                    onClick={() => handleRemoveReferenceFromProject(key)}
                  >
                    Remove {documentsToNicknames?.[key]} from Project
                  </ContextMenuItem>
                </ContextMenuContent>
              </ContextMenu>
            </div>
          </div>
        ))}
      </>
    );
  },
);

const SummaryTableRow = React.memo(
  ({
    row,
    originalIndex,
    isExpanded,
    summaryChartHeaders,
    summaryChartRowNames,
    summaryChartRowHeightCollapsed,
    heightMultiplier,
    handleSelect,
  }: TableRowProps) => {
    return (
      <div className={`contents m-1 ${isExpanded ? "mb-2" : ""}`}>
        {isExpanded && (
          <div className="sticky left-0 z-50 w-[75px] bg-background align-right truncate">
            {summaryChartRowNames[originalIndex]?.match(/^[\d.]+(?=\s|$)/)?.[0] ||
              summaryChartRowNames[originalIndex]}
          </div>
        )}
        {summaryChartHeaders.map((key, colIndex) => (
          <div
            key={`${originalIndex}-${colIndex}`}
            className="px-0.75 bg-background"
            style={{
              minWidth: "75px",
              flex: "1",
              margin: "0",
              padding: "0 2px",
            }}
          >
            <div
              className={`w-full h-full flex items-center justify-center cursor-pointer ${
                isExpanded ? "rounded m-1" : ""
              } transition-colors duration-200`}
              style={{
                backgroundColor: row[key] || "lightgray",
                height: isExpanded
                  ? "25px"
                  : `${summaryChartRowHeightCollapsed * heightMultiplier}px`,
              }}
              onClick={() => handleSelect(isExpanded ? row.claim_number : null, key)}
            />
          </div>
        ))}
      </div>
    );
  },
);

/**
 * @description Summary table
 * @param {function} handleSelect - Function to handle the selection of a claim.
 * @param {boolean} isExpanded - Whether the table is expanded.
 */
const SummaryTable: React.FC<SummaryTableProps> = ({
  handleSelect,
  isExpanded,
  hideEmptyRows,
  hideNonAsserted,
}) => {
  const {
    selectedReferences,
    summaryChartData,
    summaryChartHeaders,
    summaryChartRowNames,
    updateSummaryChartRowNames,
    summaryChartRowHeightCollapsed,
    currentProjectId,
    currentPortfolioId,
    currentProject,
  } = useProjectStore();

  const documentsToNumbers = useProjectStore(
    (state) => state.currentProject.documentsToNumbers,
  );
  const documentsToNicknames = useProjectStore(
    (state) => state.currentProject.documentsToNicknames,
  );
  const { deleteReferences } = useReferences();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();
  const [isLoading, setIsLoading] = useState(true);

  const handleRemoveReferenceFromProject = useCallback(
    async (referenceId: string) => {
      if (referenceId && currentProjectId) {
        const response = await deleteReferences(
          currentProjectId,
          currentPortfolioId,
          [referenceId],
          false,
        );
        if (!response.success) {
          addErrorMessage(
            `Error deleting prior art ${documentsToNicknames[referenceId]}`,
          );
        } else {
          addSuccessMessage(
            `${documentsToNicknames[referenceId]} removed from ${currentProject.name}`,
          );
        }
      }
    },
    [
      currentProjectId,
      documentsToNicknames,
      currentProject,
      deleteReferences,
      addErrorMessage,
      addSuccessMessage,
    ],
  );

  const claimNumberToIndexMap = useMemo(() => {
    const map = new Map<string, number>();
    summaryChartData.forEach((row, index) => {
      map.set(row.claim_number, index);
    });
    return map;
  }, [summaryChartData]);

  useEffect(() => {
    if (
      summaryChartData &&
      summaryChartHeaders &&
      summaryChartData.length > 0 &&
      summaryChartHeaders.length > 0
    ) {
      setIsLoading(false);
      updateSummaryChartRowNames(
        summaryChartData.map((row: SummaryChartData) => row.claim_number),
      );
    }
  }, [summaryChartData, summaryChartHeaders, updateSummaryChartRowNames]);

  const filteredHeaders = useMemo(
    () => summaryChartHeaders.filter((key) => documentsToNicknames?.[key]),
    [summaryChartHeaders, documentsToNicknames],
  );

  const memoizedSummaryChartData = useMemo(() => {
    let initialData = summaryChartData;

    if (hideNonAsserted && currentProject?.assertedClaims) {
      initialData = summaryChartData.filter((row) =>
        currentProject.assertedClaims.includes(row.claim_number),
      );
    }

    const filteredData = hideEmptyRows
      ? initialData.filter((row) => {
          return filteredHeaders.some(
            (key) => key !== "claim_number" && row[key] !== ChartColor.GRAY,
          );
        })
      : initialData;

    const filteredWithIndices = filteredData.map((row) => ({
      row,
      originalIndex: claimNumberToIndexMap.get(row.claim_number) ?? -1,
    }));

    const heightMultiplier = summaryChartData.length / filteredWithIndices.length;

    return filteredWithIndices.map(({ row, originalIndex }) => (
      <SummaryTableRow
        key={originalIndex}
        row={row}
        originalIndex={originalIndex}
        isExpanded={isExpanded}
        summaryChartHeaders={filteredHeaders}
        summaryChartRowNames={summaryChartRowNames}
        summaryChartRowHeightCollapsed={summaryChartRowHeightCollapsed}
        heightMultiplier={heightMultiplier}
        handleSelect={handleSelect}
      />
    ));
  }, [
    summaryChartData,
    isExpanded,
    summaryChartRowHeightCollapsed,
    summaryChartRowNames,
    filteredHeaders,
    handleSelect,
    hideEmptyRows,
    hideNonAsserted,
    currentProject,
    claimNumberToIndexMap,
  ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col relative">
      <div
        className={cn(
          "overflow-x-auto w-[calc(100vw-130px)]",
          isExpanded ? "h-[50vh] overflow-y-auto" : "h-[220px] overflow-y-hidden",
        )}
      >
        <div>
          <div
            className="grid gap-x-0 bg-background w-full"
            style={{
              gridTemplateColumns: isExpanded
                ? `75px repeat(${filteredHeaders.length}, minmax(75px, 1fr))`
                : `repeat(${filteredHeaders.length}, minmax(75px, 1fr))`,
              columnGap: "4px",
            }}
          >
            <SummaryTableHeader
              isExpanded={isExpanded}
              summaryChartHeaders={filteredHeaders}
              selectedReferences={selectedReferences}
              documentsToNicknames={documentsToNicknames}
              documentsToNumbers={documentsToNumbers}
              handleSelect={handleSelect}
              handleRemoveReferenceFromProject={handleRemoveReferenceFromProject}
            />
            {memoizedSummaryChartData}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryTable;
