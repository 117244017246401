/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage, UploadFilesModal } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { H4 } from "@/components/ui/typography";
import {
  DismissDocumentStatusModal,
  UpdateElementsModal,
} from "@/features/project/components";
import { ProcessReferencesModal } from "@/features/project/components/ProcessReferencesModal";
import { useProject, useReferences, useViz } from "@/hooks";
import useProcess from "@/hooks/useProcess";
import { useAppStateStore, useProcessStore, useProjectStore } from "@/store";
import {
  conflictingReferenceProcesses,
  DocumentStatus,
  ElementType,
  Patent,
  ProjectType,
} from "@/types";
import { PlusIcon } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AddReferenceByNumberModal,
  DocumentsTable,
  PatchReferencesModal,
  PruneReferencesModal,
  ReferencesTableActions,
  SearchReferencesModal,
} from "./components";

/**
 * @description Reference details page for invalidity project
 */
const ReferencesPage: React.FC = () => {
  const { updateProjectDetails, getProjectReferencesTableRows } = useProject();
  const { deleteReferences } = useReferences();
  const {
    updateProjectReferenceNickname,
    updateProjectReferenceNote,
    updateDocumentStatuses,
  } = useViz();
  const { reprocessDocuments } = useProcess();

  // Global state from store
  const references = useProjectStore((state) => state.currentProject.references);
  const {
    updateCurrentProject,
    currentProjectId,
    currentProject,
    currentPortfolioId,
    currentPortfolio,
  } = useProjectStore();
  const { areProcessesPending } = useProcessStore();
  const {
    addErrorMessage,
    addSuccessMessage,
    addLoadingMessage,
    removeLoadingMessage,
    isReferencesLoading,
    updateIsReferencesLoading,
    isLoading,
  } = useAppStateStore();

  // Block adding references if recharting or downloading chart is in progress
  const isReferenceDisabled =
    areProcessesPending({
      types: conflictingReferenceProcesses,
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: conflictingReferenceProcesses,
      portfolioId: currentPortfolioId,
    });

  // Set page name
  const pageName = currentProject.name
    ? `${currentProject.name} - References`
    : "References";

  // Local state setup
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddClaimsModal, setShowAddClaimsModal] = useState(false);
  const [showEditClaimsModal, setShowEditClaimsModal] = useState(false);
  const [showProcessReferencesModal, setShowProcessReferencesModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showPatchReferencesModal, setShowPatchReferencesModal] = useState(false);
  const [showPruneModal, setShowPruneModal] = useState(false);
  const [showDismissStatusesModal, setShowDismissStatusesModal] = useState(false);
  const [selectedReferencesToDismiss, setSelectedReferencesToDismiss] = useState<
    Patent[]
  >([]);
  // const [isReferenceLoading, setIsReferenceLoading] = useState<string[]>([]);
  const [selectedReferences, setSelectedReferences] = useState<Patent[]>([]);
  const [selectedReferencesToReprocess, setSelectedReferencesToReprocess] = useState<
    Patent[]
  >([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDeleteSelectedLoading, setIsDeleteSelectedLoading] = useState(false);
  const [isReprocess, setIsReprocess] = useState<boolean>(false);

  // Fetch references if none and not on refresh
  useEffect(() => {
    const loadData = async () => {
      await getProjectReferencesTableRows(currentProjectId);
    };
    if (!isLoading && currentProjectId && currentProject?.references) {
      updateIsReferencesLoading(true);
      loadData();
      updateIsReferencesLoading(false);
    }
  }, [currentProjectId]);

  // Handle deletions
  const handleDeleteSelected = useCallback(async () => {
    setIsDeleteSelectedLoading(true);
    const referenceIds = selectedReferences.map((reference) => reference.id);
    try {
      await deleteReferences(
        currentProjectId,
        null,
        referenceIds,
        currentPortfolioId ? true : false,
      );
    } catch (error) {
      addErrorMessage(
        "An unexpected error occurred while deleting references. Please try again.",
      );
    } finally {
      setIsDeleteSelectedLoading(false);
    }
  }, [currentProjectId, selectedReferences, deleteReferences]);

  const handleRemoveReferenceFromProject = useCallback(
    async (reference: Patent) => {
      const refId = reference.id || reference.referenceId;
      if (refId && currentProjectId) {
        const response = await deleteReferences(
          currentProjectId,
          null,
          [refId],
          currentPortfolioId ? true : false,
        );
        if (!response.success) {
          addErrorMessage(`Error deleting prior art ${reference.number}`);
        }
      }
    },
    [currentProjectId, references],
  );

  const handleSearchReferencesClick = useCallback(() => {
    if (currentProject?.claims?.length > 0) {
      setShowSearchModal(true);
    } else {
      setShowAddClaimsModal(true);
    }
  }, [currentProject?.claims?.length]);

  const handlePatchReferencesClick = useCallback(() => {
    setShowPatchReferencesModal((prev) => !prev);
  }, []);

  const handlePruneReferencesClick = useCallback(() => {
    setShowPruneModal((prev) => !prev);
  }, []);

  const handleAddPublishedPatentsClick = useCallback(() => {
    setShowAddModal((prev) => !prev);
  }, []);

  const handleAddOtherDocumentsClick = useCallback(() => {
    setShowUploadModal((prev) => !prev);
  }, []);

  const handleDismissReferenceStatuses = useCallback(async () => {
    const selectedIds = selectedReferencesToDismiss.map((reference) => reference.id);
    await updateDocumentStatuses(selectedIds, DocumentStatus.PROCESSED);
    setSelectedReferencesToReprocess([]);
    setSelectedReferences([]);
    setShowDismissStatusesModal(false);
  }, [selectedReferencesToDismiss, updateDocumentStatuses]);

  const handleReprocessReferences = useCallback(async () => {
    setShowProcessReferencesModal(false);
    await reprocessDocuments(selectedReferencesToReprocess, isReprocess);
    setSelectedReferencesToReprocess([]);
    setSelectedReferences([]);
  }, [selectedReferencesToReprocess, reprocessDocuments, isReprocess]);

  const handleTagUpdate = useCallback(
    async (selectedRows: Patent[], selectedTags: string[]) => {
      if (selectedTags) {
        const referenceIds = selectedRows.map((row) => row.id);
        const updatedReferences = [...currentProject.references];

        referenceIds.forEach((id) => {
          updatedReferences.find((ref) => ref.id === id).tags = selectedTags;
        });

        updateCurrentProject({
          id: currentProjectId,
          name: currentProject.name,
          references: updatedReferences,
        });

        const newTags: { [key: string]: string[] } = {};

        selectedRows.forEach((row) => {
          newTags[row.id] = selectedTags;
        });

        const response = await updateProjectDetails(currentProjectId, {
          new_tags: newTags,
        });

        if (!response.success) {
          addErrorMessage(
            response.message || "An error occurred while saving the tags.",
          );
        }
      }
    },
    [currentProjectId],
  );

  const handleSaveName = useCallback(
    async (reference: Patent, newName: string) => {
      const response = await updateProjectReferenceNickname(
        currentProjectId,
        reference.id,
        newName,
      );
      if (!response.success) {
        addErrorMessage(response.message || "An error occurred while saving the name.");
      }
    },
    [currentProjectId],
  );

  const handleSaveNote = useCallback(
    async (reference: Patent, newNote: string) => {
      const response = await updateProjectReferenceNote(
        currentProjectId,
        reference.id,
        newNote,
      );
      if (!response.success) {
        addErrorMessage(response.message || "An error occurred while saving the note.");
      }
    },
    [currentProjectId],
  );

  const handleReprocessClick = useCallback(
    (selectedRows: Patent[], isReprocess: boolean = true) => {
      setSelectedReferencesToReprocess(selectedRows);
      setIsReprocess(isReprocess);
      setShowProcessReferencesModal(true);
    },
    [],
  );

  const handleDismissSelected = () => {
    setSelectedReferencesToDismiss(
      selectedReferences.filter(
        (ref) =>
          ref.status === DocumentStatus.RECHART ||
          ref.status === DocumentStatus.REPROCESS,
      ),
    );
    setShowDismissStatusesModal(true);
  };

  const handleRowSelection = useCallback((selectedRows: Patent[]) => {
    setSelectedReferences(selectedRows);
  }, []);

  const useDocumentNames = (
    references: Patent[],
    documentsToNicknames: Record<string, string>,
  ) => {
    return useMemo(
      () =>
        references?.map(
          (reference) =>
            reference.name || documentsToNicknames[reference.id] || reference.title,
        ),
      [references, documentsToNicknames],
    );
  };

  const documentNames = useDocumentNames(
    selectedReferencesToReprocess,
    currentProject.documentsToNicknames,
  );

  const isStandardsPortfolio = currentPortfolio?.type === ProjectType.SEP;

  return (
    <ProjectPage pageName={pageName}>
      {isLoading || isReferencesLoading ? (
        <div className="flex justify-center items-center flex-col mt-12">
          <Loader message="Loading..." />
        </div>
      ) : references?.length > 0 ? (
        <DocumentsTable
          references={references}
          onDeleteRow={handleRemoveReferenceFromProject}
          height="calc(100vh - 100px)"
          onRowSelection={handleRowSelection}
          onTagUpdate={handleTagUpdate}
          onDeleteSelected={handleDeleteSelected}
          onSaveName={handleSaveName}
          onSaveNote={handleSaveNote}
          onReprocessSelected={handleReprocessClick}
          onDismissSelected={handleDismissSelected}
          enableRowDelete={true}
          isSearch={false}
          isReference={true}
          isDeleteSelectedLoading={isDeleteSelectedLoading}
        >
          {!isStandardsPortfolio && (
            <ReferencesTableActions
              isStandardsPortfolio={isStandardsPortfolio}
              isReferenceDisabled={isReferenceDisabled}
              references={references}
              onPatch={handlePatchReferencesClick}
              onPrune={handlePruneReferencesClick}
              onSearch={handleSearchReferencesClick}
            />
          )}
          <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                className="h-9"
                disabled={isReferenceDisabled}
                onClick={() => setDropdownOpen(true)}
              >
                <span className="hidden lg:inline">Add References</span>
                <PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  handleAddPublishedPatentsClick();
                  setDropdownOpen(false);
                }}
              >
                Add published patents/applications
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  handleAddOtherDocumentsClick();
                  setDropdownOpen(false);
                }}
              >
                Upload other documents
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </DocumentsTable>
      ) : (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>This project has no references.</H4>
          <div className="flex gap-2 mt-3">
            <Button
              variant="outline"
              onClick={handleSearchReferencesClick}
              disabled={isReferenceDisabled}
              className="h-9"
            >
              Search
            </Button>
            <Button
              variant="outline"
              onClick={handleAddPublishedPatentsClick}
              disabled={isReferenceDisabled}
              className="h-9"
            >
              Add by Number
            </Button>
            <Button
              variant="outline"
              onClick={handleAddOtherDocumentsClick}
              disabled={isReferenceDisabled}
              className="h-9"
            >
              Upload Files
            </Button>
          </div>
        </div>
      )}

      <AddReferenceByNumberModal
        open={showAddModal}
        handleClose={handleAddPublishedPatentsClick}
        general={false}
      />
      <SearchReferencesModal
        open={showSearchModal}
        handleClose={() => setShowSearchModal(false)}
      />
      <UploadFilesModal
        open={showUploadModal}
        handleClose={handleAddOtherDocumentsClick}
      />
      <PatchReferencesModal
        open={showPatchReferencesModal}
        handleClose={handlePatchReferencesClick}
      />
      <PruneReferencesModal open={showPruneModal} onOpenChange={setShowPruneModal} />

      <Dialog open={showAddClaimsModal} onOpenChange={setShowAddClaimsModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add claims to search</DialogTitle>
            <DialogDescription>
              You must add claims to search for prior art.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowAddClaimsModal(false)}>
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={() => {
                setShowAddClaimsModal(false);
                setShowEditClaimsModal(true);
              }}
            >
              Add Claims
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <UpdateElementsModal
        open={showEditClaimsModal}
        onClose={() => setShowEditClaimsModal(false)}
        itemType={ElementType.CLAIM}
      />
      <ProcessReferencesModal
        isOpen={showProcessReferencesModal}
        onOpenChange={setShowProcessReferencesModal}
        onConfirm={handleReprocessReferences}
        documentNames={documentNames}
        isReprocess={isReprocess}
      />
      <DismissDocumentStatusModal
        isOpen={showDismissStatusesModal}
        onOpenChange={setShowDismissStatusesModal}
        onConfirm={handleDismissReferenceStatuses}
        documentNames={selectedReferencesToDismiss
          .filter(
            (reference) =>
              reference.status === DocumentStatus.RECHART ||
              reference.status === DocumentStatus.REPROCESS,
          )
          .map(
            (reference) =>
              currentProject.documentsToNicknames[reference.id] ||
              reference.name ||
              reference.title,
          )}
      />
    </ProjectPage>
  );
};

export default ReferencesPage;
