import { CheckboxWithText } from "@/components/ui/checkbox";

interface ProcessSettingsProps {
  useClaimsCheckboxChecked: boolean;
  setUseClaimsCheckboxChecked: (checked: boolean) => void;
}

export const ProcessSettings: React.FC<ProcessSettingsProps> = ({
  useClaimsCheckboxChecked,
  setUseClaimsCheckboxChecked,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {/* Hiding this checkbox feature for now, may want to add back in the future */}
      {/* <div>
        <CheckboxWithText
          id="complete-charts"
          checked={isCheckboxChecked}
          onCheckedChange={(checked) =>
            setIsCheckboxChecked(checked as boolean)
          }
          label="Complete claim and feature chart(s)"
        />
      </div> */}
      <CheckboxWithText
        id="with-claims"
        checked={useClaimsCheckboxChecked}
        onCheckedChange={(checked) => setUseClaimsCheckboxChecked(checked as boolean)}
        label="Include claims in charting"
      />
    </div>
  );
};
