/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Separator } from "@/components/ui/separator";
import React from "react";

interface SettingsSectionProps {
  label: string;
  description: string;
  children: React.ReactNode;
}

const SettingsSection = ({ label, description, children }: SettingsSectionProps) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-[300px_1fr] gap-4 md:gap-8 items-start pb-2">
        <div>
          <p className="font-medium mb-1">{label}</p>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
        {children}
      </div>
      <Separator className="my-4" />
    </div>
  );
};

export default SettingsSection;
