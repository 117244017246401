/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAppStateStore, useProcessStore, useProjectStore } from "@/store";
import { ApiResponse, DocumentType, ProcessType, StatusType } from "@/types";
import { ApplicationDocument, OfficeAction } from "@/types/project";
import { toCamelCase } from "@/utils/dataUtils";
import { nanoid } from "nanoid";
import { useApi } from "./";

/**
 * @description Hook for handling Office Action-related operations
 * @returns {object} - Actions for office actions
 */
const useOfficeAction = () => {
  const { postRequest, getRequest, postRequestFile, handleError } = useApi();
  const { updateApplicationDocuments, currentProjectId, currentProject } =
    useProjectStore();
  const { updateLoadingGroupItem, addLoadingGroupItem, addLoadingGroup } =
    useAppStateStore();
  const { addProcess, removeProcess, processes } = useProcessStore();

  const getOfficeActions = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_office_actions", {
        project_id: projectId,
      });
      const officeActionsResponse = response.data;
      const officeActions: ApplicationDocument[] = officeActionsResponse.map(
        (oa: any) => ({
          id: oa.id,
          document_type: DocumentType.OFFICE_ACTION,
          document: toCamelCase(oa) as OfficeAction,
        }),
      );
      updateApplicationDocuments(officeActions);

      return { success: true, data: officeActionsResponse };
    } catch (error) {
      return handleError(error, "Error fetching office actions");
    }
  };

  const processOfficeAction = async (
    file: File,
    projectId: string,
  ): Promise<ApiResponse> => {
    const processId = nanoid();

    try {
      addProcess({
        id: processId,
        type: ProcessType.ADD_OFFICE_ACTION,
        projectId: projectId,
      });
      addLoadingGroup(processId, currentProject.name, ProcessType.ADD_OFFICE_ACTION);
      addLoadingGroupItem(
        processId,
        ProcessType.ADD_OFFICE_ACTION,
        file.name,
        StatusType.PROCESSING,
        "",
      );

      const summaryResponse = await processOfficeActionSummary(file);
      // console.log("summary response", summaryResponse.data);

      const bodyResponse = await processOfficeActionClaimDetails(
        projectId,
        [summaryResponse.data.id],
        true, // partial process, don't show notifs specifically for reprocessing
      );

      if (bodyResponse.success) {
        updateLoadingGroupItem(
          processId,
          ProcessType.ADD_OFFICE_ACTION,
          file.name,
          StatusType.SUCCESS,
          "",
        );
        removeProcess(processId);

        return bodyResponse;
      }
    } catch (error) {
      removeProcess(processId);
      updateLoadingGroupItem(
        processId,
        ProcessType.ADD_OFFICE_ACTION,
        file.name,
        StatusType.ERROR,
        "",
      );
      return handleError("Error processing office action:", error);
    }
  };

  const processOfficeActionSummary = async (file: File): Promise<ApiResponse> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await postRequestFile("post_process_office_action", formData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading file to S3");
    }
  };

  const processOfficeActionClaimDetails = async (
    projectId: string,
    officeActionIds: string[],
    partialProcess: boolean = false,
  ): Promise<ApiResponse> => {
    // console.log("Starting processOfficeActionClaimDetails with:", {
    //   projectId,
    //   officeActionIds,
    // });

    const processId = nanoid();
    try {
      if (!partialProcess) {
        addProcess({
          id: processId,
          type: ProcessType.REPROCESS,
          projectId: projectId,
        });
        addLoadingGroup(processId, currentProject.name, ProcessType.REPROCESS);
        addLoadingGroupItem(
          processId,
          ProcessType.REPROCESS,
          "Office Action",
          StatusType.PROCESSING,
          "",
        );
      }
      const queryParams = new URLSearchParams({
        project_id: projectId,
        office_action_id: officeActionIds[0],
      });

      // console.log("Making request with query params:", queryParams.toString());

      const response = await postRequest(
        `post_process_office_action_body?${queryParams.toString()}`,
      );

      // console.log("Received response:", response);
      if (!partialProcess) {
        updateLoadingGroupItem(
          processId,
          ProcessType.REPROCESS,
          "Office Action",
          StatusType.SUCCESS,
          "",
        );
        removeProcess(processId);
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      if (!partialProcess) {
        removeProcess(processId);
        updateLoadingGroupItem(
          processId,
          ProcessType.REPROCESS,
          "Office Action",
          StatusType.ERROR,
          "",
        );
      }
      console.error("Error in processOfficeActionClaimDetails:", error);
      return handleError(error, "Could not find a valid patent");
    }
  };

  //   const processFullOfficeAction

  const deleteOfficeAction = async (
    officeActionIds?: string[],
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("delete_references_from_project", {
        project_id: currentProjectId,
        reference_ids: officeActionIds,
      });

      await getOfficeActions(currentProjectId);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error deleting office action");
    }
  };

  return {
    getOfficeActions,
    processOfficeAction,
    processOfficeActionSummary,
    processOfficeActionClaimDetails,
    deleteOfficeAction,
  };
};

export default useOfficeAction;
