/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAppStateStore, useCreateProjectStore, useProjectStore } from "@/store";
import { ApiResponse, ParentType, ProcessType } from "@/types";
import { useNavigate } from "react-router-dom";
import { useApi, useLlm, useOfficeAction, useProject, useVector, useViz } from ".";

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useCreateProject = () => {
  const { postRequest, handleError } = useApi();
  const {
    generateFeatures,
    generatePatentFromDisclosure,
    generateFeaturesFromClaims,
    generatePatentContextFromId,
  } = useLlm();
  const { addErrorMessage } = useAppStateStore();
  const { uploadToVDB } = useVector();
  const { getProjectMetadata, addToDocumentsAdded } = useProject();
  const { getUserProjects } = useViz();
  const { processOfficeActionSummary, processOfficeActionClaimDetails } =
    useOfficeAction();
  const {
    updateCurrentProjectId,
    updateCurrentParent,
    updateCurrentPortfolio,
    updateCurrentPortfolioId,
  } = useProjectStore();

  const {
    subjectNumbers,
    subjectDetails,
    projectName,
    clientNumber,
    features,
    claims,
    abstract,
    subjectMode,
    disclosure_files,
    supportingDocuments,
    extractedApplicationDetails,
    body,
    updateAbstract,
    updateBody,
    updateProjectError,
    updateFeatures,
    updateClaims,
    updateSpinnerText,
    updateProcesses,
    updateExtractedApplicationDetails,
    removeProcesses,
  } = useCreateProjectStore();
  const navigate = useNavigate();

  const processType = ProcessType.CREATE_PROJECT;
  /**
   * Fetches and navigates to a project at the end of creation
   * @param {string} projectId - The id of the project to fetch
   */
  const fetchAndNavigateToProject = async (projectId: string) => {
    updateCurrentParent(ParentType.PROJECT);
    updateCurrentPortfolio(null);
    updateCurrentPortfolioId(null);
    updateCurrentProjectId(projectId);
    const response = await getProjectMetadata(projectId);
    const finalIds = [response.data.subject.id];
    await addToDocumentsAdded(projectId, finalIds, false);
    if (!response.success) {
      addErrorMessage(
        "Error fetching project data. Please navigate to project from home.",
      );
      navigate("/home");
      return;
    }
    navigate(`/project/${projectId}/subject`);
  };

  /**
   * @description Creates a project from element language
   * @param {string} projectType - The type of the project
   */
  const createProjectFromLanguage = async (
    projectType: string,
  ): Promise<ApiResponse> => {
    let currentClaims = claims;
    let currentAbstract = abstract;
    try {
      updateSpinnerText("Creating project...");

      // Generate features if not provided
      let featuresToSend: { [key: string]: string }[] = [];
      if (subjectMode === "disclosure") {
        const disclosureResponse = await generatePatentFromDisclosure(disclosure_files);
        if (disclosureResponse.success) {
          const formattedClaims = disclosureResponse.data.claims.map(
            (claim: string, index: number) => {
              return { [String(index + 1)]: claim };
            },
          );
          updateClaims(formattedClaims);
          currentClaims = formattedClaims;
          updateAbstract(disclosureResponse.data.abstract);
          currentAbstract = disclosureResponse.data.abstract;
        }
      }

      if (
        (subjectMode === "claim" || subjectMode === "disclosure") &&
        currentClaims.length > 0
      ) {
        const featuresResponse = await generateFeaturesFromClaims(currentClaims);
        if (featuresResponse.success) {
          featuresToSend =
            (featuresResponse.data as unknown as { [key: string]: string }[]) || [];
        }
      } else {
        featuresToSend = [];
      }

      // Create project and update state
      const res = await postRequest("post_create_project_from_language", {
        name: projectName,
        claims: subjectMode === "feature" ? [] : currentClaims,
        features: featuresToSend,
        client_number: clientNumber,
        type: projectType,
        abstract: currentAbstract || "",
        // cpc_codes: cpcCodes,
      });

      const response = res.data;
      const rSubjectId = response.subject_id;
      const rProjectId = response.project_id;
      const rFeatures = featuresToSend;

      await getUserProjects();

      // Fetch and navigate to project
      await fetchAndNavigateToProject(rProjectId);

      return { success: true, data: { rSubjectId, rProjectId, rFeatures } };
    } catch (error) {
      return handleError(error, "Error creating project from language");
    }
  };

  /**
   * @description Creates a project from a patent or application number
   * @param {string} project_type - The type of the project
   * @returns Result object with success status and data or error message.
   */
  const createProjectFromNumber = async (projectType: string): Promise<ApiResponse> => {
    try {
      const officeActionDetails = {
        application_number: extractedApplicationDetails?.applicationNumber,
        notification_date: extractedApplicationDetails?.notificationDate,
      };

      // Split body into array of strings, remove empty strings
      let splitBody: string[] = body
        ? body.split("\n").filter((line) => line.trim() !== "")
        : [];

      const basePayload = {
        name: projectName,
        type: projectType,
        client_number: clientNumber,
        cpc_codes: [],
      };

      const payload = {
        ...basePayload,
        ...(subjectNumbers[0] && { subject_number: subjectNumbers[0] }),
        ...(subjectDetails[0]?.id && { subject_id: subjectDetails[0].id }),
        ...(officeActionDetails.application_number &&
          officeActionDetails.notification_date && {
            office_action_details: officeActionDetails,
          }),
        ...(features && { features }),
        ...(claims && { claims }),
        ...(splitBody && { body: splitBody }),
        ...(abstract && { abstract }),
      };

      const res = await postRequest("post_create_project", payload);

      const response = res.data;
      const rSubjectId = response.subject_id;
      const rProjectId = response.project_id;

      let documents = [rSubjectId];
      const rOfficeActionId = response.office_action_id;
      if (rOfficeActionId) {
        documents = [...documents, rOfficeActionId];
      }
      const uploadResponse = await uploadToVDB(
        rProjectId,
        documents,
        false,
        false,
        false,
      );

      if (!uploadResponse.success) {
        // updateCreateProjectError(true);
        addErrorMessage(
          uploadResponse.message ||
            "An error occurred while creating this project. Try again later.",
        );
        // return;
      }

      if (rOfficeActionId) {
        // Only get office action claim details if there's an office action
        await processOfficeActionClaimDetails(rProjectId, [rOfficeActionId], true);
      } else {
        // Generate features and context only if there's no office action
        const [featuresResponse, patentContextResponse] = await Promise.all([
          generateFeatures(rSubjectId, rProjectId),
          generatePatentContextFromId(rProjectId, rSubjectId),
        ]);

        // Handle features response
        if (featuresResponse.success) {
          updateFeatures(
            (featuresResponse.data as unknown as { [key: string]: string }[]) || [],
          );
        }
      }

      await fetchAndNavigateToProject(rProjectId);
      await getUserProjects();

      return {
        success: true,
      };
    } catch (error) {
      updateProjectError(true);
      return handleError(error, "Error creating project from number");
    }
  };

  /**
   * @description Creates a project after office action processing is complete
   * @param {string} projectType - The type of the project
   * @returns Result object with success status and data or error message.
   */
  const createProjectAfterOfficeActionProcess = async (
    projectType: string,
  ): Promise<ApiResponse> => {
    try {
      // Create project first
      const result = await createProjectFromNumber(projectType);

      // Only remove processes after successful creation
      if (result.success) {
        removeProcesses(ProcessType.ADD_OFFICE_ACTION);
        return { success: true };
      }

      return result;
    } catch (error) {
      updateProjectError(true);
      return handleError(error, "Error creating project after office action process");
    }
  };

  /**
   * @description Creates a project from a patent or application number
   * @param {string} subjectNumbers - The numbers of the subject to create the project for
   * @param {boolean} getFeatures - Whether to get features for the subject
   * @returns Result object with success status and data or error message.
   */
  const getSubjectDetailsOnCreate = async (
    subjectNumbers: string[],
    getFeatures: boolean,
  ): Promise<any> => {
    try {
      // Create project in db
      const res = await postRequest("post_subject_details_on_create", {
        subject_numbers: subjectNumbers,
      });
      const response = res.data;

      if (getFeatures) {
        const firstResponse = response[0];
        const featuresResponse = await generateFeatures(firstResponse.id);
        let features: { [key: string]: string }[] = [];
        if (featuresResponse.success) {
          features =
            (featuresResponse.data as unknown as { [key: string]: string }[]) || [];
        }
        updateFeatures(features);

        return {
          success: true,
          data: [
            {
              ...response[0],
              features: features,
            },
          ],
        };
      } else {
        return { success: true, data: response };
      }
    } catch (error) {
      updateProjectError(true);
      return handleError(error, "Error creating project from number");
    }
  };

  /**
   * @description Creates a parsing process for a new office action
   */
  const createOfficeActionParse = async (): Promise<ApiResponse> => {
    const officeActionDocument = supportingDocuments.find(
      (doc) => doc.documentType === "OFFICE_ACTION",
    );
    if (!officeActionDocument) {
      return;
    }
    const processId = officeActionDocument.fileName;

    try {
      // Start parsing process
      await updateProcesses({
        id: processId,
        type: ProcessType.ADD_OFFICE_ACTION,
        status: "pending",
      });
      const parsed_summary = await processOfficeActionSummary(
        officeActionDocument.file,
      );

      // End parsing process, check if there is an existing process
      const processExists = useCreateProjectStore
        .getState()
        .processes.some((process) => process.id === processId);

      if (processExists) {
        updateExtractedApplicationDetails({
          applicationNumber: parsed_summary.data.application_number,
          notificationDate: parsed_summary.data.notification_date,
        });
        await updateProcesses({
          id: processId,
          type: ProcessType.ADD_OFFICE_ACTION,
          status: "completed",
        });
      }
    } catch (error) {
      console.error("Error in createOfficeActionParse:", error);
      await updateProcesses({
        id: processId,
        type: ProcessType.ADD_OFFICE_ACTION,
        status: "error",
      });
      return handleError(error, "Error creating project");
    }
  };

  return {
    fetchAndNavigateToProject,
    createProjectFromLanguage,
    createProjectFromNumber,
    createProjectAfterOfficeActionProcess,
    getSubjectDetailsOnCreate,
    createOfficeActionParse,
  };
};

export default useCreateProject;
