/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks";
import { ApiResponse } from "@/types";

const useElementOperations = () => {
  const { postRequest, handleError } = useApi();

  const updateElements = async (
    projectId: string,
    elements: { [key: string]: string }[],
    is_feature: boolean,
    assertedClaims: string[],
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_elements", {
        project_id: projectId,
        elements: elements,
        is_feature: is_feature,
        asserted_claims: assertedClaims,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating elements");
    }
  };

  return {
    updateElements,
  };
};

export default useElementOperations;
