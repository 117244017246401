/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Table, TableBody, TableCellCondensed, TableRow } from "@/components/ui/table";
import { useViz } from "@/hooks";
import { useProjectStore } from "@/store";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Cross1Icon, Pencil1Icon, PlusIcon } from "@radix-ui/react-icons";
import { GripVerticalIcon } from "lucide-react";
import { useEffect, useReducer } from "react";

interface Section112TableProps {
  type: "indefiniteness" | "enablement" | "written_description";
  claims: Array<Record<string, string>>;
}

interface EditState {
  isEditing: boolean;
  currentClaims: Array<Record<string, string>>;
  originalClaims: Array<Record<string, string>>;
}

type EditAction =
  | { type: "START_EDITING" }
  | { type: "CANCEL_EDITING" }
  | { type: "UPDATE_CLAIMS"; claims: Array<Record<string, string>> }
  | { type: "RESET_TO_ORIGINAL" }
  | { type: "SAVE_CHANGES"; claims: Array<Record<string, string>> };

function editReducer(state: EditState, action: EditAction): EditState {
  switch (action.type) {
    case "START_EDITING":
      return {
        ...state,
        isEditing: true,
        originalClaims: [...state.currentClaims],
      };
    case "CANCEL_EDITING":
      return {
        ...state,
        isEditing: false,
        currentClaims: [...state.originalClaims],
      };
    case "UPDATE_CLAIMS":
      return {
        ...state,
        currentClaims: action.claims,
      };
    case "RESET_TO_ORIGINAL":
      return {
        ...state,
        isEditing: false,
        currentClaims: [...state.originalClaims],
      };
    case "SAVE_CHANGES":
      return {
        ...state,
        isEditing: false,
        currentClaims: action.claims,
        originalClaims: action.claims,
      };
    default:
      return state;
  }
}

export function Section112Table({ type, claims }: Section112TableProps) {
  const [state, dispatch] = useReducer(editReducer, {
    isEditing: false,
    currentClaims: claims || [],
    originalClaims: claims || [],
  });
  const { currentProjectId, currentProject, updateCurrentProject } = useProjectStore();
  const { updateSection112 } = useViz();

  useEffect(() => {
    dispatch({ type: "UPDATE_CLAIMS", claims: claims || [] });
  }, [claims]);

  const getTypeHeader = () => {
    switch (type) {
      case "indefiniteness":
        return "Indefinite Claim Limitation(s)";
      case "enablement":
        return "Claim Limitation(s) Lacking Enablement";
      case "written_description":
        return "Claim Limitation(s) Lacking Written Description Support";
      default:
        return "";
    }
  };

  const handleEditButton = () => {
    dispatch({ type: "START_EDITING" });
  };

  const handleSave = async () => {
    const filteredClaims = state.currentClaims.filter((claim) => {
      const [[number, language]] = Object.entries(claim);
      return number.trim() !== "" || language.trim() !== "";
    });

    updateCurrentProject({
      id: currentProject.id,
      name: currentProject.name,
      [type]: filteredClaims,
    });
    await updateSection112(currentProjectId, filteredClaims, type);

    dispatch({ type: "SAVE_CHANGES", claims: filteredClaims });
  };

  const handleReset = () => {
    dispatch({ type: "RESET_TO_ORIGINAL" });
  };

  const hasChanges = () => {
    return JSON.stringify(state.currentClaims) !== JSON.stringify(state.originalClaims);
  };

  const handleClaimChange = (
    index: number,
    field: "number" | "language",
    value: string,
  ) => {
    const newClaims = [...state.currentClaims];
    const claim = { ...newClaims[index] };
    const [[oldNumber]] = Object.entries(claim);

    if (field === "number") {
      newClaims[index] = { [value]: Object.values(claim)[0] };
    } else {
      newClaims[index] = { [oldNumber]: value };
    }
    dispatch({ type: "UPDATE_CLAIMS", claims: newClaims });
  };

  const handleAddRow = () => {
    dispatch({
      type: "UPDATE_CLAIMS",
      claims: [...state.currentClaims, { "": "" }],
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = state.currentClaims.findIndex(
        (_, index) => `claim-${index}` === active.id,
      );
      const newIndex = state.currentClaims.findIndex(
        (_, index) => `claim-${index}` === over.id,
      );
      const newClaims = arrayMove(state.currentClaims, oldIndex, newIndex);
      dispatch({ type: "UPDATE_CLAIMS", claims: newClaims });
    }
  };

  const handleDeleteRow = (index: number) => {
    const newClaims = [...state.currentClaims];
    newClaims.splice(index, 1);
    dispatch({ type: "UPDATE_CLAIMS", claims: newClaims });
  };

  return (
    <div className="border rounded-lg  p-2 w-full mb-2">
      <div>
        <div className="flex items-center justify-between sticky top-0 bg-background z-10 py-2 border-b">
          <div className="flex items-center gap-2 pl-2 justify-between w-full">
            <Label className="mb-0">{getTypeHeader()}</Label>

            {!state.isEditing ? (
              <Button
                variant="ghost"
                size="icon"
                onClick={handleEditButton}
                aria-label="edit"
              >
                <Pencil1Icon className="h-4 w-4" />
              </Button>
            ) : (
              <div className="flex items-center gap-1">
                <Button variant="outline" className="h-9" onClick={handleReset}>
                  {hasChanges() ? "Reset" : "Cancel"}
                </Button>
                <Button className="h-9" onClick={handleSave}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToParentElement]}
        >
          <SortableContext
            items={state.currentClaims.map((_, index) => `claim-${index}`)}
            strategy={verticalListSortingStrategy}
          >
            <Table>
              <TableBody>
                {state.currentClaims.map((claim, index) => (
                  <SortableTableRow
                    key={`claim-${index}`}
                    id={`claim-${index}`}
                    claim={claim}
                    index={index}
                    showEditModal={state.isEditing}
                    handleClaimChange={handleClaimChange}
                    handleDeleteRow={handleDeleteRow}
                  />
                ))}
              </TableBody>
            </Table>
          </SortableContext>
        </DndContext>
        {state.isEditing ? (
          <div className="flex justify-center mt-2">
            <Button size="icon" onClick={handleAddRow}>
              <PlusIcon className="h-4 w-4" />
            </Button>
          </div>
        ) : (
          <>
            {state.currentClaims.length === 0 && (
              <p className="text-muted-foreground text-sm my-3 text-center">
                None found.
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const SortableTableRow = ({
  id,
  claim,
  index,
  showEditModal,
  handleClaimChange,
  handleDeleteRow,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id });

  const style = {
    transform: transform ? `translate3d(0, ${transform.y}px, 0)` : undefined,
    transition,
    background: isDragging ? "var(--background)" : undefined,
    height: "var(--row-height)",
    width: "100%",
    boxShadow: isDragging ? "0 0 0.5rem rgba(0, 0, 0, 0.1)" : "none",
  };

  const [[claimNumber, language]] = Object.entries(claim);

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={isDragging ? "bg-background" : ""}
    >
      <TableCellCondensed>
        <div className="flex items-center gap-2 max-w-[120px] ">
          {showEditModal && (
            <div {...listeners}>
              <GripVerticalIcon className="mr-4 h-4 w-4 text-gray-500" />
            </div>
          )}
          {showEditModal ? (
            <Input
              type="text"
              value={claimNumber}
              onChange={(e) => handleClaimChange(index, "number", e.target.value)}
              className="h-9"
            />
          ) : (
            <span>{claimNumber}</span>
          )}
        </div>
      </TableCellCondensed>
      <TableCellCondensed>
        {showEditModal ? (
          <AutosizeTextarea
            value={language as string}
            onChange={(e) => handleClaimChange(index, "language", e.target.value)}
            className="w-full"
          />
        ) : (
          <span>{language as string}</span>
        )}
      </TableCellCondensed>
      {showEditModal && (
        <TableCellCondensed className="w-[50px]">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleDeleteRow(index)}
            className="h-8 w-8"
          >
            <Cross1Icon className="h-4 w-4" />
          </Button>
        </TableCellCondensed>
      )}
    </TableRow>
  );
};
