/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Alert, AlertTitle } from "@/components/ui/alert";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { ProcessType } from "@/types";
import { capitalizeFirstLetter } from "@/utils/dataUtils";
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  X,
} from "lucide-react";
import React, { useState } from "react";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Spinner } from "../ui/spinner";

interface LoadingGroupAlertProps {
  loadingGroup: any;
  removeLoadingGroup: (key: string, processType: string) => void;
  processType: ProcessType;
}

const LoadingGroupAlert: React.FC<LoadingGroupAlertProps> = ({
  loadingGroup,
  removeLoadingGroup,
  processType,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const dedupedMessages: any[] = Array.from(
    new Map(
      loadingGroup.messages.map((message) => [message.name, message]).values(),
    ).values(),
  );

  const totalCount = dedupedMessages.length;
  const successCount = dedupedMessages.filter((m) => m.status === "success").length;
  const errorCount = dedupedMessages.filter((m) => m.status === "error").length;
  const warningCount = dedupedMessages.filter((m) => m.status === "warning").length;
  const loadingCount = totalCount - successCount - errorCount - warningCount;

  const isLoading = dedupedMessages.some((m) => m.status === "processing");

  const getStatusIcon = () => {
    if (loadingCount > 0) {
      return <Spinner className="mr-2 h-4 w-4" />;
    } else if (errorCount > 0) {
      return <AlertCircle className="mr-2 h-4 w-4 text-red-500" />;
    } else if (warningCount > 0) {
      return <AlertTriangle className="mr-2 h-4 w-4 text-yellow-500" />;
    } else {
      return <CheckCircle className="mr-2 h-4 w-4 text-blue-500" />;
    }
  };

  const getTitle = () => {
    const groupName = loadingGroup.name.startsWith("AndAISearchChat")
      ? "Explore &AI"
      : loadingGroup.name;

    if (processType === ProcessType.ADD_REFERENCE) {
      if (loadingCount > 0) {
        return `Adding ${loadingCount} ${
          loadingCount === 1 ? "reference" : "references"
        } to ${groupName}`;
      } else if (errorCount > 0) {
        return `Added ${successCount} ${
          successCount === 1 ? "reference" : "references"
        } to ${groupName}. ${errorCount} failed`;
      } else if (warningCount > 0) {
        return `Added ${successCount} ${
          successCount === 1 ? "reference" : "references"
        } to ${groupName}. ${warningCount} skipped`;
      } else {
        return `Added ${successCount} ${
          successCount === 1 ? "reference" : "references"
        } to ${groupName}`;
      }
    } else if (processType === ProcessType.GENERATE_CONTEXT) {
      if (loadingCount > 0) {
        return `Generating context for ${loadingCount} ${
          loadingCount === 1 ? "project" : "projects"
        } in ${groupName}`;
      } else if (errorCount > 0) {
        return `Generated context for ${successCount} ${
          successCount === 1 ? "project" : "projects"
        } in ${groupName}. ${errorCount} failed`;
      } else if (warningCount > 0) {
        return `Generated context for ${successCount} ${
          successCount === 1 ? "project" : "projects"
        } in ${groupName}. ${warningCount} skipped`;
      } else {
        return `Generated context for ${successCount} ${
          successCount === 1 ? "project" : "projects"
        } in ${groupName}`;
      }
    } else if (
      processType === ProcessType.RECHART ||
      processType === ProcessType.REPROCESS
    ) {
      const action =
        processType === ProcessType.RECHART ? "Recharting" : "Reprocessing";
      const completedAction =
        processType === ProcessType.RECHART ? "Recharted" : "Reprocessed";
      // return `Reprocessing references for ${groupName}`;
      if (loadingCount > 0 && successCount > 0) {
        return `${action} ${loadingCount} ${
          loadingCount === 1 ? "reference" : "references"
        } for ${groupName}. ${successCount} ${completedAction}.`;
      } else if (loadingCount > 0) {
        return `${action} ${loadingCount} ${
          loadingCount === 1 ? "reference" : "references"
        } for ${groupName}`;
      } else if (errorCount > 0) {
        return `${completedAction} ${successCount} ${
          successCount === 1 ? "reference" : "references"
        } for ${groupName}. ${errorCount} failed`;
      } else if (warningCount > 0) {
        return `${completedAction} ${successCount} ${
          successCount === 1 ? "reference" : "references"
        } for ${groupName}. ${warningCount} skipped`;
      } else {
        return `${completedAction} ${successCount} ${
          successCount === 1 ? "reference" : "references"
        } for ${groupName}`;
      }
    } else if (processType === ProcessType.ADD_SUBJECT) {
      if (loadingCount > 0) {
        return `Adding ${loadingCount} ${
          loadingCount === 1 ? "subject" : "subjects"
        } to ${groupName}`;
      } else if (errorCount > 0) {
        return `Added ${successCount} ${
          successCount === 1 ? "subject" : "subjects"
        } to ${groupName}. ${errorCount} failed`;
      } else if (warningCount > 0) {
        return `Added ${successCount} ${
          successCount === 1 ? "subject" : "subjects"
        } to ${groupName}. ${warningCount} skipped`;
      } else {
        return `Added ${successCount} ${
          successCount === 1 ? "subject" : "subjects"
        } to ${groupName}`;
      }
    } else if (processType === ProcessType.ADD_OFFICE_ACTION) {
      if (loadingCount > 0) {
        return `Adding ${loadingCount} ${
          loadingCount === 1 ? "office action" : "office actions"
        } to ${groupName}`;
      } else if (errorCount > 0) {
        return `Added ${successCount} ${
          successCount === 1 ? "office action" : "office actions"
        } to ${groupName}. ${errorCount} failed`;
      } else if (warningCount > 0) {
        return `Added ${successCount} ${
          successCount === 1 ? "office action" : "office actions"
        } to ${groupName}. ${warningCount} skipped`;
      } else {
        return `Added ${successCount} ${
          successCount === 1 ? "office action" : "office actions"
        } to ${groupName}`;
      }
    }
  };

  return (
    <Alert variant="default" className="flex items-center pr-8 relative">
      <div className="w-full">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {getStatusIcon()}
            <AlertTitle className="mb-0 ml-2">{getTitle()}</AlertTitle>
          </div>
          <div className="flex items-center justify-end ml-2">
            {dedupedMessages.length > 0 && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowDetails(!showDetails);
                    }}
                    onPointerDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className="mr-2"
                  >
                    {showDetails ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  {showDetails ? "Hide Details" : "View Details"}
                </TooltipContent>
              </Tooltip>
            )}

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeLoadingGroup(loadingGroup.key, loadingGroup.processType);
                  }}
                  onPointerDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <X className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Dismiss</TooltipContent>
            </Tooltip>
          </div>
        </div>
        {showDetails && (
          <div
            className="mt-2 space-y-2"
            onClick={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
          >
            {dedupedMessages.map((message) => (
              <div
                key={
                  message.displayName ? message.displayName.slice(0, 20) : message.name
                }
                className="flex flex-col"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center mr-2">
                    {message.status === "success" ? (
                      <CheckCircle className="mr-3 flex-shrink-0 h-4 w-4 text-blue-500" />
                    ) : message.status === "error" ? (
                      <AlertCircle className="mr-3 flex-shrink-0 h-4 w-4 text-red-500" />
                    ) : message.status === "warning" ? (
                      <AlertTriangle className="mr-3 flex-shrink-0 h-4 w-4 text-yellow-500" />
                    ) : (
                      <Spinner className="mr-3 flex-shrink-0 h-4 w-4" />
                    )}
                    <span>
                      {message.displayName
                        ? message.displayName.slice(0, 20)
                        : message.name}
                    </span>
                  </div>
                  <Badge variant="outline">
                    {capitalizeFirstLetter(message.status)}
                  </Badge>
                </div>
                <p
                  className={`ml-8 text-sm ${
                    message.status === "error"
                      ? "text-destructive"
                      : "text-muted-foreground"
                  }`}
                >
                  {message.detail}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </Alert>
  );
};

export default LoadingGroupAlert;
