import {
  AutosizeTextarea,
  AutosizeTextAreaRef,
} from "@/components/ui/autosize-textarea";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import React, { useCallback, useEffect, useRef } from "react";

import { useProjectStore } from "@/store";
import { convertToUtcDateString } from "@/utils/dateUtils";
import { splitId } from "@/utils/projectUtils";

interface Variable {
  id: string;
  name: string;
  current: string;
}

interface CustomInputProps {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  variables: Variable[];
}

const CustomInput: React.FC<CustomInputProps> = React.memo(
  ({ id, label, value, onChange, variables }) => {
    const textareaRef = useRef<AutosizeTextAreaRef>(null);
    const currentProject = useProjectStore((state) => state.currentProject);
    const selectedReferences = useProjectStore((state) => state.selectedReferences);

    // Ensure value exists before trying to replace
    const previewText = value
      ? value.replace(/{{(\w+)}}/g, (match, variable) => {
          switch (variable) {
            case "element_number":
              return variables.find((v) => v.id === "element_number")?.current ?? match;
            case "element_language":
              return (
                variables.find((v) => v.id === "element_language")?.current ?? match
              );
            case "reference_name": {
              // Extra defensive checks for reference name
              if (!currentProject || !currentProject.documentsToNicknames) {
                return match;
              }
              const nickname =
                currentProject.documentsToNicknames[selectedReferences[0].id];
              return nickname || match;
            }
            case "subject_nickname": {
              return (
                currentProject?.documentsToNicknames[currentProject?.subject?.id] ||
                match
              );
            }
            case "subject_number": {
              return splitId(currentProject?.subject?.number) || match;
            }
            case "subject_priority_date": {
              return (
                convertToUtcDateString(currentProject?.subject?.priorityDate) || match
              );
            }
            default:
              return match;
          }
        })
      : "";

    useEffect(() => {
      // This effect runs after every render
      // It will maintain focus on the input if it already had focus
      if (document.activeElement === textareaRef.current?.textArea) {
        textareaRef.current?.textArea.focus();
      }
    });

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
      },
      [onChange],
    );

    const insertVariable = useCallback(
      (variable: string) => {
        if (textareaRef.current) {
          const textarea = textareaRef.current.textArea;
          const start = textarea.selectionStart || 0;
          const end = textarea.selectionEnd || 0;
          const currentValue = value || "";
          const newValue =
            currentValue.substring(0, start) +
            ` {{${variable}}} ` +
            currentValue.substring(end);
          onChange(newValue);

          requestAnimationFrame(() => {
            const newPosition = start + variable.length + 6; // 6 accounts for {{ }} and spaces
            textarea.setSelectionRange(newPosition, newPosition);
            textarea.focus();
          });
        }
      },
      [value, onChange],
    );

    const showPreview = value?.includes("{{") ?? false;

    return (
      <div className="space-y-2">
        <Label htmlFor={id} className="text-left">
          {label}
        </Label>
        <div className="flex items-center">
          <AutosizeTextarea
            ref={textareaRef}
            id={id}
            value={value}
            onChange={handleChange}
            className="flex-grow"
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button type="button" variant="outline" className="ml-2">
                Insert Variable
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-48">
              <div className="grid gap-2">
                {variables.map((variable) => (
                  <DropdownMenuItem
                    key={variable.id}
                    onClick={() => insertVariable(variable.id)}
                  >
                    {variable.name}
                  </DropdownMenuItem>
                ))}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {showPreview && (
          <div className="mt-2 p-2 bg-gray-100 rounded">
            <Label className="text-sm font-medium">Preview:</Label>
            <p className="text-sm">{previewText}</p>
          </div>
        )}
      </div>
    );
  },
);

export default CustomInput;
