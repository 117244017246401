/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
  useAppStateStore,
  useCreateProjectStore,
  useProcessStore,
  useProjectStore,
} from "@/store";
import {
  ApiResponse,
  ParentType,
  PatentContext,
  ProcessType,
  Project,
  ProjectType,
  StatusType,
} from "@/types";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { useApi, useLlm, useProject, useVector, useViz } from "./";
/**
 * @description Hook for handling portfolio operations
 */
const usePortfolio = () => {
  const navigate = useNavigate();
  const { postRequest, getRequest, handleError } = useApi();
  const { rerankReferences, uploadToVDBWithRetry } = useVector();
  const {
    generateFeatures,
    generatePatentContextFromId,
    generatePatentContextFromText,
  } = useLlm();
  const { getPortfolioMetadata, uploadFile } = useViz();
  const { updateProjectDetails } = useProject();

  const { addProcess, removeProcess } = useProcessStore();
  const {
    currentPortfolio,
    currentPortfolioId,
    updateCurrentPortfolio,
    updateCurrentPortfolioId,
    updateCurrentParent,
    clearCurrentProject,
  } = useProjectStore();
  const {
    addErrorMessage,
    addSuccessMessage,
    addLoadingGroupItem,
    updateLoadingGroupItem,
    addLoadingGroup,
  } = useAppStateStore();
  const {
    subjectNumbers,
    projectName,
    clientNumber,
    updateSpinnerText,
    updateIsProjectCreationInProgress,
  } = useCreateProjectStore();

  /**
   * @description Updates portfolio details
   * @param {string} portfolioId - The id of the portfolio to update
   * @param {Object} options - The options to update the portfolio with
   */
  const updatePortfolioDetails = async (
    portfolioId: string,
    options: any,
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      portfolio_id: portfolioId,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest("post_update_portfolio_details", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error updating portfolio metadata");
    }
  };

  /**
   * @description Adds a user to a portfolio
   * @param {string} portfolioId - The id of the portfolio to add the user to
   * @param {string} userEmail - The email of the user to add to the portfolio
   */
  const addUserToPortfolio = async (
    portfolioId: string,
    userId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_add_user_to_portfolio", {
        portfolio_id: portfolioId,
        user_id: userId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error adding user to portfolio");
    }
  };

  /**
   * @description Removes a user from a portfolio
   * @param {string} portfolioId - The id of the portfolio to remove the user from
   * @param {string} userEmail - The email of the user to remove from the portfolio
   */
  const removeUserFromPortfolio = async (
    portfolioId: string,
    userId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_remove_user_from_portfolio", {
        portfolio_id: portfolioId,
        user_id: userId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error removing user from portfolio");
    }
  };

  /**
   * @description Fetches all users for a portfolio
   * @param {string} portfolioId - The id of the portfolio to fetch users for
   */
  const getUsersForPortfolio = async (
    portfolioId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_users_for_portfolio", {
        portfolio_id: portfolioId,
      });
      return {
        success: true,
        data: {
          users_on_project: response.data.users_on_project,
          shareable_users: response.data.shareable_users,
        },
      };
    } catch (error) {
      return handleError(error, "Error fetching users for project");
    }
  };

  /**
   * @description Chunks portfolio projects and uploads them to the vector database, helper
   * @param projects - The projects to chunk and upload
   */
  const processPortfolioProjects = async (projects: any[]) => {
    const projectPromises = projects.map(async (project) => {
      const documents = [project.subject_id];
      const projectId = project.project_id;
      await generateFeatures(project.subject_id, projectId);
      const uploadResponse = await uploadToVDBWithRetry(
        projectId,
        documents,
        false,
        true,
      );
      if (!uploadResponse.success) {
        addErrorMessage(
          uploadResponse.message ||
            "An error occurred while creating this project. Try again later.",
        );
      }
      await generatePatentContextFromId(projectId, project.subject_id);
    });
    await Promise.all(projectPromises);
  };

  /**
   * @description Creates a project from a patent or application number
   */
  const createPortfolioFromPatentNumbers = async (): Promise<ApiResponse> => {
    const key = nanoid();
    try {
      addProcess({
        id: key,
        type: ProcessType.CREATE_PORTFOLIO,
      });
      let finalName = projectName;
      if (!finalName) {
        finalName = `Untitled Portfolio`;
      }
      updateSpinnerText(`Creating portfolio...`);

      const res = await postRequest("post_create_portfolio", {
        patent_numbers: subjectNumbers,
        name: projectName,
        client_number: clientNumber,
        type: ProjectType.PFA,
      });
      const response = res.data.data;

      updateCurrentPortfolioId(response.id);
      updateCurrentPortfolio({
        ...currentPortfolio,
        id: response.id,
        name: projectName,
        type: ProjectType.PFA,
        clientNumber: response.client_number,
        projects: response.projects,
        owner: response.created_by,
        references: [],
      });

      await processPortfolioProjects(response.projects);

      await fetchAndNavigateToPortfolio(response.id);

      return {
        success: true,
        data: { ...response },
      };
    } catch (error) {
      return handleError(error, "Error creating portfolio. Try again later.");
    } finally {
      removeProcess(key);
    }
  };

  /**
   * @description Removes projects from a portfolio
   * @param portfolioId - The id of the portfolio to remove the projects from
   * @param projectIds - The ids of the projects to remove from the portfolio
   */
  const removeProjectsFromPortfolio = async (
    portfolioId: string,
    projectIds: string[],
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_remove_projects_from_portfolio", {
        portfolio_id: portfolioId,
        project_ids: projectIds,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error removing patents from portfolio");
    }
  };

  const getPatentsForAssignee = async (
    assignee: string[],
    priorityDate: string,
    expiryDate: string,
    cpcCodes: string[],
    types: string[],
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_patents_for_assignee", {
        assignee: assignee,
        priority_date_start: priorityDate,
        priority_date_end: expiryDate,
        cpc_codes: cpcCodes,
        types: types,
        limit: 1000,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching patents for assignee");
    }
  };

  /**
   * Fetches and navigates to a portfolio at the end of creation
   * @param {string} portfolioId - The id of the portfolio to fetch
   */
  const fetchAndNavigateToPortfolio = async (portfolioId: string) => {
    clearCurrentProject();
    updateCurrentParent(ParentType.PORTFOLIO);
    await getPortfolioMetadata(portfolioId, false);
    updateIsProjectCreationInProgress(false);
    navigate(`/portfolio/${portfolioId}/subjects`);
  };

  /**
   * @description Generates context for a portfolio
   * @param {Project[]} projects - The projects to generate context for
   * @param {"document" | "input" | "subject"} contextType - The type of context to generate
   * @param {string} inputContext - The input context to generate context from
   * @param {File[]} files - The files to generate context from
   * @param {boolean} rechart - Whether to rechart the references after generating context
   */
  const generateContextForPortfolio = async (
    projects: Project[],
    contextType: "document" | "input" | "subject",
    inputContext: string,
    files: File[],
    rechart: boolean,
  ) => {
    const processType = ProcessType.GENERATE_CONTEXT;
    const portfolioId = currentPortfolioId;
    const portfolioName = currentPortfolio.name;
    const processId = nanoid();
    addProcess({
      id: processId,
      type: processType,
      portfolioId: portfolioId,
    });
    addLoadingGroup(processId, portfolioName, processType);

    try {
      let contextGenerator;
      if (contextType === "document") {
        const file = files[0];
        const response = await uploadFile(file);
        const document_ids = response.data.map((file: any) => file.document_id);
        contextGenerator = (projectId: string) =>
          generatePatentContextFromId(projectId, document_ids[0]);
      } else if (contextType === "input") {
        contextGenerator = (projectId: string) =>
          generatePatentContextFromText(projectId, inputContext);
      } else if (contextType === "subject") {
        contextGenerator = (projectId: string, subjectId: string) =>
          generatePatentContextFromId(projectId, subjectId);
      } else {
        throw new Error("Invalid modal type");
      }

      const idToProjectName = new Map(
        projects.map((project) => [project.id, project.name]),
      );
      const contextPromises = projects.map(async (project) => {
        try {
          let context: PatentContext;
          if (contextType === "subject") {
            addLoadingGroupItem(
              processId,
              processType,
              project.name,
              StatusType.CONTEXT,
            );
            context = (await contextGenerator(project.id, project.subject.id)).data;
          } else {
            context = (await contextGenerator(project.id)).data;
          }
          return { projectId: project.id, success: true };
        } catch (error) {
          console.error(`Error generating context for project ${project.id}:`, error);
          return { projectId: project.id, success: false };
        }
      });

      const results = await Promise.all(contextPromises);

      const successfulIds = results.filter((r) => r.success).map((r) => r.projectId);
      const failedIds = results.filter((r) => !r.success).map((r) => r.projectId);

      successfulIds.forEach((id) => {
        updateLoadingGroupItem(
          processId,
          processType,
          idToProjectName.get(id),
          rechart ? StatusType.CHARTING : StatusType.SUCCESS,
        );
      });

      failedIds.forEach((id) => {
        updateLoadingGroupItem(
          processId,
          processType,
          idToProjectName.get(id),
          StatusType.ERROR,
          "Error generating context.",
        );
      });

      if (rechart) {
        const rechartPromises = successfulIds.map(async (id) => {
          try {
            await rerankReferences(id);
            return { projectId: id, success: true };
          } catch (error) {
            console.error(`Error reprocessing references for project ${id}:`, error);
            return { projectId: id, success: false };
          }
        });
        const rechartResults = await Promise.all(rechartPromises);
        // Replace with reprocessDocuments!

        const successfulRechartIds = rechartResults
          .filter((r) => r.success)
          .map((r) => r.projectId);
        const failedRechartIds = rechartResults
          .filter((r) => !r.success)
          .map((r) => r.projectId);

        successfulRechartIds.forEach((id) => {
          updateLoadingGroupItem(
            processId,
            processType,
            idToProjectName.get(id),
            StatusType.SUCCESS,
          );
        });

        failedRechartIds.forEach((id) => {
          updateLoadingGroupItem(
            processId,
            processType,
            idToProjectName.get(id),
            StatusType.ERROR,
            "Error reprocessing references.",
          );
        });
      }

      return { success: true };
    } catch (error) {
      console.error("Error generating context:", error);
      addErrorMessage("Failed to generate context. Please try again.");
      return { success: false, error };
    } finally {
      removeProcess(processId);
    }
  };

  /**
   * @description Updates tags for multiple references across all projects in a portfolio
   * @param {string[]} referenceIds - The IDs of the references to update
   * @param {string[]} newTags - The new tags to apply
   */
  const updateReferencesTagsInPortfolio = async (
    referenceIds: string[],
    newTags: string[],
  ): Promise<ApiResponse<any>> => {
    try {
      const references = currentPortfolio.references.filter((ref) =>
        referenceIds.includes(ref.id),
      );
      if (references.length === 0) {
        throw new Error("Selected references not found in portfolio");
      }

      // Collect updates per project
      const updatesPerProject: {
        [projectId: string]: { [referenceId: string]: string[] };
      } = {};

      references.forEach((reference) => {
        reference.subjects.forEach((subject) => {
          if (!updatesPerProject[subject.projectId]) {
            updatesPerProject[subject.projectId] = {};
          }
          updatesPerProject[subject.projectId][reference.id] = newTags;
        });
      });

      // Update tags in each project
      const updatePromises = Object.entries(updatesPerProject).map(
        async ([projectId, refs]) => {
          try {
            const response = await updateProjectDetails(projectId, {
              new_tags: refs,
            });
            if (!response.success) {
              throw new Error(response.message);
            }
            return { success: true, projectId };
          } catch (error) {
            console.error(`Error updating tags for project ${projectId}:`, error);
            return { success: false, projectId };
          }
        },
      );

      const results = await Promise.all(updatePromises);
      const failedUpdates = results.filter((r) => !r.success).map((u) => u.projectId);

      if (failedUpdates.length > 0) {
        return {
          success: false,
          message: `Failed to update tags for projects: ${failedUpdates.join(", ")}`,
        };
      }

      // Update the current portfolio's references' tags
      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.map((ref) => {
          if (referenceIds.includes(ref.id)) {
            return { ...ref, tags: newTags };
          }
          return ref;
        }),
      });

      return { success: true };
    } catch (error) {
      return handleError(error, "Error updating reference tags in portfolio");
    }
  };

  const createStandardEssentialPortfolio = async (
    document_ids: string[],
  ): Promise<ApiResponse> => {
    const key = nanoid();
    try {
      addProcess({
        id: key,
        type: ProcessType.CREATE_PORTFOLIO,
      });
      let finalName = projectName;
      if (!finalName) {
        finalName = `Untitled Portfolio`;
      }
      updateSpinnerText(`Creating portfolio...`);

      const res = await postRequest("post_create_portfolio", {
        document_ids: document_ids,
        name: projectName,
        client_number: clientNumber,
        type: ProjectType.SEP,
      });
      const response = res.data.data;

      updateCurrentPortfolioId(response.id);
      updateCurrentPortfolio({
        ...currentPortfolio,
        id: response.id,
        name: projectName,
        type: ProjectType.SEP,
        clientNumber: response.client_number,
        projects: response.projects,
        owner: response.created_by,
        references: [],
      });

      await processPortfolioProjects(response.projects);

      await fetchAndNavigateToPortfolio(response.id);

      return {
        success: true,
        data: { ...response },
      };
    } catch (error) {
      return handleError(error, "Error creating portfolio. Try again later.");
    } finally {
      removeProcess(key);
    }
  };

  const getPortfolioMatrix = async (
    portfolioId: string,
    assertedClaimsOnly: boolean,
  ) => {
    try {
      const response = await getRequest("get_portfolio_matrix", {
        portfolio_id: portfolioId,
        asserted_claims_only: assertedClaimsOnly,
      });
      return response.data;
    } catch (error) {
      return handleError(error, "Error fetching portfolio matrix");
    }
  };

  /**
   * @description Gets the project settings
   * @param {string} project_id - The id of the project to get the settings for
   */
  const getPortfolioSettings = async (portfolio_id: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_portfolio_settings", {
        portfolio_id,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting portfolio settings");
    }
  };

  const deletePortfolio = async (portfolioId: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_portfolio", {
        portfolio_id: portfolioId,
      });
      addSuccessMessage("Portfolio deleted successfully");
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      addErrorMessage("Error deleting portfolio");
      return handleError(error, "Error deleting portfolio");
    }
  };

  return {
    updatePortfolioDetails,
    addUserToPortfolio,
    removeUserFromPortfolio,
    getUsersForPortfolio,
    createPortfolioFromPatentNumbers,
    removeProjectsFromPortfolio,
    getPatentsForAssignee,
    fetchAndNavigateToPortfolio,
    generateContextForPortfolio,
    updateReferencesTagsInPortfolio,
    getPortfolioMatrix,
    createStandardEssentialPortfolio,
    getPortfolioSettings,
    deletePortfolio,
  };
};

export default usePortfolio;
