/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { useProjectStore } from "@/store";
import { ElementType, ProjectType } from "@/types";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";

interface ElementSelectProps {
  onElementSelect: (selectedElements: string[]) => void;
  elementType: ElementType.CLAIM | ElementType.FEATURE | ElementType.EVALUATE;
  className?: string;
}

/**
 * @description Element (function/claim)select to control elements displayed in the table
 * @param {function} onElementSelect - Function to handle the selection of elements.
 * @param {ElementType} elementType - The type of element to select.
 */
function ElementSelect({
  onElementSelect,
  elementType,
  className,
}: ElementSelectProps) {
  const {
    currentProject,
    currentPortfolio,
    showAssertedClaims,
    updateShowAssertedClaims,
  } = useProjectStore();
  const [open, setOpen] = useState(false);
  const [elements, setElements] = useState<string[]>([]);
  const [selectedElements, setSelectedElements] = useState<string[]>([]);
  const [assertedClaims, setAssertedClaims] = useState<string[]>([]);

  const getPopoverWidth = () => {
    if (elements.length === 0) return 200;
    const longestLabel = elements.reduce((a, b) => (a.length > b.length ? a : b));
    return Math.min(500, Math.max(200, longestLabel.length * 8 + 48));
  };

  const popoverWidth = getPopoverWidth();

  useEffect(() => {
    if (elementType === ElementType.CLAIM) {
      const assertedClaimLabels = currentProject?.assertedClaims || [];
      setAssertedClaims(assertedClaimLabels);
      const claimLabels =
        currentProject?.claims?.map((claim) => Object.keys(claim)[0]) || [];

      const availableElements = showAssertedClaims
        ? assertedClaimLabels.filter((label) => claimLabels.includes(label))
        : claimLabels;
      setElements(availableElements);
      setSelectedElements(availableElements);
    } else {
      const featureLabels =
        currentProject?.features?.map((feature) => Object.keys(feature)[0]) || [];
      setElements(featureLabels);
      setSelectedElements(featureLabels);
    }
  }, [currentProject, elementType, showAssertedClaims]);

  const handleElementToggle = (element: string) => {
    setSelectedElements((prev) =>
      prev.includes(element) ? prev.filter((e) => e !== element) : [...prev, element],
    );
  };

  const handleSelectAll = () => {
    setSelectedElements((prev) =>
      prev.length === elements.length ? [] : [...elements],
    );
    if (elementType === ElementType.CLAIM) {
      updateShowAssertedClaims(false);
    }
  };

  const handleSelectAsserted = () => {
    setSelectedElements((prev) =>
      prev.length === assertedClaims.length &&
      JSON.stringify(prev.sort()) === JSON.stringify(assertedClaims.sort())
        ? []
        : [...assertedClaims],
    );
    if (elementType === ElementType.CLAIM) {
      updateShowAssertedClaims(true);
    }
  };

  const handleClose = () => {
    const isSelectAll = selectedElements.length === elements.length;
    let selectedElementsToSend = selectedElements;
    if (isSelectAll) {
      selectedElementsToSend = [];
    }
    onElementSelect(selectedElementsToSend);
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      onOpenChange={(isOpen) => (isOpen ? setOpen(true) : handleClose())}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-[200px] justify-between", className)}
        >
          <div className="flex items-center space-x-1 truncate">
            {elementType === ElementType.CLAIM &&
            showAssertedClaims &&
            assertedClaims.length > 0 ? (
              <span>Asserted claims</span>
            ) : selectedElements.length === elements.length ? (
              <span>
                All{" "}
                {currentPortfolio?.type === ProjectType.SEP
                  ? "sections"
                  : elementType === ElementType.CLAIM
                    ? "claims"
                    : "features"}
              </span>
            ) : elementType === ElementType.CLAIM &&
              selectedElements.length === assertedClaims.length &&
              JSON.stringify(selectedElements.sort()) ===
                JSON.stringify(assertedClaims.sort()) ? (
              <span>Asserted claims</span>
            ) : selectedElements.length > 0 ? (
              <>
                {selectedElements.slice(0, 2).map((element) => (
                  <span
                    key={element}
                    className="text-secondary-foreground px-1 rounded border"
                  >
                    {element}
                  </span>
                ))}
                {selectedElements.length > 2 && (
                  <span className="text-secondary-foreground">
                    +{selectedElements.length - 2} more
                  </span>
                )}
              </>
            ) : (
              <span>Select {elementType}s</span>
            )}
          </div>
          <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: `${popoverWidth}px` }}
        className="p-0"
        align="start"
      >
        <ScrollArea className="h-[300px]">
          <div className="p-1">
            <Button
              variant="ghost"
              className="w-full justify-start font-bold"
              onClick={handleSelectAll}
            >
              <div className="flex items-center">
                {selectedElements.length === elements.length && (
                  <CheckIcon className="mr-2 h-4 w-4" />
                )}
                <span>Select All</span>
              </div>
            </Button>
            {elementType === ElementType.CLAIM && assertedClaims.length > 0 && (
              <Button
                variant="ghost"
                className="w-full justify-start font-bold"
                onClick={handleSelectAsserted}
              >
                <div className="flex items-center">
                  {selectedElements.length === assertedClaims.length &&
                    JSON.stringify(selectedElements.sort()) ===
                      JSON.stringify(assertedClaims.sort()) && (
                      <CheckIcon className="mr-2 h-4 w-4" />
                    )}
                  <span>Asserted claims</span>
                </div>
              </Button>
            )}
            {elements.map((element) => (
              <Button
                key={element}
                variant="ghost"
                className="w-full justify-start"
                onClick={() => handleElementToggle(element)}
              >
                <div className="flex items-center">
                  {(selectedElements.includes(element) ||
                    (elementType === ElementType.CLAIM &&
                      assertedClaims.includes(element) &&
                      selectedElements.length === assertedClaims.length &&
                      JSON.stringify(selectedElements.sort()) ===
                        JSON.stringify(assertedClaims.sort()))) && (
                    <CheckIcon className="mr-2 h-4 w-4" />
                  )}
                  <span>{element}</span>
                </div>
              </Button>
            ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
}

export default ElementSelect;
