import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import CustomInput from "@/components/input/CustomInput";
import { Label } from "@/components/ui/label";
import { LoadingButton } from "@/components/ui/loading-button";
import { useDataTable } from "@/hooks";
import React, { useCallback, useEffect, useState } from "react";

import { useProjectStore } from "@/store";
import { ElementType } from "@/types";
import { convertToUtcDateString } from "@/utils/dateUtils";

interface Variable {
  id: string;
  name: string;
  current: string;
}

interface HeaderFooterEditorProps {
  initialHeader: string;
  initialFooter: string;
  isOpen: boolean;
  onClose: () => void;
  index: number;
  defaultUseFormatForAllElements?: boolean;
  currentElementNumber?: string;
  currentElementLanguage?: string;
}

const HeaderFooterEditor: React.FC<HeaderFooterEditorProps> = ({
  initialHeader,
  initialFooter,
  isOpen,
  onClose,
  index,
  defaultUseFormatForAllElements = false,
  currentElementNumber = "1",
  currentElementLanguage = "Example element language",
}) => {
  const [header, setHeader] = useState(initialHeader);
  const [footer, setFooter] = useState(initialFooter);
  const [useFormatForAllElements, setUseFormatForAllElements] = useState(
    defaultUseFormatForAllElements,
  );
  const { updateInvalidityBoilerplate } = useDataTable();
  const [isSaving, setIsSaving] = useState(false);
  const currentProject = useProjectStore((state) => state.currentProject);
  const selectedElementType = useProjectStore((state) => state.selectedElementType);

  // console.log("currentProject", currentProject);
  // Reset state when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setHeader(initialHeader);
      setFooter(initialFooter);
      setUseFormatForAllElements(defaultUseFormatForAllElements);
    }
  }, [isOpen, initialHeader, initialFooter]);

  const variables: Variable[] = [
    {
      id: "reference_name",
      name: "Reference Name",
      current: "",
    },
    {
      id: "element_number",
      name: "Element Number",
      current: currentElementNumber,
    },
    {
      id: "element_language",
      name: "Element Language",
      current: currentElementLanguage,
    },
    {
      id: "subject_nickname",
      name: "Subject Nickname",
      current: currentProject?.documentsToNicknames[currentProject?.subject?.id] || "",
    },
    {
      id: "subject_priority_date",
      name: "Subject Priority Date",
      current: convertToUtcDateString(currentProject?.subject?.priorityDate) || "",
    },
    {
      id: "subject_number",
      name: "Subject Number",
      current: currentProject?.subject?.number || "",
    },
  ];

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    try {
      // Pass the raw header/footer with variables and let backend handle substitution
      await handleUpdateInvalidityBoilerplate(
        currentProject.id,
        variables.find((v) => v.id === "element_number")?.current,
        selectedElementType === ElementType.FEATURE,
        header,
        footer,
        useFormatForAllElements,
      );
      onClose();
    } catch (error) {
      console.error("Error updating invalidity boilerplate:", error);
    } finally {
      setIsSaving(false);
    }
  }, [
    header,
    footer,
    currentProject.id,
    variables,
    selectedElementType,
    useFormatForAllElements,
    onClose,
  ]);

  const handleUpdateInvalidityBoilerplate = async (
    projectId: string,
    claimNumber: string,
    isFeature: boolean,
    header: string,
    footer: string,
    updateAllElements: boolean,
  ) => {
    await updateInvalidityBoilerplate(
      projectId,
      claimNumber,
      isFeature,
      header,
      footer,
      updateAllElements,
      index,
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>
            Edit {selectedElementType === ElementType.FEATURE ? "Feature" : "Claim"}{" "}
            Header and Footer
          </DialogTitle>
          <DialogDescription>
            The header and footer will be applied to the{" "}
            {selectedElementType === ElementType.FEATURE ? "feature(s)" : "claim(s)"}{" "}
            for all documents in the project.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4 pb-4">
          <div>
            <div className="flex items-center gap-4">
              <Label htmlFor="header" className="mb-0">
                Header
              </Label>
            </div>
            <CustomInput
              id="header"
              label=""
              value={header}
              onChange={setHeader}
              variables={variables}
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-4">
              <Label htmlFor="footer" className="mb-0">
                Footer
              </Label>
            </div>
            <CustomInput
              id="footer"
              label=""
              value={footer}
              onChange={setFooter}
              variables={variables}
            />
          </div>
        </div>
        <DialogFooter>
          <CheckboxWithText
            label={`Use format for all ${
              selectedElementType === ElementType.FEATURE ? "features" : "claims"
            }`}
            checked={useFormatForAllElements}
            onCheckedChange={(checked) =>
              setUseFormatForAllElements(checked as boolean)
            }
          />
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton loading={isSaving} type="button" onClick={handleSave}>
            Save changes
          </LoadingButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default HeaderFooterEditor;
