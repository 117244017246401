/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { InfoPopover } from "@/components";
import VariableInput from "@/components/input/VariableInput";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LoadingButton } from "@/components/ui/loading-button";
import { RadioGroup, RadioGroupItemWithText } from "@/components/ui/radio-group";
import { usePortfolio, useProject } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { FigurePlacement, ParentType } from "@/types";
import { Loader2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import SettingsSection from "./SettingsSection";

interface InvaliditySettingsModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Share project modal
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {string} projectTitle - The title of the project.
 */
const InvaliditySettingsModal: React.FC<InvaliditySettingsModalProps> = ({
  open,
  handleClose,
}) => {
  const { updateProjectSettings, getProjectSettings, getProjectClaims } = useProject();
  const { getPortfolioSettings } = usePortfolio();

  // Global state from store
  const { currentProjectId, currentPortfolioId, currentParent } = useProjectStore();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();

  // Local state set up
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const [introductionLanguage, setIntroductionLanguage] = useState("");
  const [isCitationBefore, setIsCitationBefore] = useState(true);
  const [patentCitationFormat, setPatentCitationFormat] = useState("");
  const [applicationCitationFormat, setApplicationCitationFormat] = useState("");
  const [documentCitationFormat, setDocumentCitationFormat] = useState("");
  const [foreignDocumentCitationFormat, setForeignDocumentCitationFormat] =
    useState("");
  const [figurePlacement, setFigurePlacement] = useState(FigurePlacement.GROUPED);
  const [header, setHeader] = useState("");
  const [footer, setFooter] = useState("");
  const [defaultToAssertedClaims, setDefaultToAssertedClaims] = useState(false);
  const [titleFormat, setTitleFormat] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [collapsedClaimDepth, setCollapsedClaimDepth] = useState(0);
  const [originalClaimDepth, setOriginalClaimDepth] = useState(0);

  // Add useEffect to fetch settings when modal opens
  useEffect(() => {
    const fetchSettings = async () => {
      if (currentProjectId || currentPortfolioId) {
        setIsLoading(true);
        let response;
        if (currentParent === ParentType.PROJECT) {
          response = await getProjectSettings(currentProjectId);
        } else {
          response = await getPortfolioSettings(currentPortfolioId);
        }
        if (response.success && response.data) {
          const settings = response.data;
          // Update all the state values with the fetched settings
          setHeader(settings.header_format || "");
          setFooter(settings.footer_format || "");
          setPatentCitationFormat(settings.us_patent_citation_format || "");
          setForeignDocumentCitationFormat(
            settings.non_us_patent_citation_format || "",
          );
          setApplicationCitationFormat(settings.us_application_citation_format || "");
          setDocumentCitationFormat(settings.uploaded_document_citation_format || "");
          setDefaultToAssertedClaims(settings.default_to_asserted_claims || false);
          setIsCitationBefore(settings.is_citation_before || true);
          setFigurePlacement(settings.figure_placement || FigurePlacement.GROUPED);
          setIntroductionLanguage(settings.word_introduction_language || "");
          setSelectedColors(settings.citation_colors || []);
          setTitleFormat(settings.word_claim_chart_title_format || "");
          const depth = settings.collapsed_claim_depth || 0;
          setCollapsedClaimDepth(depth);
          setOriginalClaimDepth(depth);
        }
        setIsLoading(false);
      }
    };

    if (open) {
      fetchSettings();
    }
  }, [open]); // Only depends on 'open' prop

  const handleSave = async () => {
    setIsSaving(true);
    const settings = Object.fromEntries(
      Object.entries({
        headerFormat: header,
        footerFormat: footer,
        usPatentCitationFormat: patentCitationFormat,
        nonUsPatentCitationFormat: foreignDocumentCitationFormat,
        usApplicationCitationFormat: applicationCitationFormat,
        uploadedDocumentCitationFormat: documentCitationFormat,
        defaultToAssertedClaims: defaultToAssertedClaims,
        isCitationBefore: isCitationBefore,
        figurePlacement: figurePlacement,
        wordIntroductionLanguage: introductionLanguage,
        citationColors: selectedColors,
        wordClaimChartTitleFormat: titleFormat,
        collapsedClaimDepth: collapsedClaimDepth,
      }).filter(([_, value]) => value !== null && value !== ""),
    );

    const response = await updateProjectSettings(
      currentProjectId,
      currentPortfolioId,
      settings,
    );

    if (response.success) {
      addSuccessMessage("Settings saved successfully");

      if (currentProjectId && collapsedClaimDepth !== originalClaimDepth) {
        await getProjectClaims(currentProjectId, collapsedClaimDepth > 0, true); // get collapsed claims if depth > 0
      }

      handleClose();
    } else {
      addErrorMessage(response.error);
    }
    setIsSaving(false);
  };

  const collapseClaimDepthContent = (
    <div>
      <p className="text-sm">
        The depth of claims to combine in the invalidity cells. For example, if the
        subject has claims ["1", "1.1", "1.1.1", "1.2", "2", "3"], and depth is 1, then
        all sub-claims will be combined into the 1st level, so the claims will be
        displayed as ["1", "2", "3"]. If the depth is 2, the claims will be displayed as
        ["1", "1.1", "1.2", "2", "3"]. If no depth is specified, all claims will be
        displayed individually.
      </p>
    </div>
  );

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[1200px] flex flex-col max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Project settings</DialogTitle>
        </DialogHeader>

        {isLoading ? (
          <div className="flex-1 flex items-center justify-center">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <div className="grid gap-4 py-2 overflow-y-scroll flex-1 always-show-scrollbar !pr-2">
            {/* Claims Section */}
            <SettingsSection
              label="Claim settings"
              description="Default claims and collapsed claim depth displayed in charts and exports"
            >
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label className="text-sm">Default claims</Label>
                  <RadioGroup
                    value={defaultToAssertedClaims ? "asserted" : "all"}
                    onValueChange={(value) =>
                      setDefaultToAssertedClaims(value === "asserted")
                    }
                    className="flex gap-4"
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItemWithText
                        value="asserted"
                        id="asserted"
                        label="Asserted"
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItemWithText value="all" id="all" label="All" />
                    </div>
                  </RadioGroup>
                </div>
                <div>
                  <div className="flex flex-row gap-2">
                    <Label className="text-sm">Maximum claim depth</Label>
                    <InfoPopover content={collapseClaimDepthContent} />
                  </div>
                  <div className="flex gap-2">
                    <Input
                      type="number"
                      min={0}
                      value={collapsedClaimDepth}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Only update if it's a valid non-negative integer
                        if (/^\d*$/.test(value)) {
                          setCollapsedClaimDepth(parseInt(value));
                        }
                      }}
                      className="w-[100px] h-9"
                    />
                    {collapsedClaimDepth > 0 && (
                      <Button
                        variant="outline"
                        onClick={() => setCollapsedClaimDepth(0)}
                        className="h-9"
                      >
                        Clear
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </SettingsSection>

            {/* Invalidity Cell Boilerplate Section */}
            <SettingsSection
              label="Invalidity cell boilerplate"
              description="Language displayed as the header and footer of each invalidity cell in app and exports"
            >
              <div className="grid grid-cols-2 gap-4">
                <VariableInput
                  label="Header"
                  value={header}
                  onChange={setHeader}
                  type="header_footer"
                  placeholder="e.g., {{element_number}}"
                />
                <VariableInput
                  label="Footer"
                  value={footer}
                  onChange={setFooter}
                  type="header_footer"
                  placeholder="e.g., {{element_number}}"
                />
              </div>
            </SettingsSection>

            <SettingsSection
              label="Word invalidity claim chart title"
              description="Format for the title displayed at the top of the invalidity claim chart documents"
            >
              <div className="space-y-2">
                <VariableInput
                  label=""
                  value={titleFormat}
                  onChange={setTitleFormat}
                  type="title"
                  placeholder='e.g., Invalidity of {{subject_number}} ("{{nickname}}")\nin view of {{reference_number}}'
                />
              </div>
            </SettingsSection>

            {/* Introduction Language Section */}
            <SettingsSection
              label="Word export introduction"
              description="Language displayed at the beginning of the Word export above the claim charts"
            >
              <VariableInput
                label=""
                value={introductionLanguage}
                onChange={setIntroductionLanguage}
                type="introduction"
              />
            </SettingsSection>

            {/* Citation Settings Section */}
            <SettingsSection
              label="Citation export settings"
              description="Citation position and format in the exported charts"
            >
              <div className="space-y-4">
                <div>
                  <Label className="text-sm">Position</Label>
                  <RadioGroup
                    value={isCitationBefore ? "start" : "end"}
                    onValueChange={(value) => setIsCitationBefore(value === "start")}
                    className="flex gap-4 mt-2"
                  >
                    <RadioGroupItemWithText
                      value="start"
                      id="start"
                      label="Before language"
                    />
                    <RadioGroupItemWithText
                      value="end"
                      id="end"
                      label="After language"
                    />
                  </RadioGroup>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {/* Citation format inputs */}
                  <VariableInput
                    label="US Patent Format"
                    value={patentCitationFormat}
                    onChange={setPatentCitationFormat}
                    type="citation"
                    placeholder="e.g., {{nickname}} at {{column}}:{{lines}}"
                  />

                  <VariableInput
                    label="US Patent Application Format"
                    value={applicationCitationFormat}
                    onChange={setApplicationCitationFormat}
                    type="citation"
                    placeholder="e.g., {{nickname}} at [{{paragraph}}]"
                  />

                  <VariableInput
                    label="Uploaded Document Format"
                    value={documentCitationFormat}
                    onChange={setDocumentCitationFormat}
                    type="citation"
                    placeholder="e.g., {{nickname}} at p. {{pages}}"
                  />

                  <VariableInput
                    label="Non-US Patent Format"
                    value={foreignDocumentCitationFormat}
                    onChange={setForeignDocumentCitationFormat}
                    type="citation"
                    placeholder="e.g., {{nickname}} at [{{paragraph}}]"
                  />
                </div>
              </div>
            </SettingsSection>

            {/* Figure Settings Section */}
            <SettingsSection
              label="Figure export settings"
              description="Figure position in the exported charts"
            >
              <div>
                <Label className="text-sm">Position</Label>
                <RadioGroup
                  value={figurePlacement}
                  onValueChange={(value) =>
                    setFigurePlacement(value as FigurePlacement)
                  }
                  className="flex gap-4"
                >
                  {/* <RadioGroupItemWithText
                    value={FigurePlacement.EMBEDDED}
                    id={FigurePlacement.EMBEDDED}
                    label="Embedded with each citation"
                  /> */}
                  <RadioGroupItemWithText
                    value={FigurePlacement.GROUPED}
                    id={FigurePlacement.GROUPED}
                    label="Grouped for all element citations"
                  />
                  <RadioGroupItemWithText
                    value={FigurePlacement.OMITTED}
                    id={FigurePlacement.OMITTED}
                    label="Omitted from export"
                  />
                </RadioGroup>
              </div>
            </SettingsSection>
          </div>
        )}

        <DialogFooter className="mt-2 pt-2">
          <div className="flex flex-row justify-between gap-3">
            <div className="flex items-center gap-2">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton onClick={handleSave} loading={isSaving}>
                {isSaving ? "Saving..." : "Save Changes"}
              </LoadingButton>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InvaliditySettingsModal;
