/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import Loader from "@/components/info/Loader";
import PatentNumbersTextField from "@/components/patents/PatentNumbersTextField";
import { CheckboxWithText } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useProcessReferences } from "@/hooks";
import useProcess from "@/hooks/useProcess";
import { useAppStateStore, useProjectStore } from "@/store";
import { ParentType } from "@/types";
import React, { useEffect, useState } from "react";
import KindCodeSelect from "./KindCodeSelect";

interface AddReferenceByNumberModalProps {
  open: boolean;
  handleClose: () => void;
  general?: boolean;
  isPortfolio?: boolean;
}

/**
 * @description Modal for adding references by number
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 * @param {boolean} general - Whether the modal is for general references
 * @param {boolean} isPortfolio - Whether the modal is for a portfolio
 */
const AddReferenceByNumberModal: React.FC<AddReferenceByNumberModalProps> = ({
  open,
  handleClose,
  general = false,
  isPortfolio = false,
}) => {
  const { currentSubject } = useProjectStore();
  const { inferKindCode } = useProcessReferences();
  const {
    currentProject,
    currentProjectId,
    currentParent,
    currentPortfolioId,
    currentPortfolio,
  } = useProjectStore();
  const { processDocuments } = useProcess();
  const { addWarningMessage } = useAppStateStore();

  // Modal state
  const [isChartCheckboxChecked, setIsChartCheckboxChecked] = useState<boolean>(true);
  const [useClaimsCheckboxChecked, setUseClaimsCheckboxChecked] =
    useState<boolean>(true);

  const [isFetchingKindCodes, setIsFetchingKindCodes] = useState(false);
  // patents numbers with one or zero choices for kind code
  const [kindCodesNoChoices, setKindCodesNoChoices] = useState<
    Record<string, string[]>
  >({});
  // patents numbers with multiple choices for kind code
  const [kindCodesMultipleChoices, setKindCodesMultipleChoices] = useState<
    Record<string, string[]>
  >({});
  const [selectedKindCodes, setSelectedKindCodes] = useState<Record<string, string>>(
    {},
  );

  const [isSelectingKindCodes, setIsSelectingKindCodes] = useState(false);

  // Add state to track when we're ready to process
  const [readyToProcess, setReadyToProcess] = useState(false);

  // Reset the modal state on close
  const handleResetModal = () => {
    setIsChartCheckboxChecked(true);
    setKindCodesNoChoices({});
    setKindCodesMultipleChoices({});
    setSelectedKindCodes({});
    setIsSelectingKindCodes(false);
    handleClose();
  };

  const handleAddClick = async (refNums: string[]) => {
    if (refNums.length > 0) {
      let filteredReferenceNumbers = refNums;
      let existingReferences: string[] = [];
      let matchingSubjectReferences: string[] = [];
      let currentReferences = isPortfolio
        ? currentPortfolio.references
        : currentProject.references;

      if (currentReferences.length > 0) {
        const existingReferenceNumbers = currentReferences.map(
          (ref) => ref.number || ref.reference_number,
        );
        // Filter out the reference numbers that are already in the project
        filteredReferenceNumbers = refNums.filter((number) => {
          if (existingReferenceNumbers.includes(number)) {
            existingReferences.push(number);
            return false;
          }
          if (!isPortfolio && number === currentSubject.number) {
            matchingSubjectReferences.push(number);
            return false;
          }
          return true;
        });

        if (existingReferences.length > 0) {
          // Show warning messages for existing references
          addWarningMessage(
            `The following references already exist in the project: ${existingReferences.join(
              ", ",
            )}`,
          );
        }
        if (matchingSubjectReferences.length > 0) {
          // Show warning messages for references matching the current subject
          addWarningMessage(
            `The following references already exist in the project as the subject: ${matchingSubjectReferences.join(
              ", ",
            )}`,
          );
        }

        // If there are no new reference numbers to add, return
        if (filteredReferenceNumbers.length === 0) {
          return;
        }
      }
      //fetch the kind codes
      await (async () => {
        setIsFetchingKindCodes(true);
        try {
          const response = await inferKindCode(filteredReferenceNumbers);
          const kind_codes: Record<string, string[]> = await response.data;

          // Categorize patent numbers based on number of kind code choices
          const noChoices: Record<string, string[]> = {};
          const multipleChoices: Record<string, string[]> = {};

          Object.entries(kind_codes).forEach(([patentNumber, kindCodeList]) => {
            if (kindCodeList.length <= 1) {
              noChoices[patentNumber] = kindCodeList;
            } else {
              multipleChoices[patentNumber] = kindCodeList;
            }
          });

          setKindCodesNoChoices(noChoices);
          setKindCodesMultipleChoices(multipleChoices);

          // If there are multiple choices, show the selection modal
          if (Object.keys(multipleChoices).length > 0) {
            setIsSelectingKindCodes(true);
          } else {
            // Signal that we're ready to process
            setReadyToProcess(true);
          }

          // default the selected kind code the the first choice
          const defaults: Record<string, string> = {};
          Object.entries(multipleChoices).forEach(([patentNumber, kindCodeList]) => {
            defaults[patentNumber] = kindCodeList[0];
          });
          setSelectedKindCodes(defaults);
        } finally {
          setIsFetchingKindCodes(false);
        }
      })();
    }
  };

  const handleConfirmChoices = async () => {
    setIsSelectingKindCodes(false);
    setReadyToProcess(true);
  };

  const handleProcessReferences = async () => {
    setIsSelectingKindCodes(false);
    handleClose();

    const refNums: string[] = [];

    // Add references with no/single choices
    refNums.push(
      ...Object.entries(kindCodesNoChoices).map(([patentNumber, kindCodes]) => {
        return kindCodes.length > 0 ? `${patentNumber}${kindCodes[0]}` : patentNumber;
      }),
    );

    // Add references with multiple choices using the selected kind codes
    refNums.push(
      ...Object.entries(kindCodesMultipleChoices).map(
        ([patentNumber]) => `${patentNumber}${selectedKindCodes[patentNumber]}`,
      ),
    );

    if (isPortfolio) {
      await processDocuments(
        currentPortfolioId,
        "",
        refNums,
        [],
        true,
        useClaimsCheckboxChecked,
      );
    } else {
      await processDocuments(
        "", // portfolioId
        currentProjectId,
        refNums,
        [],
        true,
        useClaimsCheckboxChecked,
      );
    }
    setIsChartCheckboxChecked(true);
    setUseClaimsCheckboxChecked(true);
  };

  // Process references when ready and have all necessary state
  useEffect(() => {
    if (readyToProcess && !isSelectingKindCodes) {
      handleProcessReferences();
      setReadyToProcess(false);
    }
  }, [readyToProcess, isSelectingKindCodes]);

  return (
    <Dialog open={open} onOpenChange={handleResetModal}>
      <DialogContent
        className="max-w-2xl"
        onPointerDownOutside={(e) => {
          if (isSelectingKindCodes) {
            e.preventDefault();
          }
        }}
      >
        {isFetchingKindCodes ? (
          <div className="flex items-center justify-center py-8">
            <Loader />
          </div>
        ) : isSelectingKindCodes ? (
          <KindCodeSelect
            kindCodes={kindCodesMultipleChoices}
            selectedKindCodes={selectedKindCodes}
            setSelectedKindCodes={setSelectedKindCodes}
            handleConfirm={handleConfirmChoices}
          />
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>
                {isPortfolio ? "Add references to all subjects" : "Add by number"}
              </DialogTitle>
              <DialogDescription>
                Add references by entering a comma-separated list of published patents
                and applications numbers. A country prefix is required.
              </DialogDescription>
            </DialogHeader>

            {!general && (
              <div>
                {!isPortfolio && (
                  <CheckboxWithText
                    id="complete-charts"
                    checked={isChartCheckboxChecked}
                    onCheckedChange={(checked) =>
                      setIsChartCheckboxChecked(checked as boolean)
                    }
                    label="Complete claim and feature chart(s)"
                  />
                )}
                <CheckboxWithText
                  id="with-claims"
                  checked={useClaimsCheckboxChecked}
                  onCheckedChange={(checked) =>
                    setUseClaimsCheckboxChecked(checked as boolean)
                  }
                  label="Include claims in charting"
                />
              </div>
            )}

            <PatentNumbersTextField
              isPortfolio={currentParent === ParentType.PORTFOLIO || isPortfolio}
              handleAdd={handleAddClick}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddReferenceByNumberModal;
