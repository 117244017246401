/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks";
import { useClearFeedbackDataByID } from "@/hooks/useFeedbackData";
import { useAppStateStore, useProjectStore } from "@/store";
import { ApiResponse, Project } from "@/types";
import { nanoid } from "nanoid";
const useReferences = () => {
  const { postRequest, handleError } = useApi();
  const {
    updateCurrentProject,
    removeSelectedReferences,
    updateCurrentPortfolio,
    selectedReferences,
    currentProject,
    currentPortfolio,
    currentProjectId,
    currentPortfolioId,
  } = useProjectStore();
  const {
    addLoadingMessage,
    removeLoadingMessage,
    addSuccessMessage,
    addErrorMessage,
  } = useAppStateStore();
  const clearFeedbackChartDataForReferences = useClearFeedbackDataByID();

  /**
   * @description Deletes a reference from a project
   * @param {string} projectId - The id of the project to delete the references from (undefined if deleting from portfolio references page)
   * @param {string} portfolioId - The id of the portfolio to delete the references from (undefined if parent is not portfolio)
   * @param {string[]} referenceIds - The ids of the references to delete
   * @param {boolean} isPortfolio - Whether the references are being deleted from a portfolio
   */
  const deleteReferences = async (
    projectId: string | null,
    portfolioId: string | null,
    referenceIdsToDelete: string[],
    isPortfolio: boolean,
  ): Promise<ApiResponse> => {
    const key = nanoid();
    // Store original states
    const originalProject = currentProject ? { ...currentProject } : null;
    const originalPortfolio = currentPortfolio ? { ...currentPortfolio } : null;

    try {
      const message = `Removing ${referenceIdsToDelete.length} reference(s) from ${
        isPortfolio ? "portfolio" : "project"
      }`;
      addLoadingMessage(message, key);

      // Update project store
      if (currentProjectId === projectId) {
        await updateProjectStoreOnDeleteReferences(referenceIdsToDelete);
      }

      // Update portfolio store
      if (currentPortfolioId === portfolioId) {
        await updatePortfolioStoreOnDeleteReferences(referenceIdsToDelete, projectId);
      }

      const response = await postRequest("delete_references_from_project", {
        project_id: projectId,
        portfolio_id: portfolioId,
        reference_ids: referenceIdsToDelete,
      });

      // Remove reference from selected references if it is selected
      if (selectedReferences !== null) {
        removeSelectedReferences(referenceIdsToDelete);
      }

      clearFeedbackChartDataForReferences(projectId, referenceIdsToDelete);

      removeLoadingMessage(key);
      addSuccessMessage(
        `Successfully deleted ${referenceIdsToDelete.length} reference(s).`,
      );

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      console.error("Error details:", error);

      removeLoadingMessage(key);

      // Revert store changes on error
      revertStoreOnDeleteReferencesError(
        originalProject,
        originalPortfolio,
        projectId,
        portfolioId,
      );

      addErrorMessage(
        "An unexpected error occurred while deleting references. Please try again.",
      );
      return handleError(error, "Error deleting reference from project");
    }
  };

  // Updates the project store on delete references
  const updateProjectStoreOnDeleteReferences = async (
    referenceIdsToDelete: string[],
  ) => {
    const newReferenceIds = currentProject.referenceIds.filter(
      (id) => !referenceIdsToDelete.includes(id),
    );
    const newReferences = currentProject.references.filter(
      (ref) => !referenceIdsToDelete.includes(ref.id),
    );
    const newDocumentIds = currentProject.documentIds.filter(
      (id) => !referenceIdsToDelete.includes(id),
    );
    const newDocumentsToNumbers = Object.entries(
      currentProject.documentsToNumbers || {},
    )
      .filter(([id]) => !referenceIdsToDelete.includes(id))
      .reduce((acc, [id, num]) => ({ ...acc, [id]: num }), {});

    const newDocumentsToNicknames = Object.entries(
      currentProject.documentsToNicknames || {},
    )
      .filter(([id]) => !referenceIdsToDelete.includes(id))
      .reduce((acc, [id, nickname]) => ({ ...acc, [id]: nickname }), {});

    const newSummaries = Object.entries(currentProject.summaries || {})
      .filter(([id]) => !referenceIdsToDelete.includes(id))
      .reduce((acc, [id, summary]) => ({ ...acc, [id]: summary }), {});

    updateCurrentProject({
      id: currentProject.id,
      name: currentProject.name,
      referenceIds: newReferenceIds,
      references: newReferences,
      documentIds: newDocumentIds,
      documentsToNumbers: newDocumentsToNumbers,
      documentsToNicknames: newDocumentsToNicknames,
      summaries: newSummaries,
    });
  };

  // Updates the portfolio store on delete references
  const updatePortfolioStoreOnDeleteReferences = async (
    referenceIdsToDelete: string[],
    projectId: string, // deleting reference from sub-project id
  ) => {
    const newPortfolioReferences = currentPortfolio.references
      .map((ref) => {
        if (referenceIdsToDelete.includes(ref.id)) {
          // Remove the current project from the subjects list
          return {
            ...ref,
            subjects: ref.subjects.filter(
              (subject) => subject.project_id !== projectId,
            ),
          };
        }
        return ref;
      })
      .filter((ref) => ref.subjects.length > 0); // Only keep references that still have subjects
    const newPortfolioDocumentsToNicknames = Object.entries(
      currentPortfolio.documentsToNicknames || {},
    )
      .filter(([id]) => !referenceIdsToDelete.includes(id))
      .reduce((acc, [id, nickname]) => ({ ...acc, [id]: nickname }), {});

    const newPortfolioSummaries = Object.entries(currentPortfolio.summaries || {})
      .filter(([id]) => !referenceIdsToDelete.includes(id))
      .reduce((acc, [id, summary]) => ({ ...acc, [id]: summary }), {});

    updateCurrentPortfolio({
      ...currentPortfolio,
      references: newPortfolioReferences,
      documentsToNicknames: newPortfolioDocumentsToNicknames,
      summaries: newPortfolioSummaries,
    });
  };

  const revertStoreOnDeleteReferencesError = (
    originalProject: Project,
    originalPortfolio: Project,
    projectId: string,
    portfolioId: string,
  ) => {
    if (originalProject && currentProjectId === projectId) {
      updateCurrentProject(originalProject);
    }
    if (originalPortfolio && currentPortfolioId === portfolioId) {
      updateCurrentPortfolio(originalPortfolio);
    }
  };

  return { deleteReferences };
};

export default useReferences;
