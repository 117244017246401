/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage } from "@/components";
import DocumentViewer from "@/components/patents/DocumentViewer";
import { Button } from "@/components/ui/button";
import { LoadingButton } from "@/components/ui/loading-button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { H4 } from "@/components/ui/typography";
import { useProcessReferences, useVector, useViz } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ParentType } from "@/types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DocumentSummary from "./components/DocumentSummary";
import { Section112Table } from "./components/Section112Table";

const CoverPleadingPage: React.FC = () => {
  const { currentProject } = useProjectStore();
  const { analyzeSection112 } = useVector();
  const { getProjectSummaryExport } = useViz();
  useViz();
  const { isLoading, isReferencesLoading } = useAppStateStore();
  const { generateSummaries } = useProcessReferences();
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [selectedView, setSelectedView] = useState<"references" | "invalidity">(
    "references",
  );
  const [showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false);
  const [documentViewerDocument, setDocumentViewerDocument] = useState<any>(null);
  const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
    string | null
  >(null);
  const [isGeneratingSummaries, setIsGeneratingSummaries] = useState<boolean>(false);
  const [isAnalyzingSection112, setIsAnalyzingSection112] = useState(false);

  const navigate = useNavigate();

  // Page name
  const pageName = currentProject.name ? `${currentProject.name} - Summary` : "Summary";

  const handleDownloadCoverPleading = async () => {
    setDownloadIsLoading(true);
    await getProjectSummaryExport(currentProject.id);
    setDownloadIsLoading(false);
  };

  const handleGenerateSummaries = async () => {
    setIsGeneratingSummaries(true);
    const allIds = [
      ...currentProject.references.map((r) => r.id),
      currentProject.subject.id,
    ].filter(Boolean) as string[];
    await generateSummaries(allIds, currentProject.id, ParentType.PROJECT, 0.0);
    setIsGeneratingSummaries(false);
  };

  const handleAnalyzeSection112 = async () => {
    setIsAnalyzingSection112(true);
    await analyzeSection112(currentProject.id);
    setIsAnalyzingSection112(false);
  };

  const section112Types = [
    { type: "indefiniteness", claims: currentProject.indefiniteness },
    { type: "enablement", claims: currentProject.enablement },
    { type: "written_description", claims: currentProject.writtenDescription },
  ] as const;

  const allSection112TablesEmpty = section112Types.every(
    ({ claims }) => !claims || claims.length === 0,
  );

  return (
    <ProjectPage pageName={pageName}>
      {isLoading || isReferencesLoading ? (
        <div className="mt-10">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col">
          {currentProject.references && currentProject.references.length > 0 ? (
            <>
              <div className="border-b p-2 flex justify-between items-center">
                {/* <div className="flex items-center gap-4">
                  <Select
                    value={selectedView}
                    onValueChange={(value: "references" | "invalidity") =>
                      setSelectedView(value)
                    }
                  >
                    <SelectTrigger className="w-[200px] h-9">
                      <SelectValue placeholder="Select view" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="references">Reference Summaries</SelectItem>
                      <SelectItem value="invalidity">Invalidity Under § 112</SelectItem>
                    </SelectContent>
                  </Select>
                </div> */}

                <div className="flex gap-2">
                  {selectedView === "invalidity" && (
                    <LoadingButton
                      className="h-9"
                      variant="outline"
                      onClick={() => handleAnalyzeSection112()}
                      loading={isAnalyzingSection112}
                    >
                      {isAnalyzingSection112 ? "Analyzing..." : "Analyze"}
                    </LoadingButton>
                  )}
                  {selectedView === "references" && (
                    <LoadingButton
                      className="h-9"
                      variant="outline"
                      onClick={() => handleGenerateSummaries()}
                      loading={isGeneratingSummaries}
                    >
                      {isGeneratingSummaries ? "Generating..." : "Generate Summaries"}
                    </LoadingButton>
                  )}
                  {currentProject.summaries &&
                    currentProject.indefiniteness &&
                    currentProject.enablement &&
                    currentProject.writtenDescription && (
                      <LoadingButton
                        className="h-9"
                        onClick={() => handleDownloadCoverPleading()}
                        loading={downloadIsLoading}
                      >
                        {downloadIsLoading ? "Downloading..." : "Download"}
                      </LoadingButton>
                    )}
                </div>
              </div>

              <div className="flex-1">
                <ScrollArea className="h-[calc(100vh-105px)]">
                  {selectedView === "references" ? (
                    isGeneratingSummaries ? (
                      <div className="flex justify-center items-center h-full">
                        <Loader />
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 gap-2 p-2">
                        {Object.entries(currentProject.summaries || {}).map(
                          ([id, summary]) => (
                            <DocumentSummary
                              key={id}
                              documentId={id}
                              summary={summary}
                              parentType={ParentType.PROJECT}
                            />
                          ),
                        )}
                      </div>
                    )
                  ) : (
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-2 items-start">
                      {!allSection112TablesEmpty &&
                        section112Types.map(({ type, claims }) => (
                          <Section112Table key={type} type={type} claims={claims} />
                        ))}
                    </div>
                  )}
                </ScrollArea>
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center flex-col mt-12">
              <H4>This project has no references.</H4>
              <Button
                className="mt-3"
                variant="outline"
                onClick={() => navigate(`/project/${currentProject.id}/references`)}
              >
                Add references
              </Button>
            </div>
          )}
        </div>
      )}
      <DocumentViewer
        open={showDocumentViewer}
        handleClose={() => {
          setShowDocumentViewer(false);
          setDocumentViewerDocument(null);
          setDocumentViewerCitationText(null);
        }}
        referenceId={documentViewerDocument?.id}
        citationText={documentViewerCitationText || ""}
        startInChartMode={false}
      />
    </ProjectPage>
  );
};

export default CoverPleadingPage;
