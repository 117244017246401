/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore } from "@/store";
import { ApiResponse, ParentType } from "@/types";
import { useApi, useLlm } from ".";

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProcessReferences = () => {
  const {
    currentProjectId,
    currentPortfolioId,
    currentProject,
    currentPortfolio,
    updateCurrentProject,
    updateCurrentPortfolio,
  } = useProjectStore();

  const { getRequest, handleError } = useApi();

  const { generateReferenceSummaries } = useLlm();

  /**
   * @description Infer the kind code of a patent number
   * @param {array} patentNumbers - The patent numbers without a kind code
   */
  const inferKindCode = async (patentNumbers: string[]): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_infer_kind_code", {
        patent_numbers: patentNumbers,
      });
      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error inferring kind code");
    }
  };

  const generateSummaries = async (
    referenceIds: string[],
    id: string,
    parent: ParentType,
    temperature: number,
  ) => {
    try {
      // Process references in chunks of 10
      for (let i = 0; i < referenceIds.length; i += 10) {
        const chunk = referenceIds.slice(i, i + 10);

        const summariesWithCitations = await generateReferenceSummaries(
          chunk,
          id,
          parent,
          temperature,
        );

        if (parent === ParentType.PROJECT && id === currentProjectId) {
          const updatedProject = {
            id,
            name: currentProject.name,
            summaries: {
              ...currentProject?.summaries,
              ...summariesWithCitations.data,
            },
          };
          updateCurrentProject(updatedProject);
        } else if (parent === ParentType.PORTFOLIO && id === currentPortfolioId) {
          const updatedPortfolio = {
            id,
            name: currentPortfolio.name,
            summaries: {
              ...currentPortfolio?.summaries,
              ...summariesWithCitations.data,
            },
            documentsToNicknames: currentPortfolio.documentsToNicknames,
            references: currentPortfolio.references,
            projects: currentPortfolio.projects,
            owner: currentPortfolio.owner,
            type: currentPortfolio.type,
          };
          updateCurrentPortfolio(updatedPortfolio);
        }
      }
    } catch (error) {
      console.error("Error generating reference summaries:", error);
    }
  };

  return {
    generateSummaries,
    inferKindCode,
  };
};

export default useProcessReferences;
