/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, SidebarPage } from "@/components";
import { H4 } from "@/components/ui/typography";
import { useViz } from "@/hooks";
import { useProjectStore } from "@/store";
import { Reference } from "@/types";
import { useAuthInfo } from "@propelauth/react";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import FilesTable from "./components/FilesTable";

/**
 * @description User files page for uploaded files associated with the user
 */
const UserFilesPage: React.FC = () => {
  const user = useAuthInfo();
  const { currentProject } = useProjectStore();
  const { getUserFiles } = useViz();

  const [references, setReferences] = useState<Reference[]>([]);
  const [selectedReferences, setSelectedReferences] = useState<Reference[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [officeActions, setOfficeActions] = useState<Reference[]>([]);

  // Create a memoized debounced version of fetchUserFiles
  const debouncedFetchUserFiles = useCallback(
    debounce(async () => {
      setIsLoading(true);
      const response = await getUserFiles();

      if (response.success) {
        // Separate office actions from regular references
        const { officeActions: oas, references: refs } = response.data.reduce(
          (
            acc: { officeActions: Reference[]; references: Reference[] },
            item: Reference,
          ) => {
            const isOfficeAction = "primaryExaminer" in item;

            if (isOfficeAction) {
              if ("notificationDate" in item) {
                const formattedDate = new Date(
                  item.notificationDate as string,
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                });
                acc.officeActions.push({
                  ...item,
                  name: `Office Action - ${formattedDate}`,
                });
              } else {
                acc.officeActions.push({
                  ...item,
                  name: "Office Action",
                });
              }
            } else {
              acc.references.push(item);
            }

            return acc;
          },
          { officeActions: [], references: [] },
        );

        setOfficeActions(oas);
        setReferences(refs);
      }
      setIsLoading(false);
      setHasLoaded(true);
    }, 300),
    [getUserFiles, currentProject?.applicationDocuments],
  );

  useEffect(() => {
    if (!references.length && !hasLoaded) {
      debouncedFetchUserFiles();
    }

    // Cleanup function to cancel any pending debounced calls
    return () => {
      debouncedFetchUserFiles.cancel();
    };
  }, [user, debouncedFetchUserFiles]);

  const handleRowSelection = (selectedRows: Reference[]) => {
    setSelectedReferences(selectedRows);
  };

  return (
    <SidebarPage pageName="My Files">
      {isLoading ? (
        <Loader />
      ) : references?.length > 0 || officeActions?.length > 0 ? (
        <div className="pt-2 mb-2">
          <FilesTable
            references={[...references, ...officeActions]}
            height="calc(100vh - 60px)"
            onRowSelection={handleRowSelection}
          />
        </div>
      ) : (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>You have no uploaded files.</H4>
          <p>Files you upload to projects or portfolios will appear here.</p>
        </div>
      )}
    </SidebarPage>
  );
};

export default UserFilesPage;
