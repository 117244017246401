/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage, UploadFileModal } from "@/components";
import { Button } from "@/components/ui/button";
import { H4 } from "@/components/ui/typography";
import { useOfficeAction } from "@/hooks";
import { useProjectStore } from "@/store";
import { DocumentType, OfficeAction, Patent } from "@/types/project";
import { exportOfficeActionDraftToWord } from "@/utils/exportOfficeActionDraftToWord";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ApplicationDocumentsTable from "./ApplicationDocumentsTable";
import DocumentsBreadcrumb from "./components/DocumentsBreadcrumb";
import { ExportButton } from "./components/DraftingButtons";
import OfficeActionDocument from "./OfficeActionDocument";

/**
 * @description Application Documents page
 */
const ApplicationDocumentsPage: React.FC = () => {
  // Local and project states
  // const { currentProject } = useProjectStore();
  const { currentProject, currentProjectId } = useProjectStore();
  const { getOfficeActions, processOfficeAction } = useOfficeAction();
  const [selectedDocId, setSelectedDocId] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showUploadFilesModal, setShowUploadFilesModal] = useState(false);

  const [isExporting, setIsExporting] = useState(false);

  const pageName = useMemo(
    () =>
      currentProject.name ? `${currentProject.name} - Context - &AI` : "Context - &AI",
    [currentProject.name],
  );

  // Fetch office action on mount
  const fetchOfficeActions = useCallback(async () => {
    try {
      if (currentProject.id) {
        setLoading(true);
        const response = await getOfficeActions(currentProject.id);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching office actions:", error);
    }
  }, []);

  useEffect(() => {
    fetchOfficeActions();
  }, []);

  // Navigation handling (for browser back/forwards)
  const handleRowClick = useCallback((documentId: string) => {
    window.history.pushState({ view: "document", id: documentId }, "");
    setSelectedDocId(documentId);
  }, []);

  const handleBackToDocuments = useCallback(() => {
    window.history.back();
  }, []);

  const handlePopState = useCallback((event: PopStateEvent) => {
    if (event.state?.view === "document" && event.state?.id) {
      setSelectedDocId(event.state.id);
    } else {
      setSelectedDocId(null);
    }
  }, []);

  // History management effects (for browser back/forwards)
  function initializeHistory() {
    if (!window.history.state) {
      window.history.replaceState({ view: "documents" }, "");
    }
  }

  useEffect(() => {
    initializeHistory();
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [handlePopState]);

  function cleanupHistory() {
    if (window.history.state?.view === "document") {
      window.history.pushState({ view: "documents" }, "");
    }
  }

  useEffect(() => {
    return cleanupHistory;
  }, []);

  // Tracking documents and selected documents
  const tableDocuments = currentProject?.applicationDocuments ?? [];
  const selectedDocument = useMemo(
    () =>
      selectedDocId ? tableDocuments.find((doc) => doc.id === selectedDocId) : null,
    [selectedDocId, tableDocuments],
  );

  // Export button
  const handleExport = async () => {
    setIsExporting(true);
    try {
      await exportOfficeActionDraftToWord(
        selectedDocument.document as OfficeAction,
        currentProject.subject as Patent,
      );
    } finally {
      setIsExporting(false);
    }
  };

  const hasContentToExport = (document: OfficeAction): boolean => {
    if (!document?.claimDetails) {
      return false;
    }

    const hasContent = document.claimDetails.some((claim) =>
      claim.reasons.some((reason) => {
        const hasArguments = reason.arguments?.length > 0;
        const hasAmendments = reason.amendments?.length > 0;
        return hasArguments || hasAmendments;
      }),
    );

    return hasContent;
  };

  const renderDocumentContent = useMemo(() => {
    if (
      !selectedDocument ||
      selectedDocument.document_type !== DocumentType.OFFICE_ACTION
    ) {
      return null;
    }

    const officeAction = selectedDocument.document as OfficeAction;
    const showExportButton = hasContentToExport(officeAction);

    return (
      <>
        <div className="flex justify-between items-center">
          <DocumentsBreadcrumb
            onBackToDocuments={handleBackToDocuments}
            documentType="Office Action"
          />
          {showExportButton && (
            <ExportButton onExport={handleExport} isExporting={isExporting} />
          )}
        </div>
        <OfficeActionDocument document={officeAction} />
      </>
    );
  }, [
    selectedDocument,
    handleBackToDocuments,
    handleExport,
    isExporting,
    // Track the entire document structure
    selectedDocument?.document,
  ]);

  // Upload file modal
  const handleContextAddClick = async (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      try {
        // setIsProcessing(true);
        await processOfficeAction(selectedFiles[0], currentProjectId);
        await fetchOfficeActions();
      } catch (error) {
        console.error("Error uploading office action:", error);
      } finally {
        // setIsProcessing(false);
        setShowUploadFilesModal(false);
      }
    }
  };

  // Main content render
  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center flex-col">
          <Loader />
        </div>
      );
    }

    // If no documents, show empty state
    if (!tableDocuments?.length) {
      return (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>This project has no office actions.</H4>
          {/* <p>Documents you upload about your application will appear here.</p> */}
          <div className="flex flex-row gap-2">
            <Button
              className="mt-3"
              variant="outline"
              onClick={() => setShowUploadFilesModal(true)}
            >
              Upload Office Action
            </Button>
            <UploadFileModal
              open={showUploadFilesModal}
              handleClose={() => setShowUploadFilesModal(false)}
              inContext={true}
              handleContextAddClick={handleContextAddClick}
              buttonLabel="Add Office Action"
            />
          </div>
        </div>
      );
    }

    // If a document is selected, show document
    if (selectedDocId && renderDocumentContent) {
      return <div className="p-2">{renderDocumentContent}</div>;
    }

    // Otherwise, show the document table
    return (
      <ApplicationDocumentsTable
        documents={tableDocuments}
        onRowClick={handleRowClick}
      />
    );
  };

  return <ProjectPage pageName={pageName}>{renderContent()}</ProjectPage>;
};
export default ApplicationDocumentsPage;
