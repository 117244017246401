/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components";
import DocumentViewer from "@/components/patents/DocumentViewer";
import { Button } from "@/components/ui/button";
import { DataTableCell } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useDataTable } from "@/hooks";
import { useFeedbackData } from "@/hooks/useFeedbackData";
import { useAppStateStore, useProjectStore } from "@/store";
import { ElementType, InvalidityCitation } from "@/types";
import { convertToUtcDateString } from "@/utils/dateUtils";
import { splitId } from "@/utils/projectUtils";
import {
  CopyPlusIcon,
  PenLineIcon,
  PlusIcon,
  ReplaceIcon,
  SearchIcon,
} from "lucide-react";
import React, { useState } from "react";
import CopyCitationsModal from "./CopyCitationsModal";
import HeaderFooterEditor from "./HeaderFooterEditor";
import InvalidityTableCitation from "./InvalidityTableCitation";
import ReferenceTableCollapsibleFeedbackModal from "./ReferenceTableCollapsibleFeedbackModal";

interface InvalidityTableCitationCellProps {
  index: number;
  refId: string;
  claimNumber: string;
  claimText: string;
  citations: InvalidityCitation[];
  fetchReferenceChartData: () => void;
  addingCitationKey: string;
  setAddingCitationKey: (key: string) => void;
  setIsEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  header?: string;
  footer?: string;
}

/**
 * @description Reference table citation cell
 * @param {number} index - The index of the citation.
 * @param {string} refId - The id of the reference.
 * @param {string} claimNumber - The claim number.
 * @param {string} claimText - The claim text.
 * @param {string} invalidityId - The id of the invalidity.
 * @param {InvalidityCitation[]} citations - The citations.
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 * @param {string} addingCitationKey - The key of the citation being added.
 * @param {function} setAddingCitationKey - Function to set the adding citation key.
 * @param {function} setIsEditing - Function to set the editing state.
 * @param {boolean} isEditing - Whether the citation is being edited.
 */
const InvalidityTableCitationCell: React.FC<InvalidityTableCitationCellProps> = ({
  index,
  refId,
  claimNumber,
  claimText,
  citations = [],
  fetchReferenceChartData,
  addingCitationKey,
  setAddingCitationKey,
  setIsEditing,
  isEditing,
  header,
  footer,
}) => {
  const { currentProjectId, currentProject, elementEditIndex, selectedElementType } =
    useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { retrieveInvalidityForClaim } = useDataTable();
  const { positiveFeedbackData, negativeFeedbackData } = useFeedbackData(
    currentProjectId,
    refId,
    claimNumber,
  );

  const [isInvalidityLoading, setIsInvalidityLoading] = useState(false);
  const [showNoMoreCitations, setShowNoMoreCitations] = useState(false);
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [showCopyCitationsModal, setShowCopyCitationsModal] = useState<
    "append" | "replace" | null
  >(null);
  const [showHeaderFooterEditor, setShowHeaderFooterEditor] = useState(false);

  const shownCitations = citations.filter((citation) => !citation.removed);

  const removedCitations = citations.filter((citation) => citation.removed === true);

  const formatHeaderFooter = (text: string) => {
    return text
      .replaceAll("{{element_number}}", claimNumber)
      .replaceAll("{{reference_name}}", currentProject?.documentsToNicknames[refId])
      .replaceAll("{{element_language}}", claimText)
      .replaceAll(
        "{{subject_nickname}}",
        currentProject?.documentsToNicknames[currentProject?.subject?.id],
      )
      .replaceAll("{{subject_number}}", splitId(currentProject?.subject?.number))
      .replaceAll(
        "{{subject_priority_date}}",
        convertToUtcDateString(currentProject?.subject?.priorityDate),
      );
  };

  const retrieveInvalidity = async (index: number, refId: string) => {
    setIsInvalidityLoading(true);
    try {
      const response = await retrieveInvalidityForClaim(
        currentProjectId,
        claimNumber,
        refId,
        claimText,
        selectedElementType === ElementType.FEATURE,
        positiveFeedbackData.map((citation) => citation.text),
        negativeFeedbackData.map((citation) => citation.text),
        index,
      );

      // Check if the response is successful and contains data
      if (!response.success) {
        if (response.status === 404) {
          setShowNoMoreCitations(true);
        } else {
          addErrorMessage(`Error retrieving invalidity for claim ${claimNumber}.`);
        }
        return;
      }

      // Check if we have valid citations in the response
      if (!Array.isArray(response.data) || response.data.length === 0) {
        setShowNoMoreCitations(true);
        return;
      }
    } catch (error) {
      addErrorMessage(`Error retrieving invalidity for claim ${claimNumber}.`);
    } finally {
      setIsInvalidityLoading(false);
    }
  };

  return (
    <DataTableCell className="gap-2">
      <div className="mb-4">
        {showNoMoreCitations && (
          <div className="mb-3 text-center">
            <p className="text-sm text-muted-foreground">
              No more citations found. Provide feedback to find more.
            </p>
          </div>
        )}
        {isInvalidityLoading ? (
          <div className="mb-2">
            <Loader message="Retrieving invalidity..." />
          </div>
        ) : (
          <div className="flex items-center justify-center space-x-2 ">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  disabled={isEditing || isInvalidityLoading || showNoMoreCitations}
                  onClick={() => retrieveInvalidity(index, refId)}
                >
                  <SearchIcon className="h-4 w-4" />
                  <span className="sr-only">Find more citations</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Find more</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  disabled={isEditing || isInvalidityLoading}
                  onClick={() => setShowDocumentViewer(true)}
                >
                  <PlusIcon className="h-4 w-4" />
                  <span className="sr-only">Add citation</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Add from document</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  disabled={isEditing || isInvalidityLoading}
                  onClick={() => setShowCopyCitationsModal("append")}
                >
                  <CopyPlusIcon className="h-4 w-4" />
                  <span className="sr-only">Copy evidence from</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Append evidence from</p>
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  disabled={isEditing || isInvalidityLoading}
                  onClick={() => setShowCopyCitationsModal("replace")}
                >
                  <ReplaceIcon className="h-4 w-4" />
                  <span className="sr-only">Replace evidence with</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Replace evidence with</p>
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  disabled={isEditing || isInvalidityLoading}
                  onClick={() => setShowHeaderFooterEditor(true)}
                >
                  <PenLineIcon className="h-4 w-4" />
                  <span className="sr-only">Edit header/footer</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Edit header/footer</p>
              </TooltipContent>
            </Tooltip>
            <DocumentViewer
              open={showDocumentViewer}
              handleClose={() => setShowDocumentViewer(false)}
              referenceId={refId}
              citationText={""}
              selectedElement={claimNumber}
              selectedElementType={selectedElementType}
              startInChartMode={true}
            />
          </div>
        )}
      </div>
      {(header || currentProject?.settings?.headerFormat) && (
        <p className="text-sm text-muted-foreground mb-4">
          {formatHeaderFooter(header || currentProject?.settings?.headerFormat)}
        </p>
      )}

      {shownCitations.map((citation, citationIndex) => (
        <InvalidityTableCitation
          key={`${index}-${refId}-${citationIndex}`}
          citationKey={`${index}-${refId}-${citationIndex}`}
          citation={citation}
          index={index}
          refId={refId}
          claimNumber={claimNumber}
          citationIndex={citationIndex}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          addingCitationKey={addingCitationKey}
          setShowNoMoreCitations={setShowNoMoreCitations}
        />
      ))}

      {(footer || currentProject?.settings?.footerFormat) && (
        <p className="text-sm text-muted-foreground mt-4 mb-2">
          {formatHeaderFooter(footer || currentProject?.settings?.footerFormat)}
        </p>
      )}
      <ReferenceTableCollapsibleFeedbackModal
        index={index}
        positiveFeedbackData={positiveFeedbackData}
        negativeFeedbackData={negativeFeedbackData}
        removedCitations={removedCitations}
        refId={refId}
        claimNumber={claimNumber}
        currentProjectId={currentProjectId}
      />

      <CopyCitationsModal
        open={showCopyCitationsModal !== null}
        handleClose={() => setShowCopyCitationsModal(null)}
        mode={showCopyCitationsModal}
        elementType={selectedElementType}
        claimNumber={claimNumber}
        referenceId={refId}
        rowIndex={index}
      />

      <HeaderFooterEditor
        initialHeader={header || currentProject?.settings?.headerFormat || ""}
        initialFooter={footer || currentProject?.settings?.footerFormat || ""}
        isOpen={showHeaderFooterEditor}
        onClose={() => setShowHeaderFooterEditor(false)}
        index={index}
        currentElementNumber={claimNumber}
        currentElementLanguage={claimText}
      />
    </DataTableCell>
  );
};

export default InvalidityTableCitationCell;
