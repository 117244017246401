/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
  ApplicationAddSubjectStep,
  ApplicationOfficeActionStep,
  CreateProjectPageLayout,
  ProjectDetailsStep,
} from "@/features/project/create/components";
import { useCreateProject } from "@/hooks";
import { useCreateProjectStore } from "@/store";
import { ProcessType, ProjectType } from "@/types";
import React, { useEffect, useState } from "react";

/**
 * @description Renders the create application project page
 */
const CreateApplicationProjectPage: React.FC = () => {
  const { createOfficeActionParse, createProjectAfterOfficeActionProcess } =
    useCreateProject();
  // Global state from store
  const {
    isProjectCreationInProgress,
    projectName,
    supportingDocuments,
    processes,
    updateSpinnerText,
    resetCreateProjectStore,
    updateIsProjectCreationInProgress,
    updateProjectError,
    removeProcesses,
  } = useCreateProjectStore();

  // Local state setup
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [canCreateProject, setCanCreateProject] = useState<boolean>(false);
  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);
  const [isOfficeActionCompleted, setIsOfficeActionCompleted] =
    useState<boolean>(false);
  const [isOfficeActionExpanded, setIsOfficeActionExpanded] = useState<boolean>(false);
  const [isAddSubjectExpanded, setIsAddSubjectExpanded] = useState<boolean>(false);
  // const [patentNotFound, setPatentNotFound] = useState<boolean>(false);

  const [hasOADocument, setHasOADocument] = useState<boolean>(false);

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetCreateProjectStore();
    }
  }, []);

  // Step completion
  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  // Trigger office action parsing when OA is uploaded and not yet parsed
  useEffect(() => {
    if (isOfficeActionCompleted) {
      const officeActionDoc = supportingDocuments.find(
        (doc) => doc.documentType === "OFFICE_ACTION",
      );

      if (officeActionDoc) {
        // Check if there's an existing process for this document
        const existingProcess = processes.find(
          (process) =>
            process.id === officeActionDoc.fileName &&
            process.type === ProcessType.ADD_OFFICE_ACTION,
        );

        if (!existingProcess) {
          createOfficeActionParse().catch((error) => {
            console.error("Error processing office action:", error);
          });
        }
      }
    }
  }, [
    isOfficeActionCompleted,
    supportingDocuments,
    processes,
    createOfficeActionParse,
  ]);

  // Create project when all steps are completed
  useEffect(() => {
    setCanCreateProject(
      isDetailsCompleted &&
        // If office action is completed OR subject is completed (now required if no OA)
        ((isOfficeActionCompleted && hasOADocument) || isSubjectCompleted),
    );
  }, [isDetailsCompleted, isSubjectCompleted, isOfficeActionCompleted]);

  // Add effect to track OA document existence
  useEffect(() => {
    const officeActionDoc = supportingDocuments.find(
      (doc) => doc.documentType === "OFFICE_ACTION",
    );
    setHasOADocument(!!officeActionDoc);
  }, [supportingDocuments]);

  const startProjectCreation = () => {
    setIsConfirmed(true);
    updateIsProjectCreationInProgress(true);
    updateSpinnerText("Creating project...");
  };

  useEffect(() => {
    if (isProjectCreationInProgress) {
      const officeActionDoc = supportingDocuments.find(
        (doc) => doc.documentType === "OFFICE_ACTION",
      );

      if (officeActionDoc) {
        // Only check OA process if there's an OA document
        const erroredOAProcess = processes.some(
          (process) =>
            process.type === ProcessType.ADD_OFFICE_ACTION &&
            process.status === "error",
        );

        const completedOAProcess = processes.some(
          (process) =>
            process.type === ProcessType.ADD_OFFICE_ACTION &&
            process.status === "completed",
        );

        if (erroredOAProcess) {
          updateIsProjectCreationInProgress(false);
          setIsConfirmed(false);
          setIsOfficeActionExpanded(true);
          return;
        }

        // Only wait for OA parsing if there's an OA document
        if (completedOAProcess || !officeActionDoc) {
          (async () => {
            try {
              await createProjectAfterOfficeActionProcess(ProjectType.APP);
            } catch (error) {
              updateProjectError(true);
            } finally {
              updateIsProjectCreationInProgress(false);
            }
          })();
        }
      } else {
        // No OA document, proceed directly with project creation
        (async () => {
          try {
            await createProjectAfterOfficeActionProcess(ProjectType.APP);
          } catch (error) {
            updateProjectError(true);
          } finally {
            updateIsProjectCreationInProgress(false);
          }
        })();
      }
    }
  }, [isProjectCreationInProgress, processes, supportingDocuments]);

  return (
    <CreateProjectPageLayout
      isConfirmed={isConfirmed}
      resetState={resetCreateProjectStore}
      disabled={!canCreateProject}
      handleCreate={startProjectCreation}
      isPortfolio={false}
      title="New Application Project"
    >
      {/* Project details */}
      <ProjectDetailsStep startExpanded={true} isPortfolio={false} />
      {/* Office action */}
      <ApplicationOfficeActionStep
        startExpanded={true}
        onCompletionChange={setIsOfficeActionCompleted}
      />

      {/* Patent application */}
      <ApplicationAddSubjectStep
        startExpanded={true}
        onCompletionChange={setIsSubjectCompleted}
        isRequired={!hasOADocument}
      />
    </CreateProjectPageLayout>
  );
};

export default CreateApplicationProjectPage;
