/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import Loader from "@/components/info/Loader";
import { ParentType, RoleType } from "@/types";
import { useAuthInfo } from "@propelauth/react";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAdminManagement, useLlm, useProject, useViz } from "../hooks";
import { useAppStateStore, useProjectStore, useUserStore } from "../store";

const ProtectedRoute: React.FC = () => {
  const { isLoggedIn, loading, user, userClass } = useAuthInfo();
  const location = useLocation();
  const { createUserPostgres } = useAdminManagement();

  const { projectId } = useParams<{ projectId: string }>();
  const { portfolioId } = useParams<{ portfolioId: string }>();

  const navigate = useNavigate();
  const { updateOrganizationId, updateRole, updateOrganizationName, organizationName } =
    useUserStore();
  const { updateCurrentParent, currentProject, currentPortfolio } = useProjectStore();

  const { getUserProjects, getSearchChatProjectId, getPortfolioMetadata } = useViz();
  const { getUserProjectAccessCheck, getProjectMetadata } = useProject();
  const { getPatentContext } = useLlm();

  // Get user details on page load
  const userEmail = user?.email || "";
  const firstName = user?.firstName || "";

  // Global store
  const { isStoreEmpty, updateIsStoreEmpty, updateIsLoading } = useAppStateStore();
  const { updateCurrentProjectId } = useProjectStore();

  // Local state
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [unauthorizedProject, setUnauthorizedProject] = useState<string>("");
  const [hasAccess, setHasAccess] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  // Redirect to signin if not logged in
  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      navigate("/signin", { replace: true });
    }
  }, [isLoading, isLoggedIn, navigate]);

  // Fetch data on page refresh if store is empty
  useEffect(() => {
    async function loadData() {
      if (isLoggedIn && isStoreEmpty) {
        try {
          setIsLoading(true);
          await getSearchChatProjectId();
          await getUserProjects();

          if (userEmail.endsWith("@tryandai.com")) {
            await createUserPostgres();
          }

          if (location.pathname.includes("/explore")) {
            const searchChatIdResponse = await getSearchChatProjectId();
            await getProjectMetadata(searchChatIdResponse.data.id);
          }

          if (location.pathname.includes("/portfolio")) {
            if (portfolioId) {
              updateCurrentParent(ParentType.PORTFOLIO);
              updateIsLoading(portfolioId);
              const includeReferences = !location.pathname.includes("/project");
              await getPortfolioMetadata(portfolioId, includeReferences, false);

              updateIsLoading(null);
            }
          } else {
            updateCurrentParent(ParentType.PROJECT);
          }

          if (location.pathname.includes("/project")) {
            if (projectId) {
              const response = await getUserProjectAccessCheck(projectId);
              const userHasAccess = response.data;
              setHasAccess(userHasAccess);
              if (response.success && userHasAccess) {
                updateCurrentProjectId(projectId);
                updateIsLoading(projectId);

                await getProjectMetadata(projectId, "", true);

                if (location.pathname.includes("/context")) {
                  await getPatentContext(projectId);
                }
                updateIsLoading(null);
              } else {
                setUnauthorizedProject(projectId);
              }
            }
          }
        } catch (error) {
          if (process.env.NODE_ENV !== "production") {
            console.error("Error loading data on authenticated page refresh:", error);
          }
        } finally {
          setIsLoading(false);
          updateIsStoreEmpty(false);
        }
      }
    }

    loadData();
  }, [
    isLoggedIn,
    isStoreEmpty,
    location.pathname,
    userEmail,
    firstName,
    organizationName,
  ]);

  // Set org name and role on log in
  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      const orgs = userClass?.getOrgs();
      const orgId = orgs?.[0]?.orgId;
      const orgName = orgs?.[0]?.orgName;
      updateOrganizationId(orgId || "");
      updateOrganizationName(orgName || "");
      const role = userClass?.isRole(orgId || "", RoleType.ADMIN)
        ? RoleType.ADMIN
        : userClass?.isRole(orgId || "", RoleType.OWNER)
          ? RoleType.OWNER
          : RoleType.MEMBER;
      updateRole(role || "");
    }
  }, [isLoggedIn, isLoading]);

  // Redirect to unauthorized project if no access
  useEffect(() => {
    if (!hasAccess && unauthorizedProject) {
      navigate(`/project/${unauthorizedProject}/unauthorized`, {
        replace: true,
      });
    }
  }, [hasAccess, unauthorizedProject, navigate]);

  if (
    isLoading ||
    (projectId && !currentProject) ||
    (portfolioId && !currentPortfolio)
  ) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader />
      </div>
    );
  } else {
    return <Outlet />;
  }
};

export default ProtectedRoute;
