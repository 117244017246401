import { DateTime } from "luxon";

type DateInput = Date | string | DateTime;
/**
 * Converts a Date object to a UTC string in the format YYYY-MM-DD
 * @param date - The Date object to convert.
 * @returns The UTC string in the format YYYY-MM-DD or undefined if the date is null.
 */
export const convertToUtcDateString = (date: DateInput) => {
  if (!date) return "";
  const { day, month, year } = extractDayMonthYearFromDate(date);
  return DateTime.utc(year, month, day).toFormat("MM/dd/yyyy");
};

/**
 * Converts a Date object to a UTC string in the format YYYY-MM-DDTHH:MM:SS.SSSZ (e.g., 2024-11-05T00:00:00.000Z)
 * @param date - The Date object to convert.
 * @returns The UTC string in the format YYYY-MM-DDTHH:MM:SS.SSSZ or undefined if the date is null.
 */
export const convertToUtcDateTimeString = (date: DateInput) => {
  const { day, month, year } = extractDayMonthYearFromDate(date);
  return DateTime.utc(year, month, day).toISO();
};

/**
 * Converts a Date object to a UTC DateTime object
 * @param date - The Date object to convert.
 * @returns The UTC DateTime object or undefined if the date is null.
 */
export const convertToUtcDate = (date: DateInput) => {
  const { day, month, year } = extractDayMonthYearFromDate(date);
  return DateTime.utc(year, month, day);
};

export const getUtcDateNow = () => {
  return DateTime.now().setZone("utc").startOf("day");
};

// Helper function to extract the day, month, and year from a Date object
const extractDayMonthYearFromDate = (date: DateInput) => {
  let jsDate: Date;

  if (date instanceof DateTime) {
    jsDate = date.toJSDate();
  } else if (typeof date === "string") {
    jsDate = new Date(date);
  } else {
    jsDate = date;
  }

  const day = jsDate.getDate();
  const month = jsDate.getMonth() + 1; // months are 0-indexed
  const year = jsDate.getFullYear();
  return { day, month, year };
};

export const getUtcDateString = (date: DateInput) => {
  if (date instanceof DateTime) {
    return date.toFormat("MM/dd/yyyy");
  } else if (typeof date === "string") {
    return DateTime.fromISO(date, { zone: "utc" }).toFormat("MM/dd/yyyy");
  } else {
    return DateTime.fromJSDate(date, { zone: "utc" }).toFormat("MM/dd/yyyy");
  }
};
