/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useCreateProject } from "@/hooks";
import { useCreateProjectStore } from "@/store";
import { isLikelyPatentNumber, parseCommaSeparatedString } from "@/utils/dataUtils";
import React, { useState } from "react";
import PatentDetailsTable from "./PatentDetailsTable";
interface PatentNumbersInputCreateProps {
  isInvalidity?: boolean;
}

/**
 * @description Patent numbers input create displayed in create project flow
 * @param {boolean} isInvalidity - Whether the project is an invalidity project.
 */
const PatentNumbersInputCreate: React.FC<PatentNumbersInputCreateProps> = ({
  isInvalidity,
}) => {
  const { getSubjectDetailsOnCreate } = useCreateProject();
  const { subjectDetails, updateSubjectDetails, updateSubjectNumbers, updateFeatures } =
    useCreateProjectStore();

  const [inputText, setInputText] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFindInputNumbers = async () => {
    const patentNumbers = parseCommaSeparatedString(inputText).filter(
      (num) => num.trim() !== "",
    );

    // Make sure the portfolio doesn't exceed the maximum number of patents
    if (patentNumbers.length > 100) {
      setInputError(
        "The maximum number of patents or applications that can be in a portfolio is 100.",
      );
      return;
    }

    // Check if the patent numbers are valid
    const validPatentNumbers = [];
    const invalidPatentNumbers = [];
    for (const patentNumber of patentNumbers) {
      if (!isLikelyPatentNumber(patentNumber)) {
        invalidPatentNumbers.push(patentNumber);
      } else {
        validPatentNumbers.push(patentNumber);
      }
    }
    if (invalidPatentNumbers.length > 0) {
      setInputError(
        `The following patent numbers are not valid: ${invalidPatentNumbers.join(
          ", ",
        )}`,
      );
      return;
    }
    // If there are no valid patent numbers, return
    if (validPatentNumbers.length === 0) {
      setInputError("No valid patent or application numbers input.");
      return;
    }

    if (validPatentNumbers.length > 0) {
      setIsLoading(true);
      const response = await getSubjectDetailsOnCreate(
        validPatentNumbers,
        isInvalidity,
      );
      if (response.success) {
        const successfulItems = response.data.filter((item) => item.status === "200");
        const failedItems = response.data.filter((item) => item.status === "404");

        if (successfulItems.length > 0) {
          updateFeatures(successfulItems[0].features);
          updateSubjectDetails(successfulItems);
          updateSubjectNumbers(successfulItems.map((item) => item.number));
        }

        if (failedItems.length > 0) {
          const failedNumbers = failedItems.map((item) => item.number);
          setInputError(
            `The following patent numbers were not found: ${failedNumbers.join(", ")}`,
          );
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader message="Finding patent(s) and extracting details..." />
      ) : (
        <>
          {subjectDetails.length === 0 && (
            <>
              {isInvalidity ? (
                <div className="space-y-2">
                  <Label htmlFor="patent-number">
                    Published Patent or Application Number
                  </Label>
                  <Input
                    id="patent-number"
                    placeholder="e.g., US10952930B2"
                    value={inputText || ""}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  {inputError && (
                    <Alert variant="destructive">
                      <AlertDescription>{inputError}</AlertDescription>
                    </Alert>
                  )}
                </div>
              ) : (
                <div className="space-y-2">
                  <Label htmlFor="patent-numbers">
                    Published patent or application numbers
                  </Label>
                  <AutosizeTextarea
                    id="patent-numbers"
                    placeholder="Enter a comma-separated list.  A country prefix is required. Example: US10952930, CN10952931B2, US20220183193A1"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  {inputError && (
                    <Alert variant="destructive">
                      <AlertDescription>{inputError}</AlertDescription>
                    </Alert>
                  )}
                </div>
              )}
              <Button
                onClick={handleFindInputNumbers}
                className="mt-4 w-full"
                disabled={inputText === ""}
              >
                {isInvalidity ? "Find patent" : "Find subject patents"}
              </Button>
            </>
          )}
          <div>
            {subjectDetails.length > 0 && (
              <PatentDetailsTable details={subjectDetails} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PatentNumbersInputCreate;
