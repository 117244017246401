/*
 * Copyright AndAI, Inc. 2024. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { Loader, ProjectPage } from "@/components";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ScrollTopButton } from "@/components/ui/scroll-top-button";
import { Separator } from "@/components/ui/separator";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { H4 } from "@/components/ui/typography";
import { useDataTable, useProject, useViz } from "@/hooks";
import { cn } from "@/lib/utils";
import {
  useAppStateStore,
  useProcessStore,
  useProjectStore,
  useUserStore,
} from "@/store";
import {
  ChartColor,
  conflictingChartProcesses,
  DocumentStatus,
  ElementType,
  ProcessType,
  ProjectType,
} from "@/types";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ColorSelect,
  ElementSelect,
  EvaluationResults,
  ExportChartsModal,
  InvalidityTable,
  PruneInvalidityColorModal,
  PruneInvalidityScoreModal,
  ReferenceSelect,
  SummaryTable,
} from "./components";

/**
 * @description Renders the invalidity table page
 */
const ElementChartsPage: React.FC = () => {
  const { getReferenceMetadata, getDoesIprExist, getEvaluation } = useViz();
  const { getSummaryChartData, getReferenceChartData } = useDataTable();

  const location = useLocation();
  const { selectedReference, selectedElementType: navigatedElementType } =
    location.state || {};

  // Global state from store
  const {
    currentProjectId,
    currentPortfolioId,
    updateChartData,
    selectedReferences,
    updateSelectedReferences,
    currentProject,
    updateSelectedElements,
    currentSubject,
    showAssertedClaims,
    selectedColors,
    selectedElementType,
    updateSelectedColors,
    updateSelectedElementType,
    selectedElements,
    updateShowAssertedClaims,
    currentPortfolio,
  } = useProjectStore();
  const references = useProjectStore((state) => state.currentProject?.references ?? []);
  const nonPendingReferenceIds = references
    .filter((reference) => reference.status !== DocumentStatus.PROCESSING)
    .map((reference) => reference.id);
  const { addErrorMessage, isStoreEmpty, isReferencesLoading, isLoading } =
    useAppStateStore();
  const { areProcessesPending } = useProcessStore();
  const { organizationName } = useUserStore();
  const { getProjectReferencesTableRows } = useProject();

  // Local state setup
  const navigate = useNavigate();
  const [showRefError, setShowRefError] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [showPruneColorModal, setShowPruneColorModal] = useState(false);
  const [showPruneScoreModal, setShowPruneScoreModal] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const [showEvaluationResults, setShowEvaluationResults] = useState(false);
  const [evaluationData, setEvaluationData] = useState<any>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isFilling, setIsFilling] = useState(false);
  const [previousElementType, setPreviousElementType] = useState<ElementType | null>(
    null,
  );
  const [doesIprExist, setDoesIprExist] = useState(false);
  const [isReferenceChartLoading, setIsReferenceChartLoading] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Add new state to track summary chart loading
  const [isSummaryChartLoading, setIsSummaryChartLoading] = useState(false);

  // Add new state for row visibility
  const [rowVisibility, setRowVisibility] = useState<string>("show");

  const isStandardsPortfolio = currentPortfolio?.type === ProjectType.SEP;

  // Show or hide IPR depending if IPR exists
  useEffect(() => {
    const checkIpr = async () => {
      if (currentSubject) {
        const patentNumber = currentSubject.number;
        try {
          const response = await getDoesIprExist(patentNumber);
          if (response.success) {
            const exists = response.data;
            setDoesIprExist(exists);
          }
        } catch (error) {
          addErrorMessage("Error checking if IPR exists");
        }
      }
    };

    checkIpr();
  }, [currentSubject]);

  useEffect(() => {
    const fetchReferences = async () => {
      if (!currentProject?.references) {
        await getProjectReferencesTableRows(currentProjectId);
      }
    };

    fetchReferences();
  }, [currentProject]);

  useEffect(() => {
    // If there is a selectedReference passed via navigate
    if (selectedReference) {
      // Call handleReferenceSelect directly, just like the ReferenceSelect component would
      handleReferenceSelect([selectedReference.id]);
    }
    // navigated type unused but keeping for implementing features
  }, [selectedReference, navigatedElementType]);

  // Set page title
  const defaultProjectName = "Charts";
  const projectName = currentProject?.name || defaultProjectName;
  const pageName = `${projectName} - Charts`;

  const isActionDisabled =
    areProcessesPending({
      types: conflictingChartProcesses,
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: conflictingChartProcesses,
      portfolioId: currentPortfolioId,
    });

  // Fetches the summary chart data
  const debouncedFetchSummaryChartData = useCallback(
    debounce(
      async (type: string) => {
        if (type === ElementType.EVALUATE) {
          return;
        }
        setIsSummaryChartLoading(true);
        try {
          const response = await getSummaryChartData(currentProjectId, type);

          if (!response.success) {
            setShowRefError(true);
            addErrorMessage(`Error fetching summary chart data`);
          }
        } catch (error) {
          setShowRefError(true);
          addErrorMessage(`Error fetching summary chart data`);
        } finally {
          setIsSummaryChartLoading(false);
        }
      },
      300,
      { leading: true, trailing: true },
    ),
    [currentProjectId],
  );

  // Fetches the reference chart data
  const fetchReferenceChartData = async (
    referenceIds: string[],
    type: string,
    subset: string[] = [],
    colors: string[] = [],
  ) => {
    setIsReferenceChartLoading(true);
    const response = await getReferenceChartData(
      currentProjectId,
      referenceIds,
      type === ElementType.FEATURE,
      subset,
      colors,
      true, // Update chart data
    );

    if (!response.success) {
      setShowRefError(true);
      addErrorMessage(`Error fetching reference chart data`);
    }
    setIsReferenceChartLoading(false);
  };

  // Refreshes the reference data
  const refreshReferenceData = async (
    nonPendingReferenceIds: string[],
    subset?: string[],
    colors?: string[],
  ) => {
    if (nonPendingReferenceIds === null || nonPendingReferenceIds.length === 0) {
      updateChartData([]);
      updateSelectedReferences([]);
    } else {
      await getReferenceMetadata(nonPendingReferenceIds);
      await fetchReferenceChartData(
        nonPendingReferenceIds,
        selectedElementType,
        subset || selectedElements,
        colors || selectedColors,
      );
    }
  };

  const fetchElementChartPageData = useCallback(async () => {
    await debouncedFetchSummaryChartData(selectedElementType);
    if (selectedReferences && selectedReferences.length > 0) {
      const refIds = selectedReferences.map((ref) => ref.id);
      await fetchReferenceChartData(
        refIds,
        selectedElementType,
        selectedElements,
        selectedColors,
      );
    }
  }, [
    debouncedFetchSummaryChartData,
    fetchReferenceChartData,
    selectedElementType,
    selectedReferences,
    selectedElements,
    selectedColors,
  ]);

  const handleTypeSelect = useCallback(
    async (newType: string | null) => {
      if (newType !== selectedElementType && newType !== null && newType !== "") {
        updateSelectedElementType(
          newType === ElementType.CLAIM ? ElementType.CLAIM : ElementType.FEATURE,
        );
        let labels = [];

        if (newType === ElementType.CLAIM) {
          labels = getClaimLabels();
          if (
            labels.length === currentProject?.assertedClaims?.length &&
            currentProject?.settings?.defaultToAssertedClaims
          ) {
            updateShowAssertedClaims(true);
            updateSelectedElements(currentProject?.assertedClaims);
          } else {
            updateShowAssertedClaims(false);
            updateSelectedElements(labels);
          }
        } else if (newType === ElementType.FEATURE) {
          labels = getFeatureLabels();
          updateShowAssertedClaims(false);
          updateSelectedElements(labels);
        }

        await fetchData({
          elementType: newType,
          elements: labels,
        });
      }
    },
    [
      selectedElementType,
      selectedReferences,
      debouncedFetchSummaryChartData,
      fetchReferenceChartData,
      currentSubject,
      updateSelectedElementType,
      updateSelectedElements,
      selectedColors,
    ],
  );

  const handleReferenceSelect = useCallback(
    async (referenceIds: string[]) => {
      await refreshReferenceData(referenceIds, selectedElements, selectedColors);
    },
    [selectedElements, selectedColors, refreshReferenceData],
  );

  const handleDataTableRefresh = async () => {
    const refIds = selectedReferences.map((ref) => ref.id);
    await refreshReferenceData(refIds, selectedElements, selectedColors);
  };

  const handleColorSelect = useCallback(
    async (colors: string[]) => {
      updateSelectedColors(colors);
      if (selectedReferences.length > 0) {
        const refIds = selectedReferences.map((ref) => ref.id);
        await refreshReferenceData(refIds, selectedElements, colors);
      }
    },
    [selectedReferences, selectedElements, refreshReferenceData, updateSelectedColors],
  );

  const handleClaimSelect = useCallback(
    async (claimIds: string[]) => {
      if (selectedReferences.length > 0) {
        const refIds = selectedReferences.map((ref) => ref.id);
        updateSelectedElements(claimIds);
        await refreshReferenceData(refIds, claimIds, selectedColors);
      } else {
        updateSelectedElements(claimIds);
      }
    },
    [selectedReferences, selectedColors, updateSelectedElements, refreshReferenceData],
  );

  // Selects the chart display
  const selectChartDisplay = useCallback(
    async (claimNumber: string | null, key: string) => {
      const refIds = [key];
      const elements = claimNumber ? [claimNumber] : [];

      if (elements.length > 0) {
        updateSelectedElements([elements]);
        await refreshReferenceData(refIds, elements);
      } else {
        await refreshReferenceData(refIds);
      }
    },
    [updateSelectedElements, refreshReferenceData],
  );

  const handleReferencesNavigate = () => {
    navigate(`/project/${currentProjectId}/references`);
  };

  const handleCloseMenu = () => {
    setDropdownOpen(false);
  };

  const handlePruneMenuClick = (pruneType: "color" | "score") => {
    handleCloseMenu();
    if (pruneType === "color") {
      setShowPruneColorModal(true);
    } else {
      setShowPruneScoreModal(true);
    }
  };

  const handleFillStart = useCallback(() => {
    setIsFilling(true);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchSummaryChartData.cancel();
    };
  }, []);

  const handleFillEnd = useCallback(async () => {
    const refIds = selectedReferences.map((ref) => ref.id);
    await refreshReferenceData(refIds, selectedElements, selectedColors);
    setIsFilling(false);
  }, [selectedReferences, selectedElements, selectedColors, refreshReferenceData]);

  const handleEvaluate = useCallback(async () => {
    try {
      setIsEvaluating(true);
      setPreviousElementType(selectedElementType);
      updateSelectedReferences([]);
      updateSelectedElementType(ElementType.EVALUATE);
      const patentNumber = currentSubject.number;
      const response = await getEvaluation(
        currentProjectId,
        nonPendingReferenceIds,
        patentNumber,
      );
      if (response.success) {
        setEvaluationData(response.data);
        setShowEvaluationResults(true);
      } else {
        throw new Error("Failed to fetch evaluation data");
      }
    } catch (error) {
      addErrorMessage("Failed to fetch evaluation data");
    } finally {
      setIsEvaluating(false);
    }
  }, [
    currentProjectId,
    getEvaluation,
    addErrorMessage,
    selectedElementType,
    updateSelectedReferences,
    updateSelectedElementType,
    currentSubject,
    nonPendingReferenceIds,
  ]);

  const handleCloseEvaluation = useCallback(() => {
    setShowEvaluationResults(false);
    setEvaluationData(null);
    if (previousElementType) {
      updateSelectedElementType(previousElementType);
      setPreviousElementType(null);
    }
    debouncedFetchSummaryChartData(previousElementType);
    if (selectedReferences.length > 0) {
      const refIds = selectedReferences.map((ref) => ref.id);
      fetchReferenceChartData(
        refIds,
        previousElementType,
        selectedElements,
        selectedColors,
      );
    } else {
      updateChartData([]); // Reset chart data if no references are selected
    }
  }, [
    previousElementType,
    selectedReferences,
    selectedElements,
    selectedColors,
    debouncedFetchSummaryChartData,
    fetchReferenceChartData,
    updateChartData,
    updateSelectedElementType,
  ]);

  // ********* Update charts after a process (recharting, add reference, prune) finishes *********

  // on load clear the selected reference details
  useEffect(() => {
    // updateSelectedElements([]);
    if (
      showAssertedClaims &&
      currentProject?.assertedClaims &&
      currentProject.assertedClaims.length > 0
    ) {
      updateSelectedElements(currentProject.assertedClaims);
    } else {
      updateSelectedElements([]);
    }

    if (
      currentProjectId &&
      selectedElementType &&
      currentProject?.references !== null
    ) {
      debouncedFetchSummaryChartData(selectedElementType);
    }
  }, [selectedElementType, currentProjectId, currentProject?.references]);

  useEffect(() => {
    if (isStoreEmpty) updateSelectedElementType(ElementType.CLAIM);
  }, [isStoreEmpty]);

  useEffect(() => {
    updateSelectedReferences([]);
  }, []);

  const isRechartingInProgress =
    areProcessesPending({
      types: [ProcessType.RECHART, ProcessType.REPROCESS],
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: [ProcessType.RECHART, ProcessType.REPROCESS, ProcessType.GENERATE_CONTEXT],
      portfolioId: currentPortfolioId,
    });

  const isAddReferenceProcessPending =
    areProcessesPending({
      types: [ProcessType.ADD_REFERENCE],
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: [ProcessType.ADD_REFERENCE],
      portfolioId: currentPortfolioId,
    });

  useEffect(() => {
    if (!showPruneColorModal) {
      debouncedFetchSummaryChartData(selectedElementType);
    }
  }, [showPruneColorModal, selectedElementType]);

  useEffect(() => {
    if (!showPruneScoreModal) {
      debouncedFetchSummaryChartData(selectedElementType);
    }
  }, [showPruneScoreModal, selectedElementType]);

  const handleClaimModeSelect = useCallback(
    async (mode: "all" | "asserted") => {
      if (mode === "asserted") {
        updateShowAssertedClaims(true);
        const assertedClaims = currentProject.assertedClaims;
        if (selectedReferences.length > 0) {
          const refIds = selectedReferences.map((ref) => ref.id);
          await fetchReferenceChartData(
            refIds,
            selectedElementType,
            assertedClaims,
            selectedColors,
          );
        }
      } else {
        updateShowAssertedClaims(false);
        if (selectedReferences.length > 0) {
          const refIds = selectedReferences.map((ref) => ref.id);
          await fetchReferenceChartData(
            refIds,
            selectedElementType,
            [], // All claims
            selectedColors,
          );
        }
      }
    },
    [
      currentProject.assertedClaims,
      currentSubject,
      selectedReferences,
      selectedElementType,
      selectedColors,
      fetchReferenceChartData,
      updateShowAssertedClaims,
      updateSelectedElements,
    ],
  );

  const getClaimLabels = () => {
    return currentProject?.claims && currentProject.claims.length > 0
      ? currentProject.claims.map((claim) => Object.keys(claim)[0])
      : [];
  };

  const getFeatureLabels = () => {
    return currentProject?.features && currentProject.features.length > 0
      ? currentProject.features.map((feature) => feature.name)
      : [];
  };

  // Refetch chart data when collapsed claim depth changes
  useEffect(() => {
    fetchData();
  }, [currentProject?.settings?.collapsedClaimDepth]);

  // Add this helper function near the other helper functions
  const fetchData = useCallback(
    async (
      options: {
        elementType?: string;
        refIds?: string[];
        elements?: string[];
        colors?: string[];
        skipSummary?: boolean;
      } = {},
    ) => {
      const {
        elementType = selectedElementType,
        refIds = selectedReferences?.map((ref) => ref.id) || [],
        elements = selectedElements,
        colors = selectedColors,
        skipSummary = false,
      } = options;

      if (!currentProjectId || !elementType) return;

      // Fetch summary chart data if not skipped
      if (!skipSummary && elementType !== ElementType.EVALUATE) {
        setIsSummaryChartLoading(true);
        try {
          const response = await getSummaryChartData(currentProjectId, elementType);
          if (!response.success) {
            setShowRefError(true);
            addErrorMessage(`Error fetching summary chart data`);
          }
        } catch (error) {
          setShowRefError(true);
          addErrorMessage(`Error fetching summary chart data`);
        } finally {
          setIsSummaryChartLoading(false);
        }
      }

      // Fetch reference chart data if there are references
      if (refIds.length > 0) {
        setIsReferenceChartLoading(true);
        try {
          const response = await getReferenceChartData(
            currentProjectId,
            refIds,
            elementType === ElementType.FEATURE,
            elements,
            colors,
            true,
          );
          if (!response.success) {
            setShowRefError(true);
            addErrorMessage(`Error fetching reference chart data`);
          }
        } catch (error) {
          setShowRefError(true);
          addErrorMessage(`Error fetching reference chart data`);
        } finally {
          setIsReferenceChartLoading(false);
        }
      }
    },
    [
      currentProjectId,
      selectedElementType,
      selectedReferences,
      selectedElements,
      selectedColors,
      getSummaryChartData,
      getReferenceChartData,
      addErrorMessage,
    ],
  );

  // Add this check at the start of your render method
  const isDataLoading = isReferencesLoading || isLoading || !currentProject?.references;

  // Add new state for scroll direction
  const [showScrollTop, setShowScrollTop] = useState(false);

  // Add scroll event handler
  useEffect(() => {
    const scrollArea = document.querySelector("[data-radix-scroll-area-viewport]");
    let lastScrollY = 0;

    const handleScroll = (e: Event) => {
      const target = e.target as HTMLElement;
      const currentScrollY = target.scrollTop;
      const isScrollingUp = currentScrollY < lastScrollY;
      const isScrolledEnough = currentScrollY > 200;

      setShowScrollTop(isScrollingUp && isScrolledEnough);
      lastScrollY = currentScrollY;
    };

    if (scrollArea) {
      scrollArea.addEventListener("scroll", handleScroll, { passive: true });
      return () => scrollArea.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <ProjectPage pageName={pageName}>
      {isDataLoading ? (
        <div className="flex justify-center items-center flex-col mt-12">
          <Loader message="Loading..." />
        </div>
      ) : isRechartingInProgress && nonPendingReferenceIds.length > 0 ? (
        <Loader message="Processing references..." />
      ) : (
        <>
          <div className="relative">
            <ScrollArea className="px-3 h-[calc(100vh-60px)]">
              {currentProject?.references &&
              nonPendingReferenceIds &&
              nonPendingReferenceIds.length > 0 ? (
                <div className="space-y-2">
                  <div className="w-full pt-2">
                    <SummaryTable
                      handleSelect={selectChartDisplay}
                      isExpanded={isSummaryExpanded}
                      hideEmptyRows={rowVisibility === "hide"}
                      hideNonAsserted={showAssertedClaims}
                    />
                  </div>
                  <Separator className="my-2" />

                  <div className="flex flex-wrap gap-2 items-center">
                    <div className="flex items-center gap-2">
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}
                            className="h-9 w-9 flex-shrink-0"
                          >
                            {isSummaryExpanded ? (
                              <ChevronUpIcon />
                            ) : (
                              <ChevronDownIcon />
                            )}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          {isSummaryExpanded ? (
                            <p>Collapse Summary</p>
                          ) : (
                            <p>Expand Summary</p>
                          )}
                        </TooltipContent>
                      </Tooltip>

                      {isStandardsPortfolio && (
                        <ToggleGroup
                          type="single"
                          value={rowVisibility}
                          onValueChange={(value: string) => setRowVisibility(value)}
                          className="h-9"
                        >
                          <ToggleGroupItem
                            value="show"
                            className={cn(
                              rowVisibility === "show"
                                ? "bg-tertiary text-primary-foreground"
                                : "text-muted-foreground",
                            )}
                          >
                            Show All
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            value="hide"
                            className={cn(
                              rowVisibility === "hide"
                                ? "bg-tertiary text-primary-foreground"
                                : "text-muted-foreground",
                            )}
                          >
                            Hide Empty
                          </ToggleGroupItem>
                        </ToggleGroup>
                      )}

                      {!isStandardsPortfolio && (
                        <ToggleGroup
                          className="h-9"
                          type="single"
                          value={selectedElementType}
                          onValueChange={(value) => handleTypeSelect(value)}
                        >
                          <ToggleGroupItem
                            value={ElementType.CLAIM}
                            disabled={
                              !currentProject?.claims ||
                              currentProject.claims.length === 0
                            }
                            className={cn(
                              "flex-1",
                              selectedElementType === ElementType.CLAIM
                                ? "bg-tertiary text-primary-foreground"
                                : "text-muted-foreground",
                            )}
                          >
                            Claims
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            value={ElementType.FEATURE}
                            disabled={
                              !currentProject?.features ||
                              currentProject.features.length === 0
                            }
                            className={cn(
                              "flex-1",
                              selectedElementType === ElementType.FEATURE
                                ? "bg-tertiary text-primary-foreground"
                                : "text-muted-foreground",
                            )}
                          >
                            Features
                          </ToggleGroupItem>
                        </ToggleGroup>
                      )}

                      {selectedElementType === ElementType.CLAIM &&
                        currentProject?.assertedClaims &&
                        currentProject.assertedClaims.length > 0 && (
                          <ToggleGroup
                            className="h-9"
                            type="single"
                            value={showAssertedClaims ? "asserted" : "all"}
                            onValueChange={(value) =>
                              handleClaimModeSelect(value as "all" | "asserted")
                            }
                          >
                            <ToggleGroupItem
                              value="all"
                              className={cn(
                                "flex-1",
                                showAssertedClaims === false
                                  ? "bg-tertiary text-primary-foreground"
                                  : "text-muted-foreground",
                              )}
                            >
                              All
                            </ToggleGroupItem>
                            <ToggleGroupItem
                              value="asserted"
                              className={cn(
                                "flex-1",
                                showAssertedClaims === true
                                  ? "bg-tertiary text-primary-foreground"
                                  : "text-muted-foreground",
                              )}
                            >
                              Asserted
                            </ToggleGroupItem>
                          </ToggleGroup>
                        )}
                    </div>

                    {doesIprExist &&
                      organizationName &&
                      organizationName.includes("AndAI") &&
                      !isStandardsPortfolio && (
                        <Button onClick={handleEvaluate}>Evaluate</Button>
                      )}

                    <ElementSelect
                      onElementSelect={handleClaimSelect}
                      elementType={selectedElementType}
                      className="h-9"
                    />
                    <ReferenceSelect
                      handleReferenceSelect={handleReferenceSelect}
                      className="h-9"
                    />
                    <ColorSelect
                      colors={[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED]}
                      selectedColors={selectedColors}
                      setSelectedColors={handleColorSelect}
                      className="h-9"
                    />
                    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          className="h-9"
                          disabled={isActionDisabled}
                        >
                          Prune
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem onClick={() => handlePruneMenuClick("color")}>
                          By Color
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handlePruneMenuClick("score")}>
                          By Score
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <Button
                      onClick={() => setShowExportModal(true)}
                      disabled={isActionDisabled}
                      className="h-9"
                    >
                      Download
                    </Button>
                  </div>
                  <div className="chart-container">
                    {selectedElementType === ElementType.EVALUATE ? (
                      isEvaluating ? (
                        <Loader message="Evaluating...This may take a few seconds" />
                      ) : (
                        <EvaluationResults
                          data={evaluationData}
                          onClose={handleCloseEvaluation}
                        />
                      )
                    ) : isFilling ? (
                      <Loader message="Filling...This may take a few seconds" />
                    ) : selectedReferences.length > 0 ? (
                      <div className="w-full overflow-x-auto">
                        {isReferenceChartLoading ? (
                          <Loader message="Loading reference chart..." />
                        ) : (
                          <InvalidityTable
                            fetchReferenceChartData={handleDataTableRefresh}
                            onFillStart={handleFillStart}
                            onFillEnd={handleFillEnd}
                            rowVisibility={rowVisibility}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center mt-12">
                        <p className="text-center">Select reference(s) to view chart</p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center flex-col mt-12">
                  <H4>This project has no references.</H4>

                  <Button
                    className="mt-3"
                    variant="outline"
                    onClick={handleReferencesNavigate}
                  >
                    Add references
                  </Button>
                </div>
              )}
            </ScrollArea>

            <ScrollTopButton
              className="absolute bottom-4 left-6 z-50"
              show={showScrollTop}
            />
          </div>

          <ExportChartsModal
            open={showExportModal}
            handleClose={() => setShowExportModal(false)}
          />
          <PruneInvalidityColorModal
            open={showPruneColorModal}
            onOpenChange={setShowPruneColorModal}
            fetchElementChartPageData={fetchElementChartPageData}
          />
          <PruneInvalidityScoreModal
            open={showPruneScoreModal}
            handleClose={() => setShowPruneScoreModal(false)}
            fetchElementChartPageData={fetchElementChartPageData}
          />
        </>
      )}
    </ProjectPage>
  );
};

export default ElementChartsPage;
