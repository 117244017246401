/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCellCondensed, TableRow } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { ElementType } from "@/types";
import { CheckCircledIcon, Cross1Icon, PlusIcon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";

interface ElementEditorProps {
  setItems?: (items: { [key: string]: string }[]) => void;
  initialItems?: { [key: string]: string }[];
  startInEditMode: boolean;
  itemType: ElementType;
  autoRenumber?: boolean;
  assertedClaims?: string[];
  setAssertedClaims?: React.Dispatch<React.SetStateAction<string[]>>;
  isCreateMode?: boolean;
}

interface ListItem {
  [key: string]: string;
}

/**
 * @description Element editor
 * @param {function} setItems - Function to set the items.
 * @param {ElementType} itemType - The type of item.
 * @param {ListItem[]} initialItems - The initial items.
 * @param {boolean} startInEditMode - Whether to start in edit mode.
 * @param {boolean} useCurrentSubject - Whether to use the current subject.
 */
const ElementEditor: React.FC<ElementEditorProps> = ({
  setItems,
  itemType,
  initialItems,
  startInEditMode,
  autoRenumber = true,
  assertedClaims = [],
  setAssertedClaims = () => {},
  isCreateMode = false,
}) => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [newText, setNewText] = useState<string>("");
  const [newId, setNewId] = useState<string>("");
  const [claimInputError, setClaimInputError] = useState<boolean>(false);
  const [claimIdError, setClaimIdError] = useState<boolean>(false);
  const [labelClick, setLabelClick] = useState<boolean>(false);
  const [list, setList] = useState<ListItem[]>([]);

  const updateFeatureIds = (items: ListItem[]): ListItem[] => {
    return items.map((item, index) => {
      const [, text] = Object.entries(item)[0];
      return { [(index + 1).toString()]: text };
    });
  };

  const updateListAndItems = (newList: ListItem[]) => {
    setList(newList);
    if (setItems) {
      setItems(newList);
    }
  };

  useEffect(() => {
    if (initialItems) {
      setList(initialItems);
      if (
        startInEditMode ||
        (initialItems.length === 1 && initialItems[0]["1"] === "")
      ) {
        setNewId("1");
        setNewText("");
        setEditIndex(0);
      }
    } else if (startInEditMode) {
      addFirstEmptyItem();
    }
  }, [initialItems, startInEditMode]);

  const handleEdit = (id: string, index: number, itemClick: boolean) => {
    if (index !== editIndex) {
      const item = list[index];
      setLabelClick(itemClick);
      setNewText(item[id]);
      setNewId(id);
      setEditIndex(index);
      setClaimIdError(false);
    }
  };

  const handleSave = async (index: number): Promise<ListItem[]> => {
    const itemArray = [...list];
    let updatedId = newId;

    if (updatedId === "") {
      updatedId = (index + 1).toString();
    }

    if (newText.trim() === "") {
      handleRemove(updatedId, index);
      return itemArray;
    }

    if (!autoRenumber) {
      const isDuplicate = itemArray.some((item, idx) => {
        const itemId = Object.keys(item)[0];
        return itemId === updatedId && idx !== index;
      });

      if (isDuplicate) {
        setClaimIdError(true);
        return [];
      }
    }

    if (updatedId === "") {
      setClaimIdError(true);
      return [];
    }

    setClaimIdError(false);
    if (newText === "") {
      setClaimInputError(true);
      return [];
    }

    setClaimInputError(false);
    const newItem = { [updatedId]: newText };
    itemArray[index] = newItem;
    updateListAndItems(itemArray);
    setEditIndex(null);
    setNewText("");
    setNewId("");

    return itemArray;
  };

  const getNextSubclaim = (currentId: string): string => {
    const parts = currentId.split(".");
    return `${parts.join(".")}.1`;
  };

  const addFirstEmptyItem = () => {
    setList([{ "1": "" }]);
    setNewText("");
    setNewId("1");
    setEditIndex(0);
  };

  // Helper to find the last descendant index of a claim
  const findLastDescendantIndex = (index: number): number => {
    const currentId = Object.keys(list[index])[0];
    const currentParts = currentId.split(".");
    const mainNumber = currentParts[0];
    let lastIndex = index;

    // Look through subsequent items to find the last descendant
    for (let i = index + 1; i < list.length; i++) {
      const [itemId] = Object.entries(list[i])[0];
      if (itemId.startsWith(`${mainNumber}.`)) {
        lastIndex = i;
      } else {
        break; // Exit once we find an item that's not a descendant
      }
    }

    return lastIndex;
  };

  // Helper to check if next item is a subclaim of current item
  const nextItemIsSubclaim = (index: number): boolean => {
    if (index >= list.length - 1) return false;

    const currentId = Object.keys(list[index])[0];
    const nextId = Object.keys(list[index + 1])[0];

    return nextId.startsWith(`${currentId}.`);
  };

  // Update handleAddAtLevel to be smarter about placement
  const handleAddAtLevel = (index: number, level: "above" | "same" | "below") => {
    const currentId = Object.keys(list[index])[0];
    const currentParts = currentId.split(".");

    switch (level) {
      case "above":
        // Add at main level (e.g., 1.2 -> 2)
        const mainNumber = parseInt(currentParts[0]);
        handleAdd(index, false, "", mainNumber);
        break;

      case "same":
        if (nextItemIsSubclaim(index)) {
          // If next item is a subclaim, add after all descendants
          const lastDescendantIndex = findLastDescendantIndex(index);
          if (currentParts.length === 1) {
            // For main claims, add after all subclaims
            const mainNumber = parseInt(currentParts[0]);
            handleAdd(lastDescendantIndex, false, "", mainNumber);
          } else {
            // For subclaims, add after all children
            const parentId = currentParts.slice(0, -1).join(".");
            handleAdd(lastDescendantIndex, false, parentId);
          }
        } else {
          // If next item is same level or higher, add directly after current item
          if (currentParts.length === 1) {
            // For main level claims
            const mainNumber = parseInt(currentParts[0]);
            handleAdd(index, false, "", mainNumber);
          } else {
            // For subclaims
            const parentId = currentParts.slice(0, -1).join(".");
            handleAdd(index, false, parentId);
          }
        }
        break;

      case "below":
        // Add as subclaim (e.g., 1.2 -> 1.2.1)
        handleAdd(index, true, currentId);
        break;
    }
  };

  // Helper function to renumber claims at a specific level
  const renumberClaimsAtLevel = (
    items: ListItem[],
    parentId: string = "",
    startIndex: number = 0,
  ): ListItem[] => {
    const isMainLevel = parentId === "";
    let currentNumber = startIndex;
    let updatedItems = [...items];

    for (let i = 0; i < updatedItems.length; i++) {
      const [id, text] = Object.entries(updatedItems[i])[0];
      const parts = id.split(".");

      if (isMainLevel) {
        if (parts.length === 1) {
          currentNumber++;
          const oldId = parts[0];
          const newId = currentNumber.toString();
          // Update the main claim
          updatedItems[i] = { [newId]: text };
          // Update all its subclaims
          updatedItems = updateSubclaimNumbers(updatedItems, oldId, newId);
        }
      } else {
        const itemParentId = parts.slice(0, -1).join(".");
        if (itemParentId === parentId) {
          currentNumber++;
          const oldId = id;
          const newId = `${parentId}.${currentNumber}`;
          // Update the claim
          updatedItems[i] = { [newId]: text };
          // Update all its subclaims
          updatedItems = updateSubclaimNumbers(updatedItems, oldId, newId);
        }
      }
    }

    if (setItems) {
      setItems(updatedItems);
    }

    return updatedItems;
  };

  // Helper function to update subclaim numbers when parent number changes
  const updateSubclaimNumbers = (
    items: ListItem[],
    oldParentId: string,
    newParentId: string,
  ): ListItem[] => {
    return items.map((item) => {
      const [id, text] = Object.entries(item)[0];
      if (id.startsWith(`${oldParentId}.`)) {
        // Replace the parent part of the ID while keeping the rest of the hierarchy
        const subclaimPart = id.slice(oldParentId.length);
        const newId = `${newParentId}${subclaimPart}`;
        return { [newId]: text };
      }
      return item;
    });
  };

  // Update handleAdd to handle main level additions without renumbering subclaims
  const handleAdd = (
    indexBefore: number,
    isSubClaim: boolean,
    parentId: string = "",
    mainNumberOverride?: number,
  ) => {
    let newId = "";

    setList((prevList) => {
      let itemArray = [...prevList];

      if (itemType === ElementType.CLAIM) {
        if (mainNumberOverride !== undefined) {
          // Adding at main level
          const mainNumber = mainNumberOverride + 1;
          newId = mainNumber.toString();

          if (autoRenumber) {
            // Only increment subsequent claims if autoRenumber is true
            itemArray = itemArray.map((item) => {
              const [id, text] = Object.entries(item)[0];
              const parts = id.split(".");
              const itemMainNumber = parseInt(parts[0]);

              if (itemMainNumber > mainNumberOverride) {
                parts[0] = (itemMainNumber + 1).toString();
                return { [parts.join(".")]: text };
              }
              return item;
            });
          }

          // Insert the new item
          itemArray.splice(indexBefore + 1, 0, { [newId]: "" });
        } else if (parentId) {
          if (isSubClaim) {
            newId = getNextSubclaim(parentId);
          } else {
            const siblings = itemArray.filter((item) => {
              const [itemId] = Object.entries(item)[0];
              const itemParts = itemId.split(".");
              const itemParentId = itemParts.slice(0, -1).join(".");
              return itemParentId === parentId;
            });
            const currentParts = Object.keys(itemArray[indexBefore])[0].split(".");
            const currentNumber = parseInt(currentParts[currentParts.length - 1]);
            newId = `${parentId}.${currentNumber + 1}`;
          }

          itemArray.splice(indexBefore + 1, 0, { [newId]: "" });
          return autoRenumber ? renumberClaimsAtLevel(itemArray, parentId) : itemArray;
        }
      } else {
        // Handle features
        newId = autoRenumber ? (indexBefore + 2).toString() : ""; // Don't auto-assign ID if autoRenumber is false
        itemArray.splice(indexBefore + 1, 0, { [newId]: "" });
        return autoRenumber ? updateFeatureIds(itemArray) : itemArray;
      }

      return itemArray;
    });

    setNewText("");
    setEditIndex(indexBefore + 1);
    setNewId(newId);
  };

  // Update handleRemove to include proper renumbering
  const handleRemove = async (id: string, index: number) => {
    setList((prevList) => {
      let newList = [...prevList];
      newList.splice(index, 1);

      let updatedList;
      if (itemType === ElementType.FEATURE) {
        updatedList = autoRenumber ? updateFeatureIds(newList) : newList;
      } else {
        const parts = id.split(".");

        if (parts.length === 1) {
          // Removing a main claim
          const mainNumber = parseInt(parts[0]);
          // Remove all subclaims
          newList = newList.filter((item) => {
            const [itemId] = Object.entries(item)[0];
            return !itemId.startsWith(`${mainNumber}.`);
          });
          // Renumber remaining main claims only if autoRenumber is true
          updatedList = autoRenumber ? renumberClaimsAtLevel(newList) : newList;
        } else {
          // Removing a subclaim
          const parentId = parts.slice(0, -1).join(".");
          // Renumber siblings only if autoRenumber is true
          updatedList = autoRenumber
            ? renumberClaimsAtLevel(newList, parentId)
            : newList;
        }
      }

      if (setItems) {
        setItems(updatedList);
      }

      return updatedList;
    });

    setEditIndex(null);
    setNewText("");
    setNewId("");
    setClaimInputError(false);
    setClaimIdError(false);
  };

  // Checks if there are more items at the same level
  const hasMoreItemsAtSameLevel = (index: number): boolean => {
    const currentId = Object.keys(list[index])[0];
    const currentParts = currentId.split(".");

    // For main level claims (1, 2, 3), always hide "Add at Above Level"
    if (currentParts.length === 1) {
      return true;
    }

    const parentId = currentParts.slice(0, -1).join(".");
    const currentPosition = parseInt(currentParts[currentParts.length - 1]);

    // Look for any items after this one that share the same parent
    const hasMoreSiblings = list.some((item) => {
      const [itemId] = Object.entries(item)[0];
      const itemParts = itemId.split(".");
      return (
        itemParts.length === currentParts.length && // Same level
        itemParts.slice(0, -1).join(".") === parentId && // Same parent
        parseInt(itemParts[itemParts.length - 1]) > currentPosition
      ); // Higher number
    });

    // Look for any items after this one that are at a different parent but same main claim
    const mainClaimNumber = currentParts[0];
    const isLastInMainClaim = !list.slice(index + 1).some((item) => {
      const [itemId] = Object.entries(item)[0];
      return itemId.startsWith(`${mainClaimNumber}.`);
    });

    // Hide "Add at Above Level" only if there are more siblings
    // OR if this isn't the last item in its main claim group
    return hasMoreSiblings || !isLastInMainClaim;
  };

  // Check if a claim is a top-level claim
  const isTopLevelClaim = (id: string): boolean => {
    return !id.includes(".");
  };

  // Get all subclaims for a given claim
  const getAllSubclaimIds = (list: ListItem[], mainId: string): string[] => {
    return list
      .map((item) => Object.keys(item)[0])
      .filter((id) => id.startsWith(`${mainId}.`));
  };

  // Select or unselect a claim and all its subclaims
  const handleToggleAsserted = (id: string) => {
    if (!setAssertedClaims) return;

    setAssertedClaims((prevAsserted: string[]) => {
      const isCurrentlyAsserted = prevAsserted.includes(id);
      const subclaimIds = getAllSubclaimIds(list, id);

      if (isCurrentlyAsserted) {
        // Remove main claim and all subclaims
        return prevAsserted.filter(
          (claimId) => claimId !== id && !subclaimIds.includes(claimId),
        );
      } else {
        // Add main claim and all subclaims
        return [...prevAsserted, id, ...subclaimIds];
      }
    });
  };

  return (
    <>
      {list.length === 0 ? (
        <div className="flex justify-center">
          <Button
            variant="outline"
            className="w-full"
            onClick={() => addFirstEmptyItem()}
          >
            Add {itemType === ElementType.FEATURE ? "Features" : "Claims"}
          </Button>
        </div>
      ) : (
        <Table className="w-full">
          <TableBody>
            {list.map((item, index) => {
              if (!item) return null;
              const [id, text] = Object.entries(item)[0];
              return (
                <TableRow key={index}>
                  <TableCellCondensed>
                    {itemType === ElementType.CLAIM && (
                      <div className="flex gap-2 items-center">
                        {!isCreateMode && (
                          <div className="w-8">
                            {isTopLevelClaim(id) ? (
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant={
                                      assertedClaims.includes(id)
                                        ? "default"
                                        : "outline"
                                    }
                                    size="icon"
                                    onClick={() => handleToggleAsserted(id)}
                                  >
                                    <CheckCircledIcon className="h-4 w-4" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  {assertedClaims.includes(id)
                                    ? "Unselect as asserted"
                                    : "Select as asserted"}
                                </TooltipContent>
                              </Tooltip>
                            ) : (
                              assertedClaims.includes(id) && (
                                <CheckCircledIcon className="h-4 w-4 text-blue-500 ml-2" />
                              )
                            )}
                          </div>
                        )}
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="icon">
                              <PlusIcon className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            {!hasMoreItemsAtSameLevel(index) && (
                              <DropdownMenuItem
                                onClick={() => handleAddAtLevel(index, "above")}
                              >
                                Add at Above Level
                              </DropdownMenuItem>
                            )}
                            <DropdownMenuItem
                              onClick={() => handleAddAtLevel(index, "same")}
                            >
                              Add at Same Level
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleAddAtLevel(index, "below")}
                            >
                              Add Sub-Claim
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                    {itemType === ElementType.FEATURE && (
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleAdd(index, true)}
                      >
                        <PlusIcon className="h-4 w-4" />
                      </Button>
                    )}
                  </TableCellCondensed>
                  <TableCellCondensed onClick={() => handleEdit(id, index, true)}>
                    {editIndex === index && !isCreateMode ? (
                      <Input
                        value={newId}
                        onChange={(e) => setNewId(e.target.value)}
                        className={cn(
                          "w-full max-w-[100px]",
                          claimIdError && "border-red-500",
                        )}
                        autoFocus={labelClick}
                        multiple
                        onBlur={() => {
                          if (!autoRenumber) {
                            const isDuplicate = list.some((item, idx) => {
                              const itemId = Object.keys(item)[0];
                              return itemId === newId && idx !== editIndex;
                            });
                            setClaimIdError(isDuplicate);
                          }
                        }}
                      />
                    ) : (
                      <span className="cursor-pointer hover:bg-gray-100 px-2 py-1 rounded">
                        {id}
                      </span>
                    )}
                  </TableCellCondensed>
                  <TableCellCondensed onClick={() => handleEdit(id, index, false)}>
                    {editIndex === index ? (
                      <AutosizeTextarea
                        value={newText}
                        onChange={(e) => setNewText(e.target.value)}
                        className={cn("w-full")}
                        autoFocus={!labelClick}
                        onBlur={() => handleSave(index)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleSave(index);
                          }
                        }}
                      />
                    ) : (
                      <span className="cursor-pointer hover:bg-gray-100 px-2 py-1 rounded">
                        {text}
                      </span>
                    )}
                  </TableCellCondensed>
                  <TableCellCondensed>
                    <div className="flex justify-center gap-2">
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleRemove(id, index)}
                          >
                            <Cross1Icon className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Remove</TooltipContent>
                      </Tooltip>
                    </div>
                  </TableCellCondensed>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default ElementEditor;
