import { Checkbox } from "@/components/ui/checkbox";
import { ApplicationDocument, DocumentType, OfficeAction } from "@/types/project";
import { ColumnDef } from "@tanstack/react-table";

export const getCommonColumns = (): ColumnDef<ApplicationDocument, any>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        onClick={(e) => e.stopPropagation()}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "document.documentType",
    header: "Document Type",
    cell: ({ row }) => {
      switch (row.original.document_type) {
        case DocumentType.OFFICE_ACTION:
          return "Office Action";
        default:
          return "—";
      }
    },
  },
];

export const getOfficeActionColumns = (): ColumnDef<ApplicationDocument, any>[] => [
  {
    accessorKey: "document.notificationDate",
    header: "Notification Date",
    cell: ({ row }) => {
      const doc = row.original.document as OfficeAction;
      if (doc && doc.notificationDate) {
        return doc.notificationDate
          ? new Date(doc.notificationDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "—";
      }
      return "—";
    },
  },
  {
    accessorKey: "document.primaryExaminer",
    header: "Primary Examiner",
    cell: ({ row }) => {
      const doc = row.original.document as OfficeAction;
      return doc.primaryExaminer ?? "—";
    },
  },
];
